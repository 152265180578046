package shared.business.feature.auth

import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.auth.data.repository.AuthRepositoryImpl
import shared.business.feature.auth.domain.repository.AuthRepository
import shared.business.feature.auth.domain.usecase.*

internal val authModule = module {
    singleOf(::AuthRepositoryImpl) bind AuthRepository::class

    factoryOf(::SignInUseCaseImpl) bind SignInUseCase::class
    factoryOf(::SignOutUseCaseImpl) bind SignOutUseCase::class
    factoryOf(::IsSignedInUseCaseImpl) bind IsSignedInUseCase::class
    factoryOf(::GetCurrentUserIdUseCaseImpl) bind GetCurrentUserIdUseCase::class
}

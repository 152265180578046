package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.SpaceZero
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent

@Composable
fun CreatePostLayout(toolbar: @Composable (Router) -> Unit, content: @Composable ColumnScope.() -> Unit) {
    val router = rememberPageContext().router
    Column(Modifier.fillMaxWidth(50.percent).fadeInTransition(), horizontalAlignment = Alignment.CenterHorizontally) {
        toolbar(router)
        Space()
        Column(
            modifier = BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth().padding(SpaceZero),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            content()
        }
    }
}
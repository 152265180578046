import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.browser.api
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.EventSource
import org.w3c.dom.EventSourceInit
import org.w3c.dom.MessageEvent
import org.w3c.dom.`get`

private fun forceReloadNow(): Unit {
    window.stop()
    window.location.reload()
}

private fun handleServerStatusEvents(): Unit {
    val status = document.getElementById("status")!!
    var lastVersion: Int? = null
    var shouldReload = false

    val warningIcon = status.children[0]!!
    val spinnerIcon = status.children[1]!!
    val statusText = status.children[2]!!

    status.addEventListener("transitionend", {
        if (status.hasClass("fade-out")) {
            status.removeClass("fade-out")
            if (shouldReload) {
                forceReloadNow()
            }
        }
    })

    val eventSource = EventSource("/api/kobweb-status", EventSourceInit(true))
    eventSource.addEventListener("version", { evt ->
        val version = (evt as MessageEvent).data.toString().toInt()
        if (lastVersion == null) {
            lastVersion = version
        }
        if (lastVersion != version) {
            lastVersion = version
            if (status.className.isNotEmpty()) {
                shouldReload = true
            } else {
                // Not sure if we can get here but if we can't rely on the status transition
                // to reload we should do it ourselves.
                forceReloadNow()
            }
        }
    })

    eventSource.addEventListener("status", { evt ->
        val values: dynamic = JSON.parse<Any>((evt as MessageEvent).data.toString())
        val text = values.text as String
        val isError = (values.isError as String).toBoolean()
        if (text.isNotBlank()) {
            warningIcon.className = if (isError) "visible" else "hidden"
            spinnerIcon.className = if (isError) "hidden" else "visible"
            statusText.innerHTML = "<i>$text</i>"
            status.className = "fade-in"
        } else {
            if (status.className == "fade-in") {
                status.className = "fade-out"
            }
        }
    })

    eventSource.onerror = { eventSource.close() }
}

public fun main(): Unit {
    handleServerStatusEvents()

    window.api.logOnError = true

    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { boostie.pages.PostsPage() }
        ctx.router.register("/appearance") { boostie.pages.appearance.AppearancePage() }
        ctx.router.register("/auth") { boostie.pages.auth.AuthPage() }
        ctx.router.register("/create/content") {
                boostie.pages.post.create.content.PostCreateContentPage() }
        ctx.router.register("/create/props") { boostie.pages.post.create.props.PostCreatePropsPage()
                }
        ctx.router.register("/create/summary") {
                boostie.pages.post.create.summary.PostCreateSummaryPage() }
        ctx.router.register("/mobile") { boostie.pages.sample.MobileSamplePage() }
        ctx.router.register("/onboarding") { boostie.pages.onboarding.OnboardingPage() }
        ctx.router.register("/registration") { boostie.pages.auth.EmailRegistrationPage() }
        ctx.router.register("/sample") { boostie.pages.sample.SamplePage() }

    }
    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(boostie.components.style.InputStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.SecondaryInputStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.HeaderTitleStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.TitleStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.NormalTextStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.SemiBoldNormalTextStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.SubTitleStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.ErrorTextStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.PrimaryTextButtonStyleVariant)
        ctx.theme.registerComponentStyle(boostie.components.style.ShadowStyle)
        ctx.theme.registerComponentStyle(boostie.components.style.BoxStyle)
        ctx.theme.registerComponentVariants(boostie.components.style.TitleStyleOnPrimaryVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.TitleStyleOnBackgroundVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.MutedSpanTextVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.PrimaryButtonStyleVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.PrimaryButtonStyleUnselectedVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.SecondaryButtonStyleVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.TransparentSecondaryButtonStyleVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.TransparentButtonStyleVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.CircleIconButtonStyleVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.PrimaryButtonSelectedVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.ButtonUnselectedVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.BoxStylePrimaryVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.BoxStyleBackgroundVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.BoxStylePrimaryDashedVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.BoxStyleSurfaceVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.BoxStyleHeaderBackgroundVariant)
        ctx.theme.registerComponentVariants(boostie.components.style.SimpleErrorBoxBackgroundVariant)
        ctx.stylesheet.registerKeyframes(boostie.components.animation.AnimationFadeIn)
        initSiteStyles(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin))

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "Boostie", "deployment" to "PROD")
        ) { MyApp {
                com.varabyte.kobweb.silk.defer.renderWithDeferred { router.renderActivePage() }
            }
        }
    }
}

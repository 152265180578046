package boostie.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import boostie.components.animation.fadeInTransition
import boostie.components.style.RadiusLarge
import boostie.components.style.SpaceSmall
import boostie.components.style.onBackgroundSecondaryColor
import boostie.components.style.onPrimaryColor
import boostie.components.style.primaryColor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import shared.model.feature.content.domain.FileContent

@Composable
fun ImageView(
    modifier: Modifier = Modifier.padding(SpaceSmall),
    width: CSSNumeric = 200.px,
    height: CSSNumeric = 200.px,
    fileContent: FileContent,
    onRemove: ((FileContent) -> Unit)? = null,
) {
    var isShowContentVisible by remember { mutableStateOf(false) }
    var isRemoveVisible by remember { mutableStateOf(false) }
    Box(modifier, contentAlignment = Alignment.Center) {
        Image(fileContent.publicUrl ?: "",
            modifier = Modifier.borderRadius(RadiusLarge).width(width).height(height).objectFit(ObjectFit.Cover).fadeInTransition().onClick {
                isShowContentVisible = true
            }

        )
        if (onRemove != null) {
            Box(Modifier.fillMaxSize().padding(SpaceSmall), contentAlignment = Alignment.TopEnd) {
                TransparentSecondaryButton(iconLeft = { FaTrash(primaryColor) }) {
                    isRemoveVisible = true
                }
            }
        }
    }

    if (isRemoveVisible) {
        QuestionDialogView(
            title = "Smazat?",
            description = "Chcete opravdu odebrat tuto polozku?",
            onDismiss = {
                isRemoveVisible = false
            },
            onPositive = {
                isRemoveVisible = false
                onRemove?.invoke(fileContent)
            }
        )
    }

    if (isShowContentVisible) {
        ShowImageContentDialog(fileContent, onDismiss = { isShowContentVisible = false })
    }
}


@Composable
private fun ShowImageContentDialog(fileContent: FileContent, onDismiss: () -> Unit) {
    val src = fileContent.publicUrl
    if (src.isNullOrEmpty()) {
        onDismiss()
    } else {
        SimpleDialogOverlayView(sizePercent = 95.percent, onDismiss = { onDismiss() }) {
            Image(src = src, modifier = Modifier.borderRadius(RadiusLarge).fillMaxSize().objectFit(ObjectFit.ScaleDown).fadeInTransition())
        }
    }

}
package shared.business.feature.post.domain.usecase.tags

import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.common.Result
import shared.common.usecase.UseCaseResultNoParams
import shared.model.feature.post.domain.PostCreatorTag

interface GetCurrentCreatorTagsUseCase : UseCaseResultNoParams<List<PostCreatorTag>>
internal class GetCurrentCreatorTagsUseCaseImpl internal constructor(
    private val repo: PostCreatorTagRepository,
) : GetCurrentCreatorTagsUseCase {

    override suspend fun invoke(): Result<List<PostCreatorTag>> {
        return repo.getCurrentCreatorTags()
    }
}

package boostie.pages.post.create.summary

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Space
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.layout.DefaultToolbarButtons
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStylePrimaryDashedVariant
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.NormalTextStyle
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.TitleStyle
import boostie.components.style.onPrimaryColor
import boostie.components.style.primaryColor
import boostie.components.widgets.ImageView
import boostie.components.widgets.LabelText
import boostie.components.widgets.MediaContentPreview
import boostie.components.widgets.MediaFileContent
import boostie.model.CreateEditPostVO
import boostie.model.PostCreatorTagVO
import boostie.pages.post.create.CreatePostViewModel
import boostie.pages.post.create.PostModificationStep
import boostie.pages.post.create.onViewComposed
import boostie.pages.post.create.views.CreatePostLayout
import boostie.pages.post.create.views.PostStepToolbarView
import boostie.pages.post.create.views.TitleWithDescriptionView
import boostie.pages.tags.views.CreatorPostTagView
import boostie.pages.tags.views.CreatorPostTagViewProps
import boostie.toCreateProps
import boostie.util.Authenticated
import boostie.util.CheckOnboarding
import boostie.util.Localization
import boostie.util.rememberDependency
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowX
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaBook
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaFile
import com.varabyte.kobweb.silk.components.icons.fa.FaFloppyDisk
import com.varabyte.kobweb.silk.components.icons.fa.FaHashtag
import com.varabyte.kobweb.silk.components.icons.fa.FaNewspaper
import com.varabyte.kobweb.silk.components.icons.fa.FaPaperclip
import com.varabyte.kobweb.silk.components.icons.fa.FaShare
import com.varabyte.kobweb.silk.components.icons.fa.FaStore
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toJSDate
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import shared.common.extensions.currentLocalDateTime

@Page("/create/summary")
@Composable
fun PostCreateSummaryPage() {
    Authenticated { authViewModel ->
        CheckOnboarding {
            val vm = rememberDependency<CreatePostViewModel>()
            val scope = rememberCoroutineScope()

            vm.onViewComposed(PostModificationStep.CreateSummary)

            CenteredPageLayout(
                title = Localization.create_new_post_label.string,
                type = CenteredPageLayoutType.ToolbarOnly(buttons = { DefaultToolbarButtons(authViewModel) })
            ) {
                CreatePostLayout(toolbar = { router ->
                    PostStepToolbarView(Localization.summary_label.string, onBack = { router.toCreateProps() }, onNext = {
                        scope.launch {
                            vm.onIntent(CreatePostViewModel.Intent.CreatePost)
                        }
                    }, nextEnabled = true, nextButtonTitle = if (vm.state.createEditPostVO.draft) {
                        Localization.save_draft_label.string
                    } else {
                        Localization.publish_label.string
                    }, nextButtonIcon = if (vm.state.createEditPostVO.draft) {
                        { FaFloppyDisk(onPrimaryColor) }
                    } else {
                        { FaShare(onPrimaryColor) }
                    })
                }, content = {
                    RootScreen(vm)
                })
            }
        }
    }
}

@Composable
private fun ColumnScope.RootScreen(vm: CreatePostViewModel) {
    val scope = rememberCoroutineScope()

    Column(Modifier.fillMaxWidth()) {
        TitleWithDescriptionView(
            title = "Nahled", description = "Shrnuti toho co Tvuj prispevek bude obsahovat"
        )
    }

    Column(BoxStyle.toModifier(BoxStylePrimaryDashedVariant).fillMaxWidth()) {
        Row(Modifier.fillMaxWidth()) {
            FaBook(primaryColor.width(24.px).padding(top = SpaceMedium), size = IconSize.XL)
            Space()
            SpanText(vm.state.createEditPostVO.title ?: "", TitleStyle.toModifier().fillMaxWidth().fontSize(1.5.cssRem))
        }
        Space()

        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            FaClock(primaryColor.width(24.px), size = IconSize.XL)
            Space()
            val publishTime = vm.state.createEditPostVO.publishedAt?.run {
                this
            } ?: currentLocalDateTime
            val publishTimeLabel = publishTime.toInstant(TimeZone.currentSystemDefault()).toJSDate().toLocaleString(locales = "cs")
            SpanText(publishTimeLabel, NormalTextStyle.toModifier().fontSize(1.cssRem).fontWeight(FontWeight.SemiBold))
        }
        Space()
        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            FaHashtag(primaryColor.width(24.px), IconSize.XL)
            Space()
            Row(
                BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth().flexWrap(FlexWrap.Wrap).rowGap(SpaceSmall)
                    .padding(topBottom = SpaceMedium), verticalAlignment = Alignment.CenterVertically
            ) {
                val selectedTags = vm.state.createEditPostVO.tags
                selectedTags?.forEach { vo ->
                    val props = CreatorPostTagViewProps.Simple(vo.toDomain(), onClick = {})
                    CreatorPostTagView(props)
                    Space(SpaceSmall)
                }
            }
        }

        Space()
        Row(Modifier.fillMaxWidth()) {
            FaFile(primaryColor.width(24.px).padding(top = SpaceMedium), size = IconSize.XL)
            Space()
            SpanText(Localization.attachments_label.string, TitleStyle.toModifier().fillMaxWidth().fontSize(1.5.cssRem))
        }
        Attachments(vm.state.createEditPostVO)
        Space()


        Row(Modifier.fillMaxWidth()) {
            FaNewspaper(primaryColor.padding(top = SpaceMedium), size = IconSize.XL)
            Space()
            val content = vm.state.createEditPostVO.description
            val contentId = "post-content-id"
            LaunchedEffect(vm.state.createEditPostVO.description) {
                document.getElementById(contentId)?.innerHTML = content ?: "<p>Obsah</p>"
            }

            Div(attrs = Modifier.fillMaxWidth().toAttrs {
                id(contentId)
            }) {
                P {
                    Text(content ?: "<br>")
                }
            }

        }

    }
}

@Composable
private fun Attachments(createPost: CreateEditPostVO) {
    val itemSize = 300.px
    Box(Modifier.fillMaxWidth().overflow(Overflow.Auto)) {
        Row(Modifier.overflowX(Overflow.Scroll).padding(SpaceSmall)) {
            createPost.imageFiles?.forEach {
                ImageView(modifier = Modifier, width = itemSize, height = itemSize, fileContent = it.toDomain())
                Space(SpaceSmall)
            }

            createPost.fullVideoFile?.toDomain()?.let { file ->
                Space()
                MediaContentPreview(tag = Localization.full_version_label.string, size = itemSize, fileContent = file)
            }
            createPost.publicVideoFile?.toDomain()?.let { file ->
                Space()
                MediaContentPreview(tag = Localization.public_version_label.string, size = itemSize, fileContent = file)
            }

            createPost.fullAudioFile?.toDomain()?.let { file ->
                Space()
                MediaContentPreview(
                    tag = Localization.full_version_label.string,
                    size = itemSize,
                    fileContent = file
                )
            }
            createPost.publicAudioFile?.toDomain()?.let { file ->
                Space()
                MediaContentPreview(tag = Localization.public_version_label.string, size = itemSize, fileContent = file)
            }

        }
    }


}

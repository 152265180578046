package shared.business.feature.filecontent.infrastructure

import co.touchlab.kermit.Logger
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.onUpload
import io.ktor.client.request.forms.ChannelProvider
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.utils.io.ByteReadChannel
import shared.business.feature.filecontent.data.source.FileDataSource
import shared.common.Result
import shared.common.network.ClientErrorException
import shared.common.network.ServerErrorException
import shared.common.network.catchingBackendExceptions
import shared.model.error.domain.FileContentError
import shared.model.feature.common.data.api.IdApiDto
import shared.model.feature.content.data.api.FileContentApiDto
import shared.model.feature.content.data.payload.SaveFileContentPayload

internal class ApiFileDataSourceImpl(
    private val httpClient: HttpClient,
) : FileDataSource {

    override suspend fun reserveFileId(): Result<String> {
        return try {
            catchingBackendExceptions {
                val result = httpClient.get("files/reserve-file-id") {}.body<IdApiDto>()
                Result.Success(result.id)
            }
        } catch (e: ServerErrorException) {
            Result.Error(FileContentError.UploadFileFailed(throwable = e))
        }
    }

    override suspend fun saveFile(fileContent: FileContentApiDto): Result<FileContentApiDto> {
        return try {
            catchingBackendExceptions {
                val result = httpClient.post("files/save-file") {
                    setBody(
                        SaveFileContentPayload(
                            id = fileContent.id,
                            name = fileContent.name,
                            extension = fileContent.extension,
                            type = fileContent.type,
                            size = fileContent.size,
                            publicUrl = fileContent.publicUrl,
                            duration = fileContent.duration,
                        ),
                    )
                }.body<FileContentApiDto>()
                Result.Success(result)
            }
        } catch (e: ServerErrorException) {
            Result.Error(FileContentError.SaveFileFailed)
        } catch (e: ClientErrorException) {
            Result.Error(FileContentError.SaveFileFailed)
        }
    }

    override suspend fun uploadPhotoStream(
        fileName: String,
        size: Long,
        fileData: ByteArray,
    ): Result<String> {
        return try {
            catchingBackendExceptions {
                val responseString = httpClient.post("files/upload-stream") {
                    setBody(ByteReadChannel(fileData))
                    headers {
                        append(
                            HttpHeaders.ContentDisposition,
                            "form-data; filename=\"$fileName\"; size=$size",
                        )
                    }
                    onUpload { bytesSentTotal, contentLength ->
                        Logger.d("Sent $bytesSentTotal bytes from $contentLength")
                    }
                }.body<String>()
                Logger.d("File uploaded: $responseString")
                Result.Success(responseString)
            }
        } catch (e: ServerErrorException) {
            Result.Error(FileContentError.UploadFileFailed(throwable = e))
        }
    }

    override suspend fun uploadPhoto(
        fileName: String,
        size: Long,
        fileData: ByteArray,
    ): Result<String> {
        return try {
            catchingBackendExceptions {
                val headers = Headers.build {
                    append(
                        HttpHeaders.ContentDisposition,
                        "filename=\"$fileName\";size=$size",
                    )
                }
                val responseString = httpClient.post("files/upload") {
                    setBody(
                        MultiPartFormDataContent(
                            formData {
                                append(
                                    key = "file",
                                    value = ChannelProvider(fileData.size.toLong()) {
                                        ByteReadChannel(fileData)
                                    },
                                    headers = headers,
                                )
                            },
                            boundary = "Boostie",
                        ),
                    )
                    onUpload { bytesSentTotal, contentLength ->
                        Logger.d("Sent $bytesSentTotal bytes from $contentLength")
                    }
                }.body<String>()
                Logger.d("File uploaded: $responseString")
                Result.Success(responseString)
            }
        } catch (e: ServerErrorException) {
            Result.Error(FileContentError.UploadFileFailed(throwable = e))
        }
    }
}

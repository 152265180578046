package shared.business.feature.auth.domain.usecase

import shared.business.feature.auth.domain.repository.AuthRepository
import shared.common.Result
import shared.common.usecase.UseCaseResultNoParams

interface IsSignedInUseCase : UseCaseResultNoParams<Boolean>

internal class IsSignedInUseCaseImpl(
    private val repository: AuthRepository,
) : IsSignedInUseCase {
    override suspend fun invoke(): Result<Boolean> {
        return repository.isSignedIn()
    }
}

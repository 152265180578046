package Boostie.util

import com.varabyte.kobweb.compose.ui.graphics.Color
import kotlin.math.roundToInt

object ColorUtils {
    fun alphaToHex(alpha: Float): String {
        val alphaValue: Int = (255 * alpha).roundToInt()
        return alphaValue.asHex
    }

    fun toHex(color: Color.Rgb): String {
        return color.run {
            "#${alpha.asHex}${red.asHex}${green.asHex}${blue.asHex}"
        }
    }

    fun hexToInt(hex: String): Int {
        return hex.toInt(16)
    }

    fun parseColor(hexColor: String): Color.Rgb {
        return Color.rgb(parseHexColorToInt(hexColor))
    }

    private val Int.asHex: String
        get() {
            val value = toString(16)
            return if (value.length == 1) {
                "0$value"
            } else {
                value
            }
        }

    private fun parseHexColorToInt(colorString: String): Int {
        if (colorString[0] == '#') {
            // Use a long to avoid rollovers on #ffXXXXXX
            var color = colorString.substring(1).toLong(16)
            if (colorString.length == 7) {
                // Set the alpha value
                color = color.or(0x00000000ff000000)
            } else if (colorString.length != 9) {
                throw IllegalArgumentException("Unknown color");
            }
            return color.toInt()
        } else {
            throw IllegalArgumentException("Unknown color - not start with #");
        }
    }
}
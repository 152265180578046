package shared.common.network

import co.touchlab.kermit.Logger
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json
import shared.common.provider.AppInfoProvider
import shared.common.provider.BuildType
import kotlinx.serialization.json.Json as JsonConfig

val defaultJson = Json {
    ignoreUnknownKeys = true
    coerceInputValues = true
    useAlternativeNames = false
    explicitNulls = true
}

@ExperimentalSerializationApi
object KtorClient {
    fun init(
        appInfoProvider: AppInfoProvider,
        httpClient: HttpClient = HttpClient(),
        clientHttpConfig: ClientHttpConfig,
    ): HttpClient = httpClient.config {
        expectSuccess = true
        followRedirects = false
        install(HttpSend)

        install(ContentNegotiation) {
            json(
                JsonConfig {
                    ignoreUnknownKeys = true
                    coerceInputValues = true
                    useAlternativeNames = false
                    explicitNulls = true
                },
            )
        }

        install(HttpTimeout) {
            socketTimeoutMillis = 25_000L
            requestTimeoutMillis = 25_000L
            connectTimeoutMillis = 25_000L
        }
        defaultRequest {
            url(clientHttpConfig.baseUrl)
            contentType(ContentType.Application.Json)

            headers {
                append("Client-Type", "android")
                append("Connection", "close")
                append("Accept-Language", appInfoProvider.language)
                append("x-device-id", appInfoProvider.deviceId)
                append("x-app-version", appInfoProvider.appVersion)
            }
        }

        if (appInfoProvider.buildType == BuildType.Debug) {
            install(Logging) {
                logger = object : io.ktor.client.plugins.logging.Logger {
                    override fun log(message: String) {
                        Logger.d(tag = "Ktor") { message }
                    }
                }
                level = LogLevel.ALL
            }
        }
    }
}

package shared.common.network

import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.utils.io.*
import io.ktor.utils.io.charsets.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import shared.common.ErrorResult
import shared.common.Result
import shared.common.error.AuthError
import shared.common.error.BackendError
import shared.common.error.CommonError

/**
 * Ktor catching exceptions - should renamed after retrofit deletions (only in new implementation - runCatchingNetwork...)
 */
inline fun <R : Any> runtimeExceptions(block: () -> Result<R>): Result<R> = try {
    block()
} catch (e: RuntimeException) {
    // this also can be transformed into some ErrorResult
    throw e
}

inline fun <R : Any> catchingNetworkExceptions(block: () -> Result<R>): Result<R> = try {
    runtimeExceptions {
        block()
    }
} catch (e: Throwable) {
    when (e::class.simpleName) { // Handle platform specific exceptions
        "ConnectException",
        "DarwinHttpRequestException",
        "UnknownHostException",
        "HttpRequestTimeoutException",
        "NoRouteToHostException",
        "IOException",
        "SSLException",
        "SocketException",
        "SocketTimeoutException",
        -> Result.Error(
            CommonError.NoNetworkConnection(e),
        )

        else -> {
            throw e
        }
    }
} catch (e: Exception) {
    throw e
}

/**
 * Executes given code and returns [Result.Success] if successful or relevant error if not.
 * Returns [BackendError.ResponseBodyEmpty] when response body is null and [fallbackBody] is not specified.
 *
 * @param block network call to be executed.
 * @param fallbackBody object returned when body is null
 * @return [Result] determined by response success. [BackendError.ResponseBodyEmpty] if body is null
 * @throws RetrofitClientErrorException when response has 4xx status code.
 */
suspend inline fun <R : Any> catchingBackendExceptions(
    block: () -> Result<R>,
): Result<R> = try {
    catchingNetworkExceptions {
        block()
    }
} catch (e: ResponseException) {
    val errorBody = e.getError()
    val errorType: Int = e.response.status.value / 100

    when {
        e.response.status == HttpStatusCode.Unauthorized -> Result.Error(
            AuthError.InvalidCredentials(null),
        )

        errorType == 3 -> throw NotModifiedException(
            e.response,
            errorBody,
        )

        errorType == 4 -> throw ClientErrorException(
            e.response,
            errorBody,
        )

        errorType == 5 -> throw ServerErrorException(
            e.response,
            errorBody,
        )

        else -> {
            throw e
        }
    }
} catch (e: NoTransformationFoundException) {
    throw InvalidBodyException(e.message)
}

abstract class ErrorException(
    val response: HttpResponse,
    val errorBody: ErrorResponseBody?,
    val status: HttpStatusCode = response.status,
) : Exception("$errorBody") {
    val domainError
        get() = errorBody.toDomainError()

    override fun toString(): String = buildString {
        append("ClientErrorException(${response.status.value}) @ ${response.request.url}\n")
        append(errorBody.toString().prependIndent(" - "))
    }
}

class ClientErrorException(
    response: HttpResponse,
    errorBody: ErrorResponseBody?,
    status: HttpStatusCode = response.status,
) : ErrorException(response, errorBody, status)

class ServerErrorException(
    response: HttpResponse,
    errorBody: ErrorResponseBody?,
    status: HttpStatusCode = response.status,
) : ErrorException(response, errorBody, status)

class NotModifiedException(
    response: HttpResponse,
    errorBody: ErrorResponseBody?,
    status: HttpStatusCode = response.status,
) : ErrorException(response, errorBody, status)

class InvalidBodyException(message: String?) : Exception(message)

@Serializable
data class ErrorResponseBody(
    val type: String,
    val message: String? = null,
) {
    override fun toString(): String = buildString {
        append("Type: ${type}\n")
        append("Message: $message")
    }
}

suspend fun ResponseException.getError(): ErrorResponseBody {
    return response.bodyAsText().let {
        try {
            defaultJson.decodeFromString(it)
        } catch (e: Exception) {
            e.toString()
            ErrorResponseBody("", null)
        }
    }
}

fun ErrorResponseBody?.toDomainError(): ErrorResult {
    if (this == null) return BackendError.InternalError(null, null)
    return ErrorResult("$type: $message")
}

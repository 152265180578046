package boostie.model

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.serializers.LocalDateTimeIso8601Serializer
import kotlinx.serialization.Serializable
import shared.model.feature.post.data.payload.CreatePostPayload
import shared.model.feature.post.data.payload.PostFileItemsPayload
import shared.model.feature.post.domain.Post
import shared.model.feature.post.domain.PostCreatorTag

fun CreateEditPostVO.toPayload() = CreatePostPayload(
    title = title ?: "",
    description = description ?: "",
    draft = draft,
    publishedAt = publishedAt,
    tagIds = tags?.map { it.id },
    fileItems = PostFileItemsPayload(
        audio = PostFileItemsPayload.AudioPayload(
            full = fullAudioFile?.id,
            public = publicAudioFile?.id
        ),
        video = PostFileItemsPayload.VideoPayload(
            full = fullVideoFile?.id,
            public = publicVideoFile?.id
        ),
        images = PostFileItemsPayload.ImagesPayload(imageFiles?.map { it.id }),
    )
)

@Serializable
data class CreateEditPostVO(
    val title: String? = null,
    val description: String? = null,
    val draft: Boolean = false,
    val type: Post.Type = Post.Type.TEXT,

    val imageFiles: List<FileContentVO>? = null,

    val fullAudioFile: FileContentVO? = null,
    val publicAudioFile: FileContentVO? = null,

    val fullVideoFile: FileContentVO? = null,
    val publicVideoFile: FileContentVO? = null,

    val tags: List<PostCreatorTagVO>? = null,
    @Serializable(LocalDateTimeIso8601Serializer::class) val publishedAt: LocalDateTime? = null,
)

@Serializable
data class PostCreatorTagVO(
    val id: String,
    val name: String,
    val hexColor: String,
) {
    fun toDomain() = PostCreatorTag(
        id = id,
        name = name,
        hexColor = hexColor,
        creatorId = "",
    )
}

fun PostCreatorTag.toVO() = PostCreatorTagVO(id = id, name = name, hexColor = hexColor)

package boostie.pages.appearance

import androidx.compose.runtime.*
import boostie.Navigation
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.layout.DefaultToolbarButtons
import boostie.components.sections.MenuItem
import boostie.components.style.*
import boostie.components.widgets.ProgressIndicator
import boostie.util.Authenticated
import boostie.util.Localization
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*

@Page("/")
@Composable
fun AppearancePage() {
    Authenticated { authViewModel ->
        CenteredPageLayout(
            title = Localization.appearance_label.string,
            type = CenteredPageLayoutType.Navigation(selectedNavItem = MenuItem.Appearance, buttons = { DefaultToolbarButtons(authViewModel) })
        ) {
            AppearanceScreen(
                modifier = Modifier.fillMaxSize().padding(SpaceLarge),
            )
        }

    }
}

@Composable
private fun AppearanceScreen(
    modifier: Modifier = Modifier,
) {
    val isLoading by remember { mutableStateOf(false) }
    val pageContext = rememberPageContext()


    Column(modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth(),
        ) {
            SpanText(
                text = "Appreance",
                modifier = Modifier.then(HeaderTitleStyle.toModifier()).alignContent(AlignContent.Start),
            )
        }

        Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.CenterEnd) {
            Row {

                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    pageContext.router.navigateTo(Navigation.SAMPLE)
                }) {
                    if (!isLoading) SpanText("Sample")
                    else ProgressIndicator(Modifier.align(Alignment.Center))
                }
            }

        }
    }

}
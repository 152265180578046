package boostie.components.layout

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SubTitleStyle
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.ImageView
import boostie.components.widgets.SimpleDivider
import boostie.components.widgets.SimpleUploadButton
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.FlexWrap
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.SupportedFormats

@Composable
fun ImagesContentLayoutView(
    files: List<FileContent>,
    onAddFile: (FileContent) -> Unit,
    onRemoveFile: (String) -> Unit,
) {
    Column(
        Modifier.fillMaxWidth()
    ) {
        Space()
        SimpleDivider()
        Space()
        Row(
            BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth()
                .padding(left = SpaceMedium, right = SpaceMedium, top = SpaceSmall, bottom = SpaceSmall),
            horizontalArrangement = Arrangement.End,
            verticalAlignment = Alignment.CenterVertically
        ) {
            SpanText("Vyberte obrazek, ktery chcete k prispevku pridat", SubTitleStyle.toModifier().fillMaxWidth())
            Space()
            SimpleUploadButton(
                text = null,
                icon = { FaUpload(onPrimaryColor) },
                format = SupportedFormats.image,
                onUploaded = { it?.let { onAddFile(it) } },
                onFailed = {},
            )
        }
        Row(Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)) {
            if (files.isEmpty()) {
//                Box(
//                    modifier = Modifier.borderRadius(RadiusLarge).width(200.px).height(200.px).objectFit(ObjectFit.Cover).fadeInTransition()
//                        .styleModifier { background(MainColors.secondaryGradient) }, contentAlignment = Alignment.Center
//                ) {
//                    FaImage(onPrimaryColor)
//                }
            } else {
                files.forEach { fileContent ->
                    ImageView(fileContent = fileContent, onRemove = { onRemoveFile(it.id) })
                }
            }
        }
    }
}
package boostie.model

import kotlinx.serialization.Serializable
import shared.common.extensions.currentLocalDateTime
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType

@Serializable
data class FileContentVO(
    val id: String,
    val publicUrl: String?,
    val name: String,
    val extension: String,
    val type: FileContentType,
    val size: Long,
    val duration: Float?,
) {
    fun toDomain() = FileContent(
        id = id,
        name = name,
        extension = extension,
        type = type,
        size = size,
        duration = duration,
        publicUrl = publicUrl,
        createdAt = currentLocalDateTime,
        createdBy = ""
    )
}

fun List<FileContentVO>?.toDomain() = this?.map { it.toDomain() } ?: emptyList()

fun FileContent.toVO() = FileContentVO(
    id = id,
    name = name,
    extension = extension,
    type = type,
    size = size,
    duration = duration,
    publicUrl = publicUrl,
)


package shared.business.feature.user.infrastructure

import com.russhwolf.settings.Settings
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import shared.business.feature.common.infrastruture.LocalStorageCache
import shared.business.feature.user.data.source.UserRemoteSource
import shared.common.Result
import shared.common.extensions.map
import shared.common.network.catchingBackendExceptions
import shared.model.error.domain.UserError
import shared.model.feature.user.data.api.UserApiDto
import shared.model.feature.user.data.api.toApiDto
import shared.model.feature.user.data.api.toDomain
import shared.model.feature.user.data.payload.CreateUserPayload
import shared.model.feature.user.domain.User

class ApiUserRemoteSourceImpl(
    private val httpClient: HttpClient,
    settings: Settings,
) : UserRemoteSource {
    private val cache = LocalStorageCache<UserApiDto>("cache_user", settings)

    override suspend fun getUser(id: String, forceRefresh: Boolean): Result<User> {
        if (!forceRefresh) {
            val cachedUser: UserApiDto? = cache.value()
            if (cachedUser != null) return Result.Success(cachedUser.toDomain())
        }

        return try {
            catchingBackendExceptions {
                Result.Success(
                    httpClient.get("user/$id").body<UserApiDto>(),
                ).map {
                    cache.store(it)
                    it.toDomain()
                }
            }
        } catch (e: Exception) {
            Result.Error(UserError.UserNotFound())
        }
    }

    override suspend fun createUser(user: User): Result<Unit> {
        return try {
            catchingBackendExceptions {
                httpClient.post("user/create") {
                    setBody(CreateUserPayload(nickname = user.nickname, email = user.email))
                }
                cache.store(user.toApiDto())
                Result.Success(Unit)
            }
        } catch (e: Exception) {
            Result.Error(UserError.CannotCreateUser())
        }
    }
}

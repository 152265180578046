package shared.business.feature.post.domain.usecase.tags

import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.post.data.payload.CreatePostCreatorTagPayload
import shared.model.feature.post.domain.PostCreatorTag

interface CreatePostCreatorTagUseCase : UseCaseResult<CreatePostCreatorTagPayload, PostCreatorTag>

internal class CreatePostCreatorTagUseCaseImpl internal constructor(
    private val repo: PostCreatorTagRepository,
) : CreatePostCreatorTagUseCase {
    override suspend fun invoke(params: CreatePostCreatorTagPayload): Result<PostCreatorTag> {
        return repo.create(params)
    }
}

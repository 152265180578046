package shared.model.feature.content.data.api

import kotlinx.datetime.toLocalDateTime
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType

@kotlinx.serialization.Serializable
data class FileContentApiDto(
    val id: String,
    val name: String,
    val extension: String,
    val type: FileContentType,
    val size: Long,
    val publicUrl: String?,
    val duration: Float?,
    val createdAt: String,
    val createdBy: String,
)

fun FileContentApiDto.toDomain() = FileContent(
    id = id,
    name = name,
    extension = extension,
    type = type,
    size = size,
    publicUrl = publicUrl,
    duration = duration,
    createdAt = createdAt.toLocalDateTime(),
    createdBy = createdBy,
)

fun FileContent.toApiDto() = FileContentApiDto(
    id = id,
    name = name,
    extension = extension,
    type = type,
    size = size,
    publicUrl = publicUrl,
    duration = duration,
    createdAt = createdAt.toString(),
    createdBy = createdBy,
)

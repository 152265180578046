package shared.model.feature.auth.domain

sealed class AuthState {
    class Authenticated(
        val id: String,
        val email: String?,
        val name: String?,
        val isNewlyCreated: Boolean,
    ) : AuthState()
}

package shared.model.feature.post.domain

import kotlinx.datetime.LocalDateTime

data class Post(
    val id: String,
    val creatorId: String,
    val title: String,
    val description: String,
    val draft: Boolean,
    val createdAt: LocalDateTime,
    val publishedAt: LocalDateTime?,
    val deletedAt: LocalDateTime?,
    val editedAt: LocalDateTime?,
    val publicLink: String?,
    val tagIds: List<String>?,
    val fileItems: FileItems?,
) {

    val type: Type
        get() = Type.TEXT
    enum class Type {
        AUDIO, VIDEO, TEXT,
    }

    data class FileItems(
        val audio: Audio?,
        val video: Video?,
        val images: Images?,
    ) {
        data class Audio(
            val full: ContentFileItem?,
            val public: ContentFileItem?,
        )

        data class Video(
            val full: ContentFileItem?,
            val public: ContentFileItem?,
        )

        data class Images(
            val files: List<ContentFileItem>?,
        )
    }
}

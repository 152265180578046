package boostie.pages.tags.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import boostie.components.Space
import boostie.components.layout.SimpleLoadingLayout
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.RadiusMedium
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SpaceXSmall
import boostie.components.style.primaryColor
import boostie.components.widgets.LabelText
import boostie.components.widgets.PrimaryButton
import boostie.pages.tags.CreatorTagsViewModel
import boostie.util.Localization
import boostie.util.rememberDependency
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.silk.components.icons.fa.FaHashtag
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.FlexWrap
import shared.model.feature.post.domain.PostCreatorTag


@Composable
fun CreatorTagsFilterView(modifier: Modifier = Modifier, onTagClick: (PostCreatorTag) -> Unit) {
    val vm = rememberDependency<CreatorTagsViewModel>()
    val state = vm.state
    LaunchedEffect(vm) {
        vm.onViewDidAppear()
    }
    Column(modifier.fillMaxWidth()) {
        Row(
            Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap).rowGap(SpaceSmall).padding(topBottom = SpaceMedium),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (state.isLoading) {
                SimpleLoadingLayout()
            } else {
                state.tags?.forEach {
                    val props = CreatorPostTagViewProps.Simple(it, onClick = onTagClick)
                    CreatorPostTagView(props)
                    Space(SpaceSmall)
                }
            }
        }
    }

}

@Composable
fun CreatorTagsManagementView(
    tags: List<PostCreatorTag>?,
    tagsLoading: Boolean,
    onCreateTag: (String, String) -> Unit,
    onTagClick: (PostCreatorTag) -> Unit,
) {
    val showDialogCreateTag = remember { mutableStateOf(false) }

    Column(BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth()) {
        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            LabelText("Tvoje kategorie", icon = { FaHashtag(primaryColor) })
            Space(SpaceSmall)
            PrimaryButton(modifier = Modifier.padding(topBottom = SpaceXSmall, leftRight = SpaceMedium).borderRadius(RadiusMedium),
                text = Localization.add_label.string,
                iconLeft = { FaPlus() }) {
                showDialogCreateTag.value = true
            }
        }
        Space(SpaceSmall)

        Row(
            Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap).rowGap(SpaceSmall).padding(topBottom = SpaceMedium),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (tagsLoading) {
                SimpleLoadingLayout()
            } else {
                tags?.forEach {
                    val props = CreatorPostTagViewProps.Simple(it, onClick = onTagClick)
                    CreatorPostTagView(props)
                    Space(SpaceSmall)
                }
            }
        }
    }

    CreateTagDialogView(showDialogCreateTag) { name, hexColor ->
        onCreateTag(name, hexColor)
    }
}
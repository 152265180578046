package boostie.pages.onboarding.states

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.InputStyle
import boostie.components.style.SpaceMediumLarge
import boostie.components.style.SpaceSmall
import boostie.components.style.TitleStyle
import boostie.components.widgets.ButtonIconOrientation
import boostie.components.widgets.ProgressButton
import boostie.components.widgets.SelectableButton
import boostie.components.widgets.SimpleBoxError
import boostie.pages.onboarding.OnboardingStep
import boostie.pages.onboarding.OnboardingVMIntent
import boostie.pages.onboarding.OnboardingViewModel
import boostie.util.Localization
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaAnglesRight
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.TextArea
import shared.model.common.domain.Language

@Composable
fun OnboardingCreateStateView(vm: OnboardingViewModel) {
    val scope = rememberCoroutineScope()
    val descTextSize = 1.2.cssRem
    Column(modifier = Modifier.fadeInTransition()) {
        Space()
        SpanText(
            "Jak se bude jmenovat vas kanal?",
            TitleStyle.toModifier().fillMaxWidth().fontSize(descTextSize)
        )
        Space(SpaceSmall)
        Input(InputType.Text, attrs = InputStyle.toModifier().fillMaxWidth(50.percent).toAttrs {
            placeholder(Localization.name_placeholder_label.string)
            onInput {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.OnNameInput(it.value))
                }
            }
        })

        Space(SpaceMediumLarge)

        SpanText(
            "V jakem jazyze primarne budete svoje prispevky vydavat",
            TitleStyle.toModifier().fillMaxWidth().fontSize(descTextSize)
        )
        Space(SpaceSmall)
        val selectedLanguage = (vm.state.currentOnboardingStep as? OnboardingStep.CreateCreatorStep)?.language
        if (selectedLanguage != null) {
            Row(Modifier) {
                SelectableButton("čeština", isSelected = Language.CS == selectedLanguage) {
                    scope.launch {
                        vm.onIntent(OnboardingVMIntent.OnLanguageInput(Language.CS))
                    }
                }
                Space()
                SelectableButton("slovenština", isSelected = Language.SK == selectedLanguage) {
                    scope.launch {
                        vm.onIntent(OnboardingVMIntent.OnLanguageInput(Language.SK))
                    }
                }
                Space()
                SelectableButton("english", isSelected = Language.EN == selectedLanguage) {
                    scope.launch {
                        vm.onIntent(OnboardingVMIntent.OnLanguageInput(Language.EN))
                    }
                }
            }
        }

        Space(SpaceMediumLarge)
        SpanText(
            "Napis kratky popisek, ktery bude videt u hledu vasich prispevku",
            TitleStyle.toModifier().fillMaxWidth().fontSize(descTextSize)
        )
        Space(SpaceSmall)
        Input(InputType.Text, attrs = InputStyle.toModifier().toAttrs {
            placeholder(Localization.short_bio_placeholder_label.string)
            onInput {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.OnShortBioInput(it.value))
                }
            }
        })
        Space(SpaceMediumLarge)
        SpanText(
            "Napiste vasim fanouskum cokoliv budete chtit jim sdelit jako cele bio o Vas",
            TitleStyle.toModifier().fillMaxWidth().fontSize(descTextSize)
        )
        Space(SpaceSmall)
        TextArea(attrs = InputStyle.toModifier().height(200.px).toAttrs {
            placeholder(Localization.full_bio_placeholder_label.string)
            onInput {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.OnFullBioInput(it.value))
                }
            }
        })

        Space(SpaceMediumLarge)
        if (vm.state.error != null) {
            Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
                SimpleBoxError(error = vm.state.error)
            }
        }
        Space()

        Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
            ProgressButton(
                Localization.create_label.string,
                vm.state.isLoading,
                icon = { FaAnglesRight() },
                iconOrientation = ButtonIconOrientation.Right
            ) {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.CreateCreator)
                }
            }
        }
    }
}
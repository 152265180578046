package domain.file.usecase

import firebasestorage.binding.FirebaseStorage
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import org.w3c.files.File
import shared.business.feature.filecontent.domain.usecase.ReserveFileIdUseCase
import shared.business.feature.filecontent.domain.usecase.CreateFileUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.common.usecase.UseCaseFlow
import shared.common.utils.FileUtils
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType

sealed interface UploadLargeFileResult {
    class Progress(val progress: Long) : UploadLargeFileResult
    class Success(val fileContent: FileContent?) : UploadLargeFileResult
    class Error(val error: ErrorResult) : UploadLargeFileResult
}

interface UploadLargeFileUseCase : UseCaseFlow<UploadLargeFileUseCase.Params, UploadLargeFileResult> {
    data class Params(
        val file: File,
        val duration: Float?,
    )
}

internal class UploadLargeFileUseCaseImpl(
    private val firebaseStorage: FirebaseStorage,
    private val reserveFileId: ReserveFileIdUseCase,
    private val saveFile: CreateFileUseCase,
) : UploadLargeFileUseCase {
    override suspend fun invoke(params: UploadLargeFileUseCase.Params): Flow<UploadLargeFileResult> {
        return callbackFlow<UploadLargeFileResult> {
            val fileId = when (val result = reserveFileId()) {
                is Result.Error -> {
                    val error = ErrorResult("No file ID", IllegalStateException("No file ID"))
                    this.trySend(UploadLargeFileResult.Error(error))
                    close(error.throwable)
                    return@callbackFlow
                }

                is Result.Success -> result.data
            }
            val file = params.file
            val extension = file.extension
            val type = FileContentType.parseByExtension(extension).name

            val reference = firebaseStorage.ref(FileUtils.getBucketUrl(type, fileId, extension))
            val uploadTask = reference.uploadBytesResumable(file)
            uploadTask.on(next = {
                trySend(UploadLargeFileResult.Progress(it.uploadProgress))
            }, error = {
                val error = ErrorResult("Upload failed", IllegalStateException("Upload failed"))
                trySend(UploadLargeFileResult.Error(error))
                close(error.throwable)
            }, complete = {
                val saveFileParams = CreateFileUseCase.Params(
                    id = fileId, name = file.name, extension = file.extension, size = file.size.toLong(), publicUrl = reference.getDownloadURL(),
                    duration = params.duration,
                )
                when (val result = saveFile(saveFileParams)) {
                    is Result.Error -> {
                        trySend(UploadLargeFileResult.Error(result.error))
                        close()
                    }

                    is Result.Success -> {
                        trySend(UploadLargeFileResult.Success(result.data))
                        close()
                    }
                }
            })
            awaitClose {

            }
        }

    }

}

private val File.extension get() = FileUtils.getExtension(this.name)

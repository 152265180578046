package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.CircleIconButtonStyleVariant
import boostie.components.style.MainColors
import boostie.components.style.SizeSmall
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceXSmall
import boostie.pages.post.create.CreatePostDefaults
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaQuestion
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun TitleWithDescriptionView(modifier: Modifier = Modifier, title: String, description: String) {
    Column(modifier.fillMaxWidth()) {
        SpanText(
            title, CreatePostDefaults.titleModifier
        )
        Space(SpaceXSmall)
        SpanText(
            description,
            CreatePostDefaults.descriptionModifier
        )
        Space(SpaceMedium)
    }
}

@Composable
fun TitleWithTooltip(modifier: Modifier = Modifier, title: String, description: String) {
    Column(modifier.fillMaxWidth()) {
        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            Box(
                modifier = Modifier.size(24.px).backgroundColor(MainColors.background).clip(Circle()),
                contentAlignment = Alignment.Center
            ) {
                FaQuestion(Modifier.color(MainColors.primary))
            }
            Tooltip(ElementTarget.PreviousSibling, description, placement = PopupPlacement.RightTop)
            Space(SpaceXSmall)
            SpanText(
                title, CreatePostDefaults.titleModifier
            )
        }
        Space(SpaceMedium)
    }
}

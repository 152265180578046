package shared.business.feature.post.infrastructure

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import shared.business.feature.post.data.source.PostRemoteSource
import shared.common.Result
import shared.common.network.catchingBackendExceptions
import shared.model.error.domain.PostError
import shared.model.feature.post.data.api.PostApiDto
import shared.model.feature.post.data.api.toDomain
import shared.model.feature.post.data.payload.CreatePostPayload
import shared.model.feature.post.data.payload.UpdatePostPayload
import shared.model.feature.post.domain.Post

class ApiPostRemoteSourceImpl(
    private val httpClient: HttpClient,
) : PostRemoteSource {

    override suspend fun createPost(
        payload: CreatePostPayload,
    ): Result<Post> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.post("creator/post/create") {
                    setBody(payload)
                }.body<PostApiDto>()
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(PostError.CreateFailed)
        }
    }

    override suspend fun deletePost(postId: String): Result<Unit> {
        return try {
            catchingBackendExceptions {
                httpClient.delete("creator/post/delete/$postId")
                Result.Success(Unit)
            }
        } catch (e: Exception) {
            Result.Error(PostError.CannotBeDeleted(postId))
        }
    }

    override suspend fun getPostById(postId: String): Result<Post> {
        TODO("Not yet implemented")
    }

    override suspend fun getCurrentCreatorPosts(
        deleted: Boolean?,
        draft: Boolean?,
        tagId: String?,
    ): Result<List<Post>> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.get("creator/post/my") {
                    deleted?.let {
                        parameter("deleted", it)
                    }
                    draft?.let {
                        parameter("draft", it)
                    }
                    tagId?.let {
                        parameter("tagId", it)
                    }
                }.body<List<PostApiDto>>()
                Result.Success(body.map { it.toDomain() })
            }
        } catch (e: Exception) {
            Result.Error(PostError.PostsNotFound)
        }
    }

    override suspend fun updatePost(
        postId: String,
        payload: UpdatePostPayload,
    ): Result<Post> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.post("creator/post/update") {
                    setBody(payload)
                }.body<PostApiDto>()
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(PostError.CannotUpdatePostOfAnotherCreator)
        }
    }
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SubTitleStyle
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.dom.CheckboxInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import shared.common.utils.StringUtils

@Composable
fun PrimaryToggleButton(id: String, checked: Boolean, onChecked: (Boolean) -> Unit) {
    Div(attrs = {
        classes("toggle-switch")
    }) {
        CheckboxInput(checked = checked) {
            id(id)
            classes("toggle-input")
            checked(checked)
            onChange {
                onChecked(it.value)
            }
        }
        Label(forId = id, attrs = {
            classes("toggle-label")
        })
    }
}

@Composable
fun ToggleWithLabel(label: String, checked: Boolean, onChecked: (Boolean) -> Unit) {
    Row(
        BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth()
            .padding(left = SpaceMedium, right = SpaceMedium, top = SpaceSmall, bottom = SpaceSmall),
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        SpanText(label, SubTitleStyle.toModifier().fillMaxWidth())
        Space()
        PrimaryToggleButton(id = StringUtils.getRandomString(), checked = checked, onChecked = onChecked)
    }
}


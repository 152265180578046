package shared.business.feature.creator.domain.usecase

import shared.business.feature.creator.domain.repository.CreatorRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.creator.domain.Creator
import shared.model.feature.creator.domain.UpdateCreatorModel

interface UpdateCreatorUseCase : UseCaseResult<UpdateCreatorUseCase.Params, Creator> {
    data class Params(
        val updateCreatorModel: UpdateCreatorModel,
    )
}

internal class UpdateCreatorUseCaseImpl(
    private val repository: CreatorRepository,
) : UpdateCreatorUseCase {
    override suspend fun invoke(params: UpdateCreatorUseCase.Params): Result<Creator> {
        return repository.updateCreator(
            updateCreatorModel = params.updateCreatorModel,
        )
    }
}

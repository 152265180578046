package shared.model.feature.creator.domain

import kotlinx.datetime.LocalDateTime
import shared.model.common.domain.Language

data class Creator(
    val id: String,
    val userId: String,
    val name: String?,
    val email: String?,
    val photoUrl: String?,
    val backgroundPhotoUrl: String?,
    val shortBio: String?,
    val fullBio: String?,
    val isVerified: Boolean,
    val language: Language?,
    val createdAt: LocalDateTime,
    val lastActivity: LocalDateTime,
)

val Creator.isFulfilled
    get() =
        name != null &&
            email != null &&
            photoUrl != null &&
            backgroundPhotoUrl != null &&
            shortBio != null &&
            fullBio != null &&
            language != null

val Creator.hasFulFilledBasicInfo
    get() =
        name != null &&
            email != null &&
            shortBio != null &&
            fullBio != null &&
            language != null

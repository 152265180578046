package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStylePrimaryDashedVariant
import boostie.components.style.BoxStylePrimaryVariant
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.RadiusMedium
import boostie.components.style.SizeSmall
import boostie.components.style.SpaceSmall
import boostie.components.style.SpaceXSmall
import boostie.components.style.onPrimaryColor
import boostie.components.style.textEllipsis
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaHardDrive
import com.varabyte.kobweb.silk.components.icons.fa.FaImage
import com.varabyte.kobweb.silk.components.icons.fa.FaMusic
import com.varabyte.kobweb.silk.components.icons.fa.FaStopwatch
import com.varabyte.kobweb.silk.components.icons.fa.FaVideo
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Audio
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Video
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType

@Composable
fun FileContentType.Icon() {
    when (this) {
        FileContentType.Audio -> FaMusic(onPrimaryColor)
        FileContentType.Video -> FaVideo(onPrimaryColor)
        FileContentType.Image -> FaImage(onPrimaryColor)
        FileContentType.None -> {}
    }
}

@Composable
fun MediaContentPreview(
    size: CSSNumeric = 300.px,
    tag: String = "aaaaaaaaaaaa",
    fileContent: FileContent,
) {
    Column(
        BoxStyle.toModifier(BoxStylePrimaryDashedVariant).styleModifier { background(MainColors.secondaryGradient) }.padding(SpaceSmall).size(size),
        verticalArrangement = Arrangement.Bottom
    ) {
        Column(Modifier.fillMaxHeight().padding(SpaceSmall)) {
            Row(modifier = Modifier.fillMaxWidth()) {
                fileContent.type.Icon()
            }
            Space(SpaceXSmall)
            Box(BoxStyle.toModifier(BoxStylePrimaryVariant).padding(SpaceSmall).borderRadius(RadiusMedium), contentAlignment = Alignment.Center) {
                SpanText(tag, NormalTextStyle.toModifier().fontSize(0.8.cssRem).fontWeight(FontWeight.SemiBold).color(MainColors.onPrimary))
            }
        }

        MediaFileContent(Modifier.fillMaxWidth(), fileContent)
    }
}

@Composable
fun MediaFileContent(
    modifier: Modifier = Modifier,
    fileContent: FileContent,
    onAction1: (@Composable (FileContent) -> Unit)? = null,
    onAction2: (@Composable (FileContent) -> Unit)? = null,
) {
    Column(BoxStyle.toModifier(BoxStyleSurfaceVariant).padding(SpaceSmall).then(modifier)) {
        Column(modifier = modifier.fillMaxWidth()) {
            when (fileContent.type) {
                FileContentType.Audio -> AudioView(fileContent)
                FileContentType.Video -> VideoView(fileContent)
                FileContentType.Image,
                FileContentType.None,
                -> {
                }
            }
            Space(SpaceSmall)
            Row(Modifier.fillMaxWidth()) {
                PropsLayout(fileContent)

                if (onAction1 != null || onAction2 != null) {
                    Row(
                        Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.End
                    ) {
                        onAction1?.let {
                            onAction1(fileContent)
                            Space(SpaceSmall)
                        }
                        onAction2?.let { onAction2(fileContent) }
                    }
                }

            }
        }
    }
}

@Composable
private fun PropsLayout(fileContent: FileContent) {
    Row(Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap), verticalAlignment = Alignment.CenterVertically) {
        PropertyView(
            icon = {
                FaHardDrive(onPrimaryColor)
            },
            value = "${fileContent.size / 1000000f} MB",
        )
        if (fileContent.duration != null) {
            Space(SpaceSmall)
            PropertyView(
                icon = {
                    FaStopwatch(onPrimaryColor)
                },
                value = "${fileContent.duration}s",
            )
        }
    }
}

@Composable
private fun AudioView(fileContent: FileContent) {
    Audio(attrs = Modifier.fillMaxWidth().borderRadius(RadiusMedium).toAttrs {
        attr("controls", "")
    }) {
        Source(attrs = {
            attr("src", fileContent.publicUrl!!)
            attr("type", "audio/${fileContent.extension}")
        })
    }
}

@Composable
private fun VideoView(fileContent: FileContent) {
    Video(attrs = Modifier.fillMaxWidth().borderRadius(RadiusMedium).toAttrs {
        attr("controls", "")
        attr("preload", "metadata")
    }) {
        Source(attrs = {
            attr("src", fileContent.publicUrl!!)
            attr("type", "video/${fileContent.extension}")
        })
    }
}


@Composable
fun AudioFileContent(modifier: Modifier = Modifier, fileContent: FileContent) {
    Column(modifier = modifier.fillMaxWidth()) {
        Audio(attrs = Modifier.fillMaxWidth().toAttrs {
            attr("controls", "")
        }) {
            Source(attrs = {
                attr("src", fileContent.publicUrl!!)
                attr("type", "audio/${fileContent.extension}")
            })
        }
        Space()
        Row(Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)) {
            PropertyView(
                icon = {
                    FaHardDrive(onPrimaryColor)
                },
                value = "${fileContent.size / 1_000} MB",
            )
            Space(SpaceSmall)
            PropertyView(
                icon = {
                    FaHardDrive(onPrimaryColor)
                },
                value = "${fileContent.size / 1_000} MB",
            )
            if (fileContent.duration != null) {
                Space(SpaceSmall)
                PropertyView(
                    icon = {
                        FaStopwatch(onPrimaryColor)
                    },
                    value = "${fileContent.duration}s",
                )
            }
        }
    }
}

@Composable
private fun PropertyView(icon: @Composable () -> Unit, value: String) {
    val iconModifier =
        Modifier.styleModifier { background(MainColors.primaryGradient) }.borderRadius(RadiusMedium).size(SizeSmall).padding(SpaceSmall)
    Row(Modifier, verticalAlignment = Alignment.CenterVertically) {
        Box(iconModifier, contentAlignment = Alignment.Center) {
            icon()
        }
        Space(SpaceSmall)
        SpanText(value, NormalTextStyle.toModifier().textEllipsis())
    }
}
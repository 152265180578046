package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.widgets.SelectableButton
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import shared.model.feature.post.domain.Post

private val Post.Type.localizedValue
    get() = when (this) {
        Post.Type.AUDIO -> "Audio"
        Post.Type.VIDEO -> "Video"
        Post.Type.TEXT -> "Text"
    }

@Composable
fun PostTypeSelectorView(
    selectedType: Post.Type,
    onTypeChange: (Post.Type) -> Unit,
) {
    val types = listOf(Post.Type.TEXT, Post.Type.AUDIO, Post.Type.VIDEO)
    Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start, verticalArrangement = Arrangement.Center) {
        TitleWithTooltip(
            title = "Typ Prispevku",
            description = "Toto bude nazev prispevku, ktery se bude Tvym odberatelum zobrazovat jako upoutavka na to, co se tam mohou dozvedet. Neco jako Youtube titulek"
        )
        Row(BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth().padding(0.px)) {

            types.forEachIndexed { index, type ->
                SelectableButton(type.localizedValue, isSelected = selectedType == type) {
                    onTypeChange(type)
                }
                if (index != types.lastIndex) {
                    Space()
                }
            }
        }
        Space()
    }
}

package boostie.components.style

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowWrap
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.addVariantBase
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.cssRem


fun Modifier.textEllipsis(lines: Int = 1) = overflow(Overflow.Hidden).overflowWrap(OverflowWrap.Unset).whiteSpace(WhiteSpace.Unset).styleModifier {
    property("text-overflow", "ellipsis")
    property("display", "-webkit-box")
    property("-webkit-line-clamp", lines)
    property("-webkit-box-orient", "vertical")
}

val HeaderTitleStyle by ComponentStyle.base {
    Modifier
        .fontSize(3.cssRem)
        .color(MainColors.onSurface)
        .fontWeight(FontWeight.Bold)
        .overflowWrap(OverflowWrap.BreakWord)
}


val TitleStyle by ComponentStyle.base {
    Modifier
        .fontSize(2.cssRem)
        .color(MainColors.onSurface)
        .fontWeight(FontWeight.Bold)
        .overflowWrap(OverflowWrap.BreakWord)
}

val TitleStyleOnPrimaryVariant by TitleStyle.addVariant {
    base {
        Modifier
            .color(MainColors.onPrimary)
    }
}

val TitleStyleOnBackgroundVariant by TitleStyle.addVariant {
    base {
        Modifier
            .color(MainColors.onBackground)
    }
}

val NormalTextStyle by ComponentStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .color(MainColors.onSurface)
}

val SemiBoldNormalTextStyle by ComponentStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .color(MainColors.onSurface)
        .fontWeight(FontWeight.SemiBold)
}


val SubTitleStyle by ComponentStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .color(MainColors.onSurfaceSecondary)
}


val MutedSpanTextVariant by SpanTextStyle.addVariantBase {
    Modifier.color(MainColors.onBackground.copyf(alpha = 0.7f))
}

val ErrorTextStyle by ComponentStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .color(MainColors.onError).fillMaxWidth().alignContent(AlignContent.Center)
}

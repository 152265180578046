package shared.business.feature.creator.domain.usecase

import shared.business.feature.creator.domain.repository.CreatorRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.creator.domain.Creator

interface GetCreatorByIdUseCase : UseCaseResult<String, Creator>

internal class GetCreatorByIdUseCaseImpl(
    private val repository: CreatorRepository,
) : GetCreatorByIdUseCase {
    override suspend fun invoke(params: String): Result<Creator> {
        return repository.getCreator(id = params)
    }
}

package boostie.pages.post.list.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.RadiusMedium
import boostie.components.style.SpaceMedium
import boostie.components.style.SubTitleStyle
import boostie.components.style.TitleStyle
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.SecondaryButton
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCalendar
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Audio
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Video
import shared.common.utils.StringUtils
import shared.model.feature.post.domain.Post

@Composable
fun PostViewItem(post: Post, onEditClick: (String) -> Unit, onDeleteClick: (String) -> Unit) {
    Column(
        BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth().padding(SpaceMedium).fadeInTransition(),
        verticalArrangement = Arrangement.SpaceAround
    ) {
        SpanText(post.title, modifier = TitleStyle.toModifier().fontSize(1.5.cssRem).fillMaxWidth())
        Space()
        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
            FaCalendar(modifier = Modifier.styleModifier { color(MainColors.primaryGradient) }, size = IconSize.LG)
            Space()
            SpanText(post.publishedAt?.toString() ?: "Unknown", modifier = SubTitleStyle.toModifier().fontSize(1.25.cssRem).fillMaxWidth())
        }
        Space()
        PostItemContentView(post)
        Space()
        val content = post.description
        val contentId = StringUtils.getRandomString()
        LaunchedEffect(content) {
            document.getElementById(contentId)?.innerHTML = content ?: "<p>Obsah</p>"
        }

        Div(attrs = Modifier.fillMaxWidth().toAttrs {
            id(contentId)
        }) {
            P {
                Text(content ?: "<br>")
            }
        }
        Space()
        Row(
            modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.End
        ) {
            SecondaryButton(iconLeft = { FaTrash(onPrimaryColor) }, onClick = { onDeleteClick(post.id) })
            Space()
            SecondaryButton(iconLeft = { FaPen(onPrimaryColor) }, onClick = { onEditClick(post.id) })
        }
    }
}

@Composable
fun PostItemContentView(post: Post) {
    when (post.type) {
        Post.Type.AUDIO -> {
            val file = post.fileItems?.audio?.full
            Audio(attrs = Modifier.width(400.px).height(400.px).toAttrs {
                attr("controls", "")
            }) {
                Source(attrs = {
                    attr("src", file?.publicUrl ?: "")
                    attr("type", "audio/mp3")
                })
            }
        }

        Post.Type.VIDEO -> {
            val file = post.fileItems?.video?.full
            Video(attrs = Modifier.width(400.px).height(400.px).toAttrs {
                attr("controls", "")
            }) {
                Source(attrs = {
                    attr("src", file?.publicUrl ?: "")
                    attr("type", "video/mp4")
                })
            }
        }

        Post.Type.TEXT -> {
            val url = post.fileItems?.images?.files?.firstOrNull()?.publicUrl
            if (url != null) {
                Image(src = url, desc = url, modifier = Modifier.borderRadius(RadiusMedium).fillMaxWidth().padding(SpaceMedium))
            }
        }
    }
}

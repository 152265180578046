package boostie.pages.post.create.content

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.layout.DefaultToolbarButtons
import boostie.components.layout.ImagesContentLayoutView
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SpaceXLarge
import boostie.components.style.SpaceXSmall
import boostie.components.style.SpaceZero
import boostie.components.style.SubTitleStyle
import boostie.components.style.primaryColor
import boostie.components.style.primaryDashedBorder
import boostie.components.widgets.LabelText
import boostie.components.widgets.SimpleDivider
import boostie.components.widgets.ToggleWithLabel
import boostie.model.toDomain
import boostie.model.toVO
import boostie.pages.post.create.CreatePostViewModel
import boostie.pages.post.create.ErrorView
import boostie.pages.post.create.PostModificationStep
import boostie.pages.post.create.onViewComposed
import boostie.pages.post.create.views.AudioFileContentManagementView
import boostie.pages.post.create.views.CreatePostLayout
import boostie.pages.post.create.views.MultiLineTitleContent
import boostie.pages.post.create.views.PostStepToolbarView
import boostie.pages.post.create.views.PostTypeSelectorView
import boostie.pages.post.create.views.RichTextContent
import boostie.pages.post.create.views.VideoFileContentManagementView
import boostie.toHome
import boostie.util.Authenticated
import boostie.util.CheckOnboarding
import boostie.util.Localization
import boostie.util.rememberDependency
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaPodcast
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType
import shared.model.feature.post.domain.Post

@Page("/create/content")
@Composable
fun PostCreateContentPage() {
    val vm = rememberDependency<CreatePostViewModel>()
    val scope = rememberCoroutineScope()
    vm.onViewComposed(PostModificationStep.CreateContent)

    Authenticated { authViewModel ->
        CheckOnboarding {
            CenteredPageLayout(
                title = Localization.create_new_post_label.string,
                type = CenteredPageLayoutType.ToolbarOnly(buttons = { DefaultToolbarButtons(authViewModel) })
            ) {
                CreatePostLayout(toolbar = { router ->
                    PostStepToolbarView("Obsah", onBack = { router.toHome() }, onNext = {
                        scope.launch {
                            vm.onIntent(CreatePostViewModel.Intent.ValidateCurrentStep)
                        }
                    }, nextEnabled = true)
                }, content = {
                    vm.apply {
                        ErrorView()
                    }
                    RootScreen(vm)
                })
            }
        }
    }
}


@Composable
private fun ColumnScope.RootScreen(vm: CreatePostViewModel) {
    val scope = rememberCoroutineScope()

    MultiLineTitleContent(title = Localization.create_new_post_label.string,
        description = "Toto bude nazev prispevku, ktery se bude Tvym odberatelum zobrazovat jako upoutavka na to, co se tam mohou dozvedet. Neco jako Youtube titulek",
        placeholder = "Nazev...",
        value = vm.state.createEditPostVO.title,
        onInput = {
            scope.launch {
                vm.onIntent(CreatePostViewModel.Intent.OnTitleInput(it))
            }
        })
    Space(SpaceXLarge)
    PostTypeSelectorView(selectedType = vm.state.createEditPostVO.type, onTypeChange = { type ->
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnPostTypeChange(type))
        }
    })
    Space()
    val fullAudio = PostViewParams.Audio(audio = vm.state.createEditPostVO.fullAudioFile?.toDomain(), onAdd = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnAddFullAudioFile(it.toVO()))
        }
    }, onRemove = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnRemoveFullAudioFile)
        }
    })
    val publicAudio = PostViewParams.Audio(audio = vm.state.createEditPostVO.publicAudioFile?.toDomain(), onAdd = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnAddPublicAudioFile(it.toVO()))
        }
    }, onRemove = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnRemovePublicAudioFile)
        }
    })
    val fullVideo = PostViewParams.Video(video = vm.state.createEditPostVO.fullVideoFile?.toDomain(), onAdd = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnAddFullVideoFile(it.toVO()))
        }
    }, onRemove = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnRemoveFullVideoFile)
        }
    })
    val publicVideo = PostViewParams.Video(video = vm.state.createEditPostVO.publicVideoFile?.toDomain(), onAdd = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnAddPublicVideoFile(it.toVO()))
        }
    }, onRemove = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnRemovePublicVideoFile)
        }
    })
    val images = PostViewParams.Images(images = vm.state.createEditPostVO.imageFiles.toDomain(), onAdd = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnAddImageFile(it.toVO()))
        }
    }, onRemove = {
        scope.launch {
            vm.onIntent(CreatePostViewModel.Intent.OnRemoveImageFile(it))
        }
    })

    PostContentView(
        type = vm.state.createEditPostVO.type,
        images = images,
        fullAudio = fullAudio,
        publicAudio = publicAudio,
        fullVideo = fullVideo,
        publicVideo = publicVideo,
    )
    Space(SpaceXLarge)
    RichTextContent(value = vm.state.createEditPostVO.description,
        title = "Obsah prispevku",
        description = "Sdelte co mate na srdci. Obsah muze slouzit jako hlavni obsah, ale zaroven jako dodatecny popis pripadnych Vasich dalsich priloh",
        onInput = { html, _ ->
            html?.let {
                scope.launch {
                    vm.onIntent(CreatePostViewModel.Intent.OnDescriptionInput(it))
                }
            }
        })
    Space(SpaceXLarge)
}

sealed interface PostViewParams {
    class Images(
        val images: List<FileContent>,
        val onAdd: (FileContent) -> Unit,
        val onRemove: (String) -> Unit,
    ) : PostViewParams

    class Video(
        val video: FileContent?,
        val onAdd: (FileContent) -> Unit,
        val onRemove: () -> Unit,
    ) : PostViewParams

    class Audio(
        val audio: FileContent?,
        val onAdd: (FileContent) -> Unit,
        val onRemove: () -> Unit,
    ) : PostViewParams
}

@Composable
fun PostContentView(
    type: Post.Type,
    images: PostViewParams.Images,
    fullVideo: PostViewParams.Video,
    publicVideo: PostViewParams.Video,
    fullAudio: PostViewParams.Audio,
    publicAudio: PostViewParams.Audio,
) {
    Column(
        BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth().backgroundColor(MainColors.surface).primaryDashedBorder
    ) {
        when (type) {
            Post.Type.AUDIO -> AudioPostTypeContent(
                fullAudio = fullAudio,
                publicAudio = publicAudio,
            )

            Post.Type.VIDEO -> VideoPostTypeContent(
                fullVideo = fullVideo, publicVideo = publicVideo, fullAudio = fullAudio, publicAudio = publicAudio
            )

            Post.Type.TEXT -> TextPostTypeContent(images)
        }
    }
}


@Composable
fun VideoPostTypeContent(
    // Full Video
    fullVideo: PostViewParams.Video,
    publicVideo: PostViewParams.Video,
    fullAudio: PostViewParams.Audio,
    publicAudio: PostViewParams.Audio,
) {
    var addPublicVersion by remember { mutableStateOf(false) }
    val showPublicVersion = addPublicVersion || publicVideo.video != null

    var addFullAudioVersion by remember { mutableStateOf(false) }
    val showFullAudioVersion = addFullAudioVersion || fullAudio.audio != null

    var addPublicAudioVersion by remember { mutableStateOf(false) }
    val showPublicAudioVersion = addPublicAudioVersion || publicAudio.audio != null



    Column(Modifier.fillMaxWidth()) {
        Column(
            BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.Start
        ) {
            LabelText("Plná varianta Tveho audio podcastu", icon = { FaPodcast(primaryColor) })
            Space(SpaceSmall)
            VideoFileContentManagementView(
                fullVideo.video, uploadLabel = Localization.upload_full_video_label.string, fullVideo.onAdd, fullVideo.onRemove
            )
            ToggleWithLabel("Pridat pouze zvukovou stopu", showFullAudioVersion, onChecked = { addFullAudioVersion = it })
            if (showFullAudioVersion) {
                AudioFileContentManagementView(
                    fullAudio.audio, uploadLabel = Localization.upload_full_audio_label.string, fullAudio.onAdd, fullAudio.onRemove
                )
            }
        }
        Space(SpaceSmall)
        SimpleDivider()
        Space(SpaceSmall)
        ToggleWithLabel("Chci pridat verejnou variantu i pro nepredplatitele", showPublicVersion, onChecked = { addPublicVersion = it })
        if (showPublicVersion) {
            Space(SpaceXSmall)
            Column(
                BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {
                LabelText("Variant pro vsechny posluchace Tveho podcastu", icon = { FaPodcast(primaryColor) })
                Space(SpaceSmall)
                SpanText(
                    "Pridanim se Vas podcast prida na Tvuj profil pro vsechny i nepredplatitele. " + "V pripade, ze Tvuj fanousek je uz predplatitelem, tato cast pro nej bude skryta a uvidi pouze celou verzi podcastu",
                    SubTitleStyle.toModifier().textAlign(TextAlign.Start).fontSize(0.8.cssRem)
                )
                Space(SpaceSmall)
                VideoFileContentManagementView(
                    publicVideo.video, uploadLabel = Localization.upload_public_video_label.string, publicVideo.onAdd, publicVideo.onRemove
                )
                ToggleWithLabel("Pridat pouze zvukovou stopu", showPublicAudioVersion, onChecked = { addPublicAudioVersion = it })
                if (showPublicAudioVersion) {
                    AudioFileContentManagementView(
                        publicAudio.audio, uploadLabel = Localization.upload_public_audio_label.string, publicAudio.onAdd, publicAudio.onRemove
                    )
                }
            }
        }
    }
}


@Composable
fun AudioPostTypeContent(
    fullAudio: PostViewParams.Audio,
    publicAudio: PostViewParams.Audio,
) {
    var addPublicVersion by remember { mutableStateOf(false) }
    val showPublicVersion = addPublicVersion || publicAudio.audio != null

    Column(Modifier.fillMaxWidth()) {
        Column(
            BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.Start
        ) {
            LabelText("Plná varianta Tveho audio podcastu", icon = { FaPodcast(primaryColor) })
            Space(SpaceSmall)
            AudioFileContentManagementView(
                fullAudio.audio, uploadLabel = Localization.upload_full_audio_label.string, fullAudio.onAdd, fullAudio.onRemove
            )
        }
        Space(SpaceSmall)
        SimpleDivider()
        Space(SpaceSmall)
        ToggleWithLabel("Chci pridat verejnou variantu i pro nepredplatitele", showPublicVersion, onChecked = { addPublicVersion = it })
        if (showPublicVersion) {
            Space(SpaceXSmall)
            Column(
                BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {
                LabelText("Variant pro vsechny posluchace Tveho podcastu", icon = { FaPodcast(primaryColor) })
                Space(SpaceSmall)
                SpanText(
                    "Pridanim se Vas podcast prida na Tvuj profil pro vsechny i nepredplatitele. " + "V pripade, ze Tvuj fanousek je uz predplatitelem, tato cast pro nej bude skryta a uvidi pouze celou verzi podcastu",
                    SubTitleStyle.toModifier().textAlign(TextAlign.Start).fontSize(0.8.cssRem)
                )
                Space(SpaceSmall)
                AudioFileContentManagementView(
                    publicAudio.audio,
                    uploadLabel = Localization.upload_public_audio_label.string,
                    publicAudio.onAdd,
                    publicAudio.onRemove,
                )

            }
        }
    }
}


@Composable
fun TextPostTypeContent(
    images: PostViewParams.Images,
) {
    var addImages by remember { mutableStateOf(false) }
    val showImages = addImages || images.images.any { it.type == FileContentType.Image }
    Column(
        BoxStyle.toModifier(BoxStyleSurfaceVariant).padding(SpaceZero).fillMaxWidth(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        SpanText(
            "Vas prispevek bude pouze textovy, chcete li, pridat dalsi obsah vyberte si ho...",
            SubTitleStyle.toModifier().padding(SpaceMedium).textAlign(TextAlign.Center)
        )
        Space(SpaceSmall)
        ToggleWithLabel("Chci pridat obrazky k prispevku", showImages, onChecked = { addImages = it })

        if (showImages) {
            Space()
            ImagesContentLayoutView(images.images, images.onAdd, images.onRemove)
        }
    }
}


package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.SpaceSmall
import boostie.components.widgets.RichTextEditor
import boostie.pages.post.create.CreatePostDefaults
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.css.resize
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.cols
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.dom.TextArea

@Composable
fun RichTextContent(
    value: String?,
    title: String,
    description: String,
    onInput: (String?, String?) -> Unit,
) {

    Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start, verticalArrangement = Arrangement.Center) {
        TitleWithDescriptionView(
            title = title,
            description = description,
        )

        RichTextEditor(
            modifier = Modifier.fillMaxWidth(),
            editorStyleModifier = CreatePostDefaults.inputStyleModifier,
            content = value,
            onInput = onInput
        )

        Space(SpaceSmall)
    }
}


@Composable
fun MultiLineTitleContent(
    title: String,
    description: String,
    placeholder: String,
    value: String?,
    onInput: (String) -> Unit,
) {
    Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start, verticalArrangement = Arrangement.Center) {
        TitleWithTooltip(
            title = title,
            description = description
        )

        TextArea(attrs = CreatePostDefaults.inputStyleModifier.toAttrs {
            defaultValue(value ?: "")
            style { resize(Resize.None) }
            rows(2)
            cols(40)
            placeholder(placeholder)
            onInput {
                onInput(it.value)
            }
        })
    }
}
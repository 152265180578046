package shared.business.feature.auth.data.repository

import shared.business.feature.auth.data.source.FirebaseAuthSource
import shared.business.feature.auth.domain.repository.AuthRepository
import shared.common.Result
import shared.model.feature.auth.domain.AuthState

internal class AuthRepositoryImpl(
    private val source: FirebaseAuthSource,
) : AuthRepository {

    override suspend fun signWithEmail(email: String, password: String): Result<AuthState> {
        return source.signInWithEmail(email, password)
    }

    override suspend fun createEmailAccount(email: String, password: String): Result<AuthState> {
        return source.createEmailAccount(email, password)
    }

    override suspend fun signWithFacebook(): Result<AuthState> {
        TODO("Not yet implemented")
    }

    override suspend fun signWithApple(): Result<AuthState> {
        TODO("Not yet implemented")
    }

    override suspend fun signWithGoogle(tokenId: String): Result<AuthState> {
        return source.signInWithGoogle(tokenId)
    }

    override suspend fun signOut(): Result<Unit> {
        return source.signOut()
    }

    override suspend fun getCurrentUid(): Result<String> {
        return source.getCurrentUid()
    }

    override suspend fun isSignedIn(): Result<Boolean> {
        return Result.Success(source.isUserAuthenticated())
    }
}

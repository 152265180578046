package shared.business.feature.user

import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.user.data.repository.UserRepositoryImpl
import shared.business.feature.user.domain.repository.UserRepository
import shared.business.feature.user.domain.usecase.*

internal val userModule = module {
    singleOf(::UserRepositoryImpl) bind UserRepository::class

    factoryOf(::GetUserByIdUseCaseImpl) bind GetUserByIdUseCase::class
    factoryOf(::GetCurrentUserUseCaseImpl) bind GetCurrentUserUseCase::class
    factoryOf(::CreateUserUseCaseImpl) bind CreateUserUseCase::class
}

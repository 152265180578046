// ktlint-disable
package shared.model.feature.content.data.payload

import shared.model.feature.content.domain.FileContentType

@kotlinx.serialization.Serializable
data class SaveFileContentPayload(
    val id: String,
    val name: String,
    val extension: String,
    val type: FileContentType,
    val size: Long,
    val publicUrl: String?,
    val duration: Float? = null,
)

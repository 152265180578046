package boostie.pages.onboarding

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import boostie.util.ValidationError
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import shared.model.feature.creator.domain.UpdateCreatorModel
import shared.business.feature.creator.domain.usecase.CreateCreatorUseCase
import shared.business.feature.creator.domain.usecase.GetCreatorByIdUseCase
import shared.business.feature.creator.domain.usecase.UpdateCreatorUseCase
import shared.business.feature.user.domain.usecase.GetCurrentUserUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.model.common.domain.Language
import shared.model.feature.creator.domain.Creator
import shared.model.feature.creator.domain.hasFulFilledBasicInfo
import shared.model.feature.user.domain.User

class OnboardingViewModel(
    private val currentUser: GetCurrentUserUseCase,
    private val getCreatorById: GetCreatorByIdUseCase,
    private val createCreatorUseCase: CreateCreatorUseCase,
    private val updateCreatorUseCase: UpdateCreatorUseCase,
) : BaseIntentViewModel<OnboardingVMState, OnboardingVMIntent>(OnboardingVMState()) {
    private val _events = MutableSharedFlow<OnboardingVMEvents>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: OnboardingVMIntent) {
        when (intent) {
            is OnboardingVMIntent.OnLanguageInput -> {
                val currentOnboardingStep = state.currentOnboardingStep
                if (currentOnboardingStep is OnboardingStep.CreateCreatorStep) {
                    state = state.copy(currentOnboardingStep = currentOnboardingStep.copy(language = intent.input))
                }
            }

            is OnboardingVMIntent.OnFullBioInput -> {
                val currentOnboardingStep = state.currentOnboardingStep
                if (currentOnboardingStep is OnboardingStep.CreateCreatorStep) {
                    state = state.copy(currentOnboardingStep = currentOnboardingStep.copy(fullBio = intent.input))
                }
            }

            is OnboardingVMIntent.OnNameInput -> {
                val currentOnboardingStep = state.currentOnboardingStep
                if (currentOnboardingStep is OnboardingStep.CreateCreatorStep) {
                    state = state.copy(currentOnboardingStep = currentOnboardingStep.copy(name = intent.input))
                }
            }

            is OnboardingVMIntent.OnShortBioInput -> {
                val currentOnboardingStep = state.currentOnboardingStep
                if (currentOnboardingStep is OnboardingStep.CreateCreatorStep) {
                    state = state.copy(currentOnboardingStep = currentOnboardingStep.copy(shortBio = intent.input))
                }
            }

            OnboardingVMIntent.CreateCreator -> {
                val currentOnboardingStep = state.currentOnboardingStep as OnboardingStep.CreateCreatorStep
                createCreator(currentOnboardingStep)
            }

            is OnboardingVMIntent.OnAddBackgroundPhotoUrl -> {
                val publicUrl = intent.input
                state = state.copy(creator = state.creator?.copy(backgroundPhotoUrl = publicUrl))
            }

            is OnboardingVMIntent.OnAddProfilePhotoUrl -> {
                val publicUrl = intent.input
                state = state.copy(creator = state.creator?.copy(photoUrl = publicUrl))
            }

            OnboardingVMIntent.OnSaveProfilePhoto -> {
                val creator = state.creator ?: return // TODO: error result
                val url = creator.photoUrl ?: return // TODO: error result
                when (val result =
                    updateCreatorUseCase(UpdateCreatorUseCase.Params(UpdateCreatorModel(id = creator.id, photoUrl = url)))) {
                    is Result.Error -> TODO()
                    is Result.Success -> {

                        goToNextStep()
                    }
                }
            }

            OnboardingVMIntent.OnSaveBackgroundPhoto -> {
                val creator = state.creator ?: return // TODO: error result
                val url = creator.backgroundPhotoUrl ?: return // TODO: error result
                when (val result =
                    updateCreatorUseCase(UpdateCreatorUseCase.Params(UpdateCreatorModel(id = creator.id, backgroundPhotoUrl = url)))) {
                    is Result.Error -> TODO()
                    is Result.Success -> {
                        // TODO: refresh current user !!! or change onboarding logic!
                        goToNextStep()
                    }
                }
            }
        }
    }

    private suspend fun createCreator(step: OnboardingStep.CreateCreatorStep) {
        state = state.copy(isLoading = true, error = null)
        val validationError = step.validate()
        if (validationError != null) {
            state = state.copy(isLoading = false, error = validationError)
            return
        }
        val user = state.user
        if (user == null) {
            // TODO: do some validation

        } else {
            when (val result = createCreatorUseCase(
                CreateCreatorUseCase.Params(
                    id = user.id,
                    name = step.name,
                    email = user.email ?: "",
                    shortBio = step.shortBio,
                    fullBio = step.fullBio,
                    language = step.language
                )
            )) {
                is Result.Error -> state = state.copy(isLoading = false, error = result.error)
                is Result.Success -> {
                    fetchCreator(user.id)
                    goToNextStep()
                }
            }
        }
    }

    override suspend fun onViewDidAppear() {
        state = state.copy(fullScreenLoading = true)
        when (val result = currentUser(GetCurrentUserUseCase.Params.Get)) {
            is Result.Error -> {

            }

            is Result.Success -> {
                val user = result.data
                state = state.copy(user = user)
                if (user.isCreator) {
                    fetchCreator(user.creatorId ?: "")
                    resolveCurrentStepByCreator(state.creator)
                }
            }
        }
        state = state.copy(fullScreenLoading = false)
    }

    private suspend fun fetchCreator(creatorId: String) {
        val creator = getCreatorById(creatorId).getOrNull()
        state = state.copy(creator = creator)

    }

    private fun resolveCurrentStepByCreator(creator: Creator?) {
        if (creator == null) {
            state = state.copy(currentOnboardingStep = OnboardingStep.CreateCreatorStep())
            return
        }
        if (!creator.hasFulFilledBasicInfo) {
            state = state.copy(currentOnboardingStep = OnboardingStep.CreateCreatorStep())
            return
        }
        if (creator.photoUrl == null) {
            state = state.copy(currentOnboardingStep = OnboardingStep.AddPhotoStep())
            return
        }

        if (creator.backgroundPhotoUrl == null) {
            state = state.copy(currentOnboardingStep = OnboardingStep.AddBackgroundPhotoStep())
            return
        }
    }


    private suspend fun goToNextStep() {
        state = when (state.currentOnboardingStep) {
            is OnboardingStep.AddBackgroundPhotoStep -> {
                _events.emit(OnboardingVMEvents.FinishOnboarding)
                state
            }

            is OnboardingStep.AddPhotoStep -> state.copy(currentOnboardingStep = OnboardingStep.AddBackgroundPhotoStep(), isLoading = false)
            is OnboardingStep.CreateCreatorStep -> state.copy(currentOnboardingStep = OnboardingStep.AddPhotoStep(), isLoading = false)
        }
    }
}

data class OnboardingVMState(
    val fullScreenLoading: Boolean = true,
    val isLoading: Boolean = false,
    val error: ErrorResult? = null,
    val user: User? = null,
    val creator: Creator? = null,
    val currentOnboardingStep: OnboardingStep = OnboardingStep.CreateCreatorStep(),
) : VmState

sealed interface OnboardingStep {
    data class CreateCreatorStep(
        val name: String = "",
        val shortBio: String = "",
        val fullBio: String = "",
        val language: Language = Language.CS,
    ) : OnboardingStep {
        fun validate(): ErrorResult? {
            if (name.isEmpty()) return ValidationError.NoName
            if (shortBio.isEmpty()) return ValidationError.NoShortBio
            if (fullBio.isEmpty()) return ValidationError.NoFullBio
            return null
        }
    }

    data class AddPhotoStep(val publicUrl: String? = null) : OnboardingStep
    data class AddBackgroundPhotoStep(val publicUrl: String? = null) : OnboardingStep
}

sealed interface OnboardingVMIntent : VmIntent {
    class OnNameInput(val input: String) : OnboardingVMIntent
    class OnShortBioInput(val input: String) : OnboardingVMIntent
    class OnFullBioInput(val input: String) : OnboardingVMIntent
    class OnLanguageInput(val input: Language) : OnboardingVMIntent
    class OnAddProfilePhotoUrl(val input: String) : OnboardingVMIntent
    class OnAddBackgroundPhotoUrl(val input: String) : OnboardingVMIntent
    object CreateCreator : OnboardingVMIntent
    object OnSaveProfilePhoto : OnboardingVMIntent
    object OnSaveBackgroundPhoto : OnboardingVMIntent
}

sealed interface OnboardingVMEvents {
    object FinishOnboarding : OnboardingVMEvents
}

package shared.business.feature.creator.data.repository

import kotlinx.datetime.Clock
import shared.business.feature.creator.data.source.CreatorRemoteSource
import shared.business.feature.creator.domain.repository.CreatorRepository
import shared.common.Result
import shared.common.extensions.asCurrentLocalDateTime
import shared.model.common.domain.Language
import shared.model.feature.creator.domain.Creator
import shared.model.feature.creator.domain.UpdateCreatorModel

internal class CreatorRepositoryImpl(
    private val source: CreatorRemoteSource,
) : CreatorRepository {
    override suspend fun getCreator(id: String): Result<Creator> {
        return source.getCreator(id)
    }

    override suspend fun createCreator(
        id: String,
        name: String,
        shortBio: String,
        fullBio: String,
        email: String,
        language: Language,
    ): Result<Creator> {
        return source.createCreator(
            id = id,
            email = email,
            name = name,
            shortBio = shortBio,
            fullBio = fullBio,
            language = language,
            createdAt = Clock.System.now().asCurrentLocalDateTime,
        )
    }

    override suspend fun updateCreator(
        updateCreatorModel: UpdateCreatorModel,
    ): Result<Creator> {
        return source.updateCreator(updateCreatorModel)
    }
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SpaceSmall
import boostie.util.rememberDependency
import boostie.vm.shared.UploadFileVMIntent
import boostie.vm.shared.UploadFileViewModel
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.ariaDisabled
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.w3c.files.File
import shared.common.ErrorResult
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.SupportedFormats

@Composable
private fun BaseUploadButton(
    format: String = SupportedFormats.all,
    onFilePick: (suspend (File) -> Unit)? = null,
    onUploaded: (FileContent?) -> Unit,
    onUploadProgress: ((Long) -> Unit)? = null,
    onFailed: (ErrorResult) -> Unit,
    variant: ComponentVariant = PrimaryButtonStyleVariant,
    content: @Composable BoxScope.() -> Unit,
    progress: @Composable BoxScope.() -> Unit = { ProgressIndicator(Modifier.align(Alignment.Center).fadeInTransition()) },
) {
    val vm = rememberDependency<UploadFileViewModel>()
    val scope = rememberCoroutineScope()

    Input(InputType.File) {
        hidden()
        id("file-upload-btn")
//            multiple()
        accept(format)
        onInput {
            val files = it.target.files
            if (files != null && files.length == 1) {
                val file = files.item(0)!!
                scope.launch {
                    onFilePick?.invoke(file)
                    vm.onIntent(
                        UploadFileVMIntent.OnUpload(
                            file = file,
                            onSuccess = onUploaded,
                            onProgress = { progress -> onUploadProgress?.invoke(progress) },
                            onError = onFailed
                        )
                    )
                }
//                val fileReader = FileReader()
//                    fileReader.onloadend = {
//                        scope.launch {
//                            uploadFileVm.onIntent(UploadFileVMIntent.OnUpload(file))
//                        }
//                    }
//                    fileReader.readAsArrayBuffer(file)
            }
        }
    }

    Label(forId = "file-upload-btn", attrs = ButtonStyle.toModifier(variant).ariaDisabled(vm.state.isUploading).toAttrs { }) {
        Box {
            if (!vm.state.isUploading) {
                content()
            } else {
                progress()
            }
        }
    }
}

@Composable
fun SimpleUploadButton(
    text: String?,
    icon: (@Composable () -> Unit)? = null,
    onColor: CSSColorValue = MainColors.onPrimary,
    format: String = SupportedFormats.all,
    variant: ComponentVariant = PrimaryButtonStyleVariant,
    onFilePick: (suspend (File) -> Unit)? = null,
    onUploaded: (FileContent?) -> Unit,
    onUploadProgress: ((Long) -> Unit)? = null,
    onFailed: (ErrorResult) -> Unit,
) {
    BaseUploadButton(
        format = format,
        variant = variant,
        onFilePick = onFilePick, onUploaded = onUploaded, onUploadProgress = onUploadProgress, onFailed = onFailed,
        content = {
            Row(Modifier.fadeInTransition()) {
                when {
                    icon != null && !text.isNullOrBlank() -> {
                        icon()
                        Space(SpaceSmall)
                        SpanText(text, NormalTextStyle.toModifier().fontWeight(FontWeight.SemiBold).color(onColor))
                    }

                    icon != null && text.isNullOrBlank() -> {
                        icon()
                    }

                    icon == null && !text.isNullOrEmpty() -> {
                        SpanText(text, NormalTextStyle.toModifier().fontWeight(FontWeight.SemiBold).color(onColor))
                    }
                }
            }
        },
        progress = {
            ProgressIndicator(Modifier.align(Alignment.Center).fadeInTransition())
        }
    )
}

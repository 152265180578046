package shared.model.feature.content.domain

import kotlinx.datetime.LocalDateTime
import shared.model.feature.post.domain.ContentFileItem
import shared.model.feature.post.domain.Post

data class FileContent(
    val id: String,
    val name: String,
    val extension: String,
    val type: FileContentType,
    val size: Long,
    val publicUrl: String?,
    val duration: Float?,
    val createdAt: LocalDateTime,
    val createdBy: String,
)

val FileContent.asPostContentFileItem
    get() = ContentFileItem(
        id = id,
        size = size,
        publicUrl = publicUrl,
        duration = duration,
        type = type,
    )
val FileContent?.asPostType
    get() = when (this?.type) {
        FileContentType.Audio -> Post.Type.AUDIO
        FileContentType.Video -> Post.Type.VIDEO
        FileContentType.Image,
        FileContentType.None,
        null,
        -> Post.Type.TEXT
    }

enum class FileContentType {
    Audio, Video, Image, None,
    ;

    companion object {
        fun parseByExtension(extension: String): FileContentType {
            return when {
                SupportedFormats.imageRegex.matches(extension) -> Image
                SupportedFormats.audioRegex.matches(extension) -> Audio
                SupportedFormats.videoRegex.matches(extension) -> Video
                else -> None
            }
        }
    }
}

object SupportedFormats {
    const val image = ".png, .jpeg, .jpg, .gif, .webp"
    const val audio = ".mp3, .wav"
    const val video = ".webm, .mp4"
    const val all = "$image, $audio, $video"

    val imageRegex = Regex(".*(png|jpeg|jpg|gif|webp|PNG|JPEG|JPG|GIF|WEBP)")
    val audioRegex = Regex(".*(mp3|wav|MP3|WAV)")
    val videoRegex = Regex(".*(webm|mp4|WEBM|MP4)")
}

@file:OptIn(WarningOfUsage::class)

package shared.business.feature.common.infrastruture

import co.touchlab.kermit.Logger
import com.russhwolf.settings.Settings
import com.russhwolf.settings.set
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import shared.common.annotation.WarningOfUsage
import shared.common.network.defaultJson

class LocalStorageCache<T>(
    private val key: String,
    private val settings: Settings,
) {

    inline fun <reified T> store(obj: T?) {
        if (obj == null) return
        try {
            currentStoredValue = defaultJson.encodeToString<T>(obj)
        } catch (_: Exception) {
            Logger.i { "Cannot convert User to String" }
        }
    }

    inline fun <reified T> value(): T? {
        return try {
            currentStoredValue?.let {
                Json.decodeFromString<T>(it)
            }
        } catch (e: Exception) {
            null
        }
    }

    fun clear() {
        settings.remove(key)
    }

    @WarningOfUsage
    var currentStoredValue: String?
        get() = settings.getStringOrNull(key)
        set(value) = settings.set(key, value)
}

package shared.business.feature.post.domain.usecase.tags

import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.common.usecase.UseCaseResult
import shared.model.feature.post.data.payload.UpdatePostCreatorTagPayload
import shared.model.feature.post.domain.PostCreatorTag

interface UpdatePostCreatorTagUseCase :
    UseCaseResult<UpdatePostCreatorTagUseCase.Params, PostCreatorTag> {
    data class Params(
        val tagId: String,
        val payload: UpdatePostCreatorTagPayload,
    )
}

internal class UpdatePostCreatorTagUseCaseImpl internal constructor(
    private val repo: PostCreatorTagRepository,
) : UpdatePostCreatorTagUseCase {

    override suspend fun invoke(params: UpdatePostCreatorTagUseCase.Params) =
        repo.update(tagId = params.tagId, payload = params.payload)
}

package shared.common.provider

enum class BuildType {
    Debug, Alpha, Beta, Release
}

interface AppInfoProvider {
    val appVersion: String
    val deviceId: String
    val system: String
    val language: String
    val buildType: BuildType
}

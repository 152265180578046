package shared.model.error.domain

import shared.common.ErrorResult

/**
 * Error type used anywhere in the project. Contains subclasses for common exceptions that can happen anywhere
 * @param throwable optional [Throwable] parameter used for debugging or crash reporting
 */
sealed class CreatorError(throwable: Throwable? = null, message: String? = null) : ErrorResult(
    throwable = throwable,
    message = message,
) {
    object CreateFailedNoUser : CreatorError(message = "No user with this id has been found")
    object CreateFailedAlreadyExist : CreatorError(message = "Creator already exists for this user")
    class CreatorNotFound(t: Throwable? = null) : CreatorError(t)
    class CannotCreateCreator(t: Throwable? = null) : CreatorError(t)
}

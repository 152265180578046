package shared.model.feature.user.data.payload

import kotlinx.serialization.Serializable

@Serializable
data class CreateUserPayload(
    val nickname: String?,
    val email: String?,
)

@Serializable
data class UpdateUserPayload(
    val nickname: String?,
    val email: String?,
    val bio: String?,
)

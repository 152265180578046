package shared.model.error.domain

import shared.common.ErrorResult

/**
 * Error type used anywhere in the project. Contains subclasses for common exceptions that can happen anywhere
 * @param throwable optional [Throwable] parameter used for debugging or crash reporting
 */
sealed class UserError(throwable: Throwable? = null) : ErrorResult(
    throwable = throwable,
) {
    class UserNotFound(t: Throwable? = null) : UserError(t)
    class CannotCreateUser(t: Throwable? = null) : UserError(t)
}

package shared.business.feature.filecontent.domain.usecase

import shared.business.feature.filecontent.domain.repository.FileRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult

interface UploadPhotoUseCase : UseCaseResult<UploadPhotoUseCase.Params, String> {
    data class Params(
        val fileName: String,
        val fileData: ByteArray,
    )
}

internal class UploadPhotoUseCaseImpl(
    private val repo: FileRepository,
) : UploadPhotoUseCase {
    override suspend fun invoke(params: UploadPhotoUseCase.Params): Result<String> {
        return repo.uploadPhoto(params.fileName, params.fileData)
//        return repo.uploadFileStream(params.fileName, params.fileData)
    }
}

package shared.common.network

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.http.*

fun HttpClientConfig<*>.installFirebaseInterceptor(getToken: suspend () -> String?) {
    install("AuthInterceptor") {
        sendPipeline.intercept(HttpSendPipeline.State) {
            val token = getToken()
            if (token != null) {
                context.header(HttpHeaders.Authorization, "Bearer $token")
            }
        }
    }
}

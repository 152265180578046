package firebasestorage.binding

import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.w3c.files.Blob

class FirebaseStorage internal constructor(private val wrapped: firebasestorage.external.FirebaseStorage) {
    fun ref(url: String): StorageReference {
        val ref = firebasestorage.external.ref(wrapped, url)
        return StorageReference(ref)
    }
}


class StorageReference internal constructor(private val wrapped: firebasestorage.external.StorageReference) {
    val bucket get() = wrapped.bucket
    val fullPath get() = wrapped.fullPath
    val name get() = wrapped.name

    suspend fun uploadBytes(data: Blob): UploadResult {
        val result = firebasestorage.external.uploadBytes(wrapped, data, null).await()
        return UploadResult(result)
    }

    suspend fun uploadBytesResumable(data: Blob): UploadTask {
        val uploadTask = firebasestorage.external.uploadBytesResumable(wrapped, data, null)
        return UploadTask(uploadTask)
    }

    suspend fun getDownloadURL(): String = firebasestorage.external.getDownloadURL(wrapped).await()

}


class UploadMetadata internal constructor(private val wrapped: firebasestorage.external.UploadMetadata) {
    val md5Hash: String
        get() = wrapped.md5Hash
}

class FullMetadata internal constructor(private val wrapped: firebasestorage.external.FullMetadata) {
    val bucket: String get() = wrapped.bucket
    val downloadTokens: List<String> get() = wrapped.downloadTokens
    val size: Long get() = wrapped.size.toLong()
}

class UploadResult internal constructor(private val wrapped: firebasestorage.external.UploadResult) {
    val metadata: FullMetadata get() = FullMetadata(wrapped.metadata)
    val ref: StorageReference get() = StorageReference(wrapped.ref)
}

class UploadTaskSnapshot internal constructor(private val wrapped: firebasestorage.external.UploadTaskSnapshot) {
    val bytesTransferred: Long get() = wrapped.bytesTransferred.toLong()
    val totalBytes: Long get() = wrapped.totalBytes.toLong()
    val ref: StorageReference get() = StorageReference(wrapped.ref)
    val metadata: FullMetadata get() = FullMetadata(wrapped.metadata)
    val uploadProgress: Long
        get() = try {
            ((bytesTransferred.toFloat() / totalBytes) * 100).toLong()
        } catch (ex: Exception) {
            0
        }
}


class UploadTask internal constructor(private val wrapped: firebasestorage.external.UploadTask) {
    val snapshot: UploadTaskSnapshot get() = UploadTaskSnapshot(wrapped.snapshot)
    @OptIn(DelicateCoroutinesApi::class)
    suspend fun on(next: (UploadTaskSnapshot) -> Unit, error: (() -> Unit)? = null, complete: (suspend () -> Unit)? = null) {
        val nextBlock: (firebasestorage.external.UploadTaskSnapshot) -> Unit = {
            next(UploadTaskSnapshot(it))
        }

        wrapped.on("state_changed",
            nextOrObserver = nextBlock,
            error = {
                error?.invoke()
            }, complete = {
                GlobalScope.launch {
                    complete?.invoke()
                }
            }
        )
    }

}


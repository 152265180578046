package shared.business.infrastructure

import co.touchlab.kermit.Logger
import com.russhwolf.settings.Settings
import com.russhwolf.settings.set
import dev.bitspittle.firebase.app.FirebaseApp
import dev.bitspittle.firebase.auth.GoogleAuthProvider
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import shared.business.feature.auth.data.source.FirebaseAuthSource
import shared.common.ErrorResult
import shared.common.Result
import shared.common.error.AuthError
import shared.common.provider.ApplicationCoroutineScope
import shared.model.feature.auth.domain.AuthState

internal class JsFirebaseAuthSourceImpl(
    firebaseApp: FirebaseApp,
    private val settings: Settings,
    scope: ApplicationCoroutineScope,
) : FirebaseAuthSource {
    private val auth = firebaseApp.getAuth()

    init {
        scope.scope.launch {
            auth.onAuthStateChanged {
                isAuthenticatedLocalStorage = it != null
            }
        }
    }

    private var isAuthenticatedLocalStorage: Boolean
        get() = settings.getBoolean(IS_AUTHENTICATED_KEY, false)
        set(value) = settings.set(IS_AUTHENTICATED_KEY, value)

    override suspend fun signInWithGoogle(tokenId: String): Result<AuthState> {
        return try {
            val result = auth.signInWithPopup(GoogleAuthProvider())
            //        result.user.sendEmailVerification()
            isAuthenticatedLocalStorage = true
            Result.Success(
                AuthState.Authenticated(
                    result.user.uid,
                    email = result.user.email,
                    name = result.user.displayName,
                    false,
                ),
            )
        } catch (ex: dev.bitspittle.firebase.auth.AuthError) {
            Result.Error(ErrorResult(message = ex.code.text, throwable = ex))
        }
    }

    override suspend fun signInWithEmail(email: String, password: String): Result<AuthState> {
        return try {
            val result = auth.signInWithEmailAndPassword(email, password)
            isAuthenticatedLocalStorage = true
            Result.Success(
                AuthState.Authenticated(
                    result.user.uid,
                    email = email,
                    name = result.user.displayName,
                    false,
                ),
            )
        } catch (ex: dev.bitspittle.firebase.auth.AuthError) {
            Result.Error(ErrorResult(message = ex.code.text, throwable = ex))
        }
    }

    override suspend fun createEmailAccount(email: String, password: String): Result<AuthState> {
        return try {
            val result = auth.createUserWithEmailAndPassword(email, password)
            result.user.sendEmailVerification()
            isAuthenticatedLocalStorage = true
            Result.Success(
                AuthState.Authenticated(
                    result.user.uid,
                    email = email,
                    name = result.user.displayName,
                    false,
                ),
            )
        } catch (ex: dev.bitspittle.firebase.auth.AuthError) {
            Result.Error(ErrorResult(message = ex.code.text, throwable = ex))
        }
    }

    override suspend fun getCurrentUid(): Result<String> {
        val userId = try {
            auth.currentUser?.uid
        } catch (t: Throwable) {
            Logger.e("Auth", t) { "Exception while retrieving the token" }
            null
        }

        return if (userId != null) {
            Result.Success(userId)
        } else {
            Result.Error(AuthError.NoUserLoggedIn)
        }
    }

    override suspend fun signOut(): Result<Unit> {
        if (auth.currentUser == null) return Result.Error(AuthError.NoUserLoggedIn)
        settings.clear()
        auth.signOut()
        return Result.Success(Unit)
    }

    override suspend fun getToken(): Result<String> {
        val token = try {
            auth.currentUser?.getIdToken(false)
        } catch (t: Throwable) {
            Logger.e("Auth", t) { "Exception while retrieving the token" }
            null
        }

        return if (token != null) {
            Result.Success(token)
        } else {
            Result.Error(AuthError.NoUserLoggedIn)
        }
    }

    override suspend fun isUserAuthenticated(): Boolean {
        if (isAuthenticatedLocalStorage) return isAuthenticatedLocalStorage
        delay(1000)
        isAuthenticatedLocalStorage = auth.currentUser != null
        return isAuthenticatedLocalStorage
    }

    companion object {
        private const val IS_AUTHENTICATED_KEY = "isAuthenticated"
    }
}

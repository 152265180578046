package boostie.pages.onboarding.states

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.MainColors
import boostie.components.style.RadiusMedium
import boostie.components.style.SpaceLarge
import boostie.components.style.SpaceXLarge
import boostie.components.widgets.ButtonIconOrientation
import boostie.components.widgets.ProgressButton
import boostie.components.widgets.SimpleUploadButton
import boostie.pages.onboarding.OnboardingVMIntent
import boostie.pages.onboarding.OnboardingViewModel
import boostie.util.Localization
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAnglesRight
import com.varabyte.kobweb.silk.components.icons.fa.FaImage
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.px

@Composable
fun UpdateProfilePhotoView(vm: OnboardingViewModel) {
    val scope = rememberCoroutineScope()
    val url = vm.state.creator?.photoUrl

    Column(Modifier.fillMaxWidth().fadeInTransition(), horizontalAlignment = Alignment.CenterHorizontally) {
        Space(SpaceLarge)
        val photoModifier = Modifier.clip(Circle()).size(200.px).objectFit(ObjectFit.Cover).fadeInTransition()
        if (!url.isNullOrEmpty()) {
            Image(url, modifier = photoModifier)
        } else {
            Box(photoModifier.styleModifier { background(MainColors.secondaryGradient) }) {
                FaImage(Modifier.color(MainColors.onPrimary))
            }
        }
        Space()
        SimpleUploadButton(
            text = Localization.choose_label.string,
            icon = { FaUpload() },
            onUploaded = {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.OnAddProfilePhotoUrl(it?.publicUrl ?: ""))
                }
            },
            onFailed = {

            }
        )
        Space(SpaceXLarge)
        if (!url.isNullOrEmpty()) {
            Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
                ProgressButton(
                    Localization.continue_label.string,
                    vm.state.isLoading,
                    icon = { FaAnglesRight() },
                    iconOrientation = ButtonIconOrientation.Right
                ) {
                    scope.launch {
                        vm.onIntent(OnboardingVMIntent.OnSaveProfilePhoto)
                    }
                }
            }
        }
    }
}


@Composable
fun UpdateBackgroundPhotoView(vm: OnboardingViewModel) {
    val scope = rememberCoroutineScope()
    val url = vm.state.creator?.backgroundPhotoUrl

    Column(Modifier.fillMaxWidth().fadeInTransition(), horizontalAlignment = Alignment.CenterHorizontally) {
        Space(SpaceLarge)
        val photoModifier = Modifier.borderRadius(RadiusMedium)
            .width(200.px)
            .height(400.px)
            .objectFit(ObjectFit.Cover)
            .fadeInTransition()
        if (!url.isNullOrEmpty()) {
            Image(url, modifier = photoModifier)
        } else {
            Box(photoModifier.styleModifier { background(MainColors.secondaryGradient) }, contentAlignment = Alignment.Center) {
                FaImage(Modifier.color(MainColors.onPrimary), size = IconSize.XL)
            }
        }
        Space()
        SimpleUploadButton(text = Localization.choose_label.string, icon = { FaUpload() },
            onUploaded = {
                scope.launch {
                    vm.onIntent(OnboardingVMIntent.OnAddBackgroundPhotoUrl(it?.publicUrl ?: ""))
                }
            },
            onFailed = {

            }
        )
        Space(SpaceXLarge)
        if (!url.isNullOrEmpty()) {
            Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
                ProgressButton(
                    Localization.continue_label.string,
                    vm.state.isLoading,
                    icon = { FaAnglesRight() },
                    iconOrientation = ButtonIconOrientation.Right
                ) {
                    scope.launch {
                        vm.onIntent(OnboardingVMIntent.OnSaveBackgroundPhoto)
                    }
                }
            }
        }
    }
}


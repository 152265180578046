package shared.business.feature.common.usecase

import kotlinx.coroutines.flow.Flow
import shared.common.provider.ConnectivityProvider
import shared.common.usecase.UseCaseFlowNoParams

interface HasConnectivityFlowUseCase : UseCaseFlowNoParams<Boolean>

internal class HasConnectivityFlowUseCaseImpl(
    private val connectivityProvider: ConnectivityProvider,
) : HasConnectivityFlowUseCase {
    override suspend fun invoke(): Flow<Boolean> = connectivityProvider.getConnectivityFlow()
}

package shared.model.feature.post.data.api

import kotlinx.serialization.Serializable

@Serializable
data class ContentFileItemApiDto(
    val id: String,
    val size: Long,
    val publicUrl: String?,
    val duration: Float?,
    val type: String,
)

// ktlint-disable
package shared.common.utils

class FileUtils {

    companion object {
        fun getExtension(fileName: String?) = fileName?.takeLastWhile { it != '.' } ?: ""

        fun getBucketUrl(type: String, id: String, extension: String) =
            "${type.lowercase()}/$id.$extension"
    }
}

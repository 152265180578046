package shared.business.feature.post.domain.usecase

import shared.business.feature.post.domain.repository.PostRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.post.data.payload.UpdatePostPayload
import shared.model.feature.post.domain.Post

interface UpdatePostUseCase : UseCaseResult<UpdatePostUseCase.Params, Post> {
    data class Params(
        val postId: String,
        val payload: UpdatePostPayload,
    )
}

internal class UpdatePostUseCaseImpl(
    private val repo: PostRepository,
) : UpdatePostUseCase {
    override suspend fun invoke(params: UpdatePostUseCase.Params): Result<Post> =
        repo.updatePost(postId = params.postId, payload = params.payload)
}

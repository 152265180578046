package shared.business.di

import dev.bitspittle.firebase.app.FirebaseApp
import dev.bitspittle.firebase.app.FirebaseOptions
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.auth.data.source.FirebaseAuthSource
import shared.business.feature.creator.data.source.CreatorRemoteSource
import shared.business.feature.creator.infrastructure.ApiCreatorRemoteSourceImpl
import shared.business.feature.post.data.source.PostCreatorTagRemoteSource
import shared.business.feature.post.data.source.PostRemoteSource
import shared.business.feature.post.infrastructure.ApiPostCreatorTagRemoteSourceImpl
import shared.business.feature.post.infrastructure.ApiPostRemoteSourceImpl
import shared.business.feature.user.data.source.UserRemoteSource
import shared.business.feature.user.infrastructure.ApiUserRemoteSourceImpl
import shared.business.infrastructure.JsFirebaseAuthSourceImpl

val firebaseOptions = FirebaseOptions(
    apiKey = "AIzaSyDlXxMCxDngW_AK7pUWyzHDs6TMA0hRfus",
    authDomain = "boostie-test.firebaseapp.com",
    projectId = "boostie-test",
    storageBucket = "boostie-test.appspot.com",
    messagingSenderId = "685172535379",
    appId = "1:685172535379:web:0fdf8df41eedca968f96a6",
    measurementId = "G-QSGV0MCGD4",
    databaseURL = "https://boostie-test-default-rtdb.europe-west1.firebasedatabase.app",
)

internal actual val platformModule = module {
    single {
        FirebaseApp.initialize(firebaseOptions)
    }

    // Sources
    singleOf(::JsFirebaseAuthSourceImpl) bind FirebaseAuthSource::class

    // User
    single<UserRemoteSource> { ApiUserRemoteSourceImpl(get(boostieBackendKtorClient), get()) }

    // Creator
    single<CreatorRemoteSource> { ApiCreatorRemoteSourceImpl(get(boostieBackendKtorClient), get()) }

    // Post
    single<PostRemoteSource> { ApiPostRemoteSourceImpl(get(boostieBackendKtorClient)) }
    single<PostCreatorTagRemoteSource> {
        ApiPostCreatorTagRemoteSourceImpl(
            get(boostieBackendKtorClient),
        )
    }
}

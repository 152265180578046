package boostie.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.layout.DefaultToolbarButtons
import boostie.components.sections.MenuItem
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.InputStyle
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SizeXXLarge
import boostie.components.style.SizeXXXLarge
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.TitleStyle
import boostie.components.style.TitleStyleOnPrimaryVariant
import boostie.components.style.onPrimaryColor
import boostie.components.style.textEllipsis
import boostie.components.widgets.BigBoostieProgressIndicator
import boostie.components.widgets.PrimaryButton
import boostie.components.widgets.ToolbarDialogOverlayView
import boostie.pages.post.list.PostsVMIntent
import boostie.pages.post.list.PostsViewModel
import boostie.pages.post.list.views.PostViewItem
import boostie.pages.post.list.views.PostsToolbarView
import boostie.toCreateContent
import boostie.util.Authenticated
import boostie.util.CheckOnboarding
import boostie.util.Localization
import boostie.util.rememberDependency
import co.touchlab.kermit.Logger
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontStyle
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.TextArea
import shared.model.feature.post.domain.Post

@Page
@Composable
fun PostsPage() {
    Authenticated { authViewModel ->
        CheckOnboarding {
            CenteredPageLayout(
                title = Localization.posts_label.string,
                type = CenteredPageLayoutType.Navigation(selectedNavItem = MenuItem.Posts, buttons = { DefaultToolbarButtons(authViewModel) })
            ) {
                RootScreen(
                    modifier = BoxStyle.toModifier(BoxStyleBackgroundVariant).fillMaxWidth().padding(leftRight = SpaceSmall),
                )
            }
        }
    }
}

@Composable
private fun RootScreen(
    modifier: Modifier = Modifier,
) {
    var isNewPostViewVisible by remember { mutableStateOf(false) }
    val pageContext = rememberPageContext()
    val scope = rememberCoroutineScope()
    val vm = rememberDependency<PostsViewModel>()
    val state = vm.state
    val isLoading = state.isLoading

    LaunchedEffect(vm) {
        vm.onViewDidAppear()
    }

    fun onNewPostClick() {
        pageContext.router.toCreateContent()
    }

    Column(modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
        PostsToolbarView(onNewPostClick = {
            onNewPostClick()
//            scope.launch { vm.onIntent(PostsVMIntent.CreatePost) }
            isNewPostViewVisible = true
        }, onReload = {
            scope.launch { vm.onIntent(PostsVMIntent.OnRefresh(it)) }
        })

        when {
            isLoading -> {
                Box(Modifier.fillMaxWidth().minHeight(SizeXXXLarge), contentAlignment = Alignment.Center) {
                    BigBoostieProgressIndicator()
                }
            }

            state.posts.isNullOrEmpty() -> {
                Box(Modifier.fillMaxWidth().minHeight(SizeXXXLarge), contentAlignment = Alignment.Center) {
                    Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center) {
                        SpanText("No posts yet", modifier = TitleStyle.toModifier().fontSize(1.5.cssRem))
                        Space()
                        PrimaryButton(text = Localization.create_label.string, iconLeft = { FaPlus(onPrimaryColor) }) {
                            onNewPostClick()
                        }
                    }
                }
            }

            else -> {
                PostListView(posts = state.posts, onEditClick = {}, onDeleteClick = {})
            }
        }
    }

    if (isNewPostViewVisible) {
        ToolbarDialogOverlayView("Novy prispevek", onDismiss = { isNewPostViewVisible = false }) {
            CreatePostView { isNewPostViewVisible = false }
        }
    }
}

@Composable
fun CreatePostView(onDone: () -> Unit) {

    var text by remember { mutableStateOf("") }

    Row(modifier = Modifier.fillMaxWidth().overflow(Overflow.Auto)) {
        // Left
        Column(
            modifier = Modifier.fillMaxHeight().fillMaxWidth(75.percent).padding(SpaceMedium).overflowY(Overflow.Scroll),
        ) {
            Space(SpaceSmall)

            Row(modifier = Modifier.attrsModifier {
                id("toolbar")
            }.fillMaxWidth()) {
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant).attrsModifier {
                    classes("ql-bold")
                }, onClick = {

                }) {
                    SpanText("Bold", modifier = NormalTextStyle.toModifier().fontWeight(FontWeight.Bold))
                }

                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant).attrsModifier {
                    classes("ql-italic")
                }, onClick = {

                }) {
                    SpanText("Italic", modifier = NormalTextStyle.toModifier().fontWeight(FontWeight.Bold))
                }
            }

//            Div({
//                id("toolbar")
//            }) {
//                org.jetbrains.compose.web.dom.Button({
//                    classes("ql-bold")
//                }) {
//                    org.jetbrains.compose.web.dom.Text("Bold")
//                }
//                org.jetbrains.compose.web.dom.Button({
//                    classes("ql-italic")
//                }) {
//                    org.jetbrains.compose.web.dom.Text("Italic")
//                }
//            }
            Box(BoxStyle.toModifier(BoxStyleBackgroundVariant).padding(0.px).fillMaxWidth().attrsModifier {
                id("editor")
            }) {

            }
//            Div({
//                id("editor")
//            }) {
//                P {
//                    org.jetbrains.compose.web.dom.Text("Placeholder")
//
//                }
//            }

            LaunchedEffect(true) {
                js(
                    "var editor = new Quill('#editor',{\n" + "  modules: {\n" + "    toolbar: '#toolbar'\n" + "  },\n" + "  placeholder: 'Compose an epic...'\n" +
//                        "  placeholder: 'Compose an epic...',\n" +
//                        "  theme: 'snow'\n" +
                        "});" + "editor.focus()"
                )
            }

            Row {
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    document.execCommand("bold", false)
                }) {
                    SpanText("Bold", modifier = NormalTextStyle.toModifier().fontWeight(FontWeight.Bold))
                }
                Space()
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    document.execCommand("italic", false)
                }) {
                    SpanText("italic", modifier = NormalTextStyle.toModifier().fontStyle(FontStyle.Italic))
                }
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    document.execCommand("italic", false)
                }) {
                    SpanText("Emoji", modifier = NormalTextStyle.toModifier().fontStyle(FontStyle.Italic))
                }
            }
            Div(attrs = Modifier.toAttrs {
                id("editor")
                attr("width", "100%")
                contentEditable(true)
            }) {
                P {
                    org.jetbrains.compose.web.dom.Text("asdasd")
                }
            }


            TextArea(value = text, attrs = InputStyle.toModifier().toAttrs {
                placeholder(Localization.full_bio_placeholder_label.string)
                onInput {
                    text = it.value
//                    scope.launch {
//                        vm.onIntent(OnboardingVMIntent.OnFullBioInput(it.value))
//                    }
                }
                onSelect {
                    text = "<strong>${it.selection()}</strong>"
                    Logger.d {
                        "selected: ${it.selection()}"
                    }
                }
            })
            for (i in 0..10) {
                Space()
                SpanText(
                    "Jak se bude jmenovat vas kanal?", TitleStyle.toModifier().fillMaxWidth().fontSize(1.5.cssRem)
                )
                Space(SpaceSmall)
                Input(InputType.Text, attrs = InputStyle.toModifier().fillMaxWidth(25.percent).toAttrs {
                    placeholder(Localization.name_placeholder_label.string)
                    onInput {
//                    scope.launch {
//                        vm.onIntent(OnboardingVMIntent.OnNameInput(it.value))
//                    }
                    }
                })
            }
        }
        Column(
            modifier = Modifier.fillMaxHeight().fillMaxWidth(25.percent).padding(SpaceMedium)
                .styleModifier { background(MainColors.primaryGradient) }, verticalArrangement = Arrangement.Center
        ) {
            SpanText(
                "kajsdkjaskdjaksjdlkasjdlkasjdlkasjdlkajdkljaslkdjaskldjalksjdklasjdlk",
                modifier = TitleStyle.toModifier(TitleStyleOnPrimaryVariant).fillMaxWidth().textEllipsis()
            )
        }
    }
}

@Composable
fun PostListView(posts: List<Post>, onEditClick: (String) -> Unit, onDeleteClick: (String) -> Unit) {
    Column(modifier = Modifier.fillMaxWidth().padding(SpaceSmall).fadeInTransition()) {
        posts.forEach {
            PostViewItem(it, onEditClick, onDeleteClick)
            Space(SpaceSmall)
        }
    }
}

package boostie.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import boostie.components.widgets.BigBoostieProgressIndicator
import boostie.toLogin
import boostie.vm.shared.AuthViewModel
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.rememberPageContext
import dev.bitspittle.firebase.app.FirebaseApp
import kotlinx.coroutines.delay

@Composable
fun isAuthenticated(): State<Boolean?> {

    val auth = rememberDependency<FirebaseApp>().getAuth()

    return produceState<Boolean?>(null) {
        auth.onAuthStateChanged { user ->
            value = user != null
        }
        delay(600)
        value = auth.currentUser != null
    }
}

@Composable
fun Authenticated(
    modifier: Modifier = Modifier.fillMaxWidth(),
    content: @Composable (authViewModel: AuthViewModel) -> Unit,
) {
    val authViewModel = rememberDependency<AuthViewModel>()
    val context = rememberPageContext()
    val authenticated by isAuthenticated()

    LaunchedEffect(authenticated) {
        if (authenticated == false) {
            context.router.toLogin()
        }
    }


    if (authenticated == true) {
        Box(modifier) {
            content(authViewModel)
        }
    } else {
        Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            BigBoostieProgressIndicator(Modifier.align(Alignment.Center))
        }
    }
}

package boostie.util

import shared.common.ErrorResult
import shared.model.error.domain.CreatorError

val ErrorResult.localizedMessage: String
    get() = when (this) {
        is CreatorError -> localizedMessage
        is ValidationError -> localizedMessage
        else -> this.message ?: this::class.simpleName.toString()
    }

private val ValidationError.localizedMessage: String
    get() = when (this) {
        ValidationError.NoFullBio -> "Neni vyplneno bio"
        ValidationError.NoImageSelected -> "Neni vybrana zadna fotka"
        ValidationError.NoName -> "Neni vyplnen nazev kanalu"
        ValidationError.NoShortBio -> "Neni vyplnen kratky popis Vaseho profilu"
    }


private val CreatorError.localizedMessage: String
    get() = when (this) {
        CreatorError.CreateFailedAlreadyExist,
        CreatorError.CreateFailedNoUser,
        is CreatorError.CreatorNotFound,
        is CreatorError.CannotCreateCreator,
        -> this.message ?: this::class.simpleName.toString()
    }
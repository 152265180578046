package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.style.BoxStyle
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.SimpleErrorBoxBackgroundVariant
import boostie.util.localizedMessage
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.AlignContent
import shared.common.ErrorResult

@Composable
fun SimpleBoxError(modifier: Modifier = Modifier, error: ErrorResult?) {
    if (error != null) {
        Box(BoxStyle.toModifier(SimpleErrorBoxBackgroundVariant).then(modifier), contentAlignment = Alignment.Center) {
            SpanText(
                error.localizedMessage, modifier = NormalTextStyle.toModifier().color(MainColors.onError).fillMaxWidth().alignContent(
                    AlignContent.Start
                )
            )
        }
    }
}
package boostie.components.layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.sections.Footer
import boostie.components.sections.MenuItem
import boostie.components.sections.RowNavMenu
import boostie.components.sections.SideNavMenu
import boostie.components.style.MainColors
import boostie.components.sections.Header
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.CircleIconButton
import boostie.vm.shared.AuthVMIntent
import boostie.vm.shared.AuthViewModel
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRightFromBracket
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

sealed class CenteredPageLayoutType {
    class Navigation(val selectedNavItem: MenuItem?, val buttons: (@Composable RowScope.() -> Unit)?) : CenteredPageLayoutType()
    class ToolbarOnly(val buttons: (@Composable RowScope.() -> Unit)?, val withAnimation: Boolean = true) : CenteredPageLayoutType()
    object Simple : CenteredPageLayoutType()
}

@Composable
fun DefaultToolbarButtons(authViewModel: AuthViewModel) {
    val scope = rememberCoroutineScope()

    CircleIconButton(icon = { FaArrowRightFromBracket(onPrimaryColor) }) {
        scope.launch { authViewModel.onIntent(AuthVMIntent.SignOut) }
    }
}

@Composable
fun CenteredPageLayout(
    title: String,
    type: CenteredPageLayoutType = CenteredPageLayoutType.Simple,
    colorModifier: Modifier = Modifier.backgroundColor(MainColors.surface),
    fillMaxSize: Boolean = false,
    hideFooter: Boolean = false,
    content: @Composable () -> Unit,
) {
    LaunchedEffect(title) {
        document.title = "Boostie - $title"
    }

//    LaunchedEffect(window.location.href) {
//        // See kobweb config in build.gradle.kts which sets up highlight.js
//        js("hljs.highlightAll()") as Unit
//    }


    // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
    // space at the bottom). "auto" means the use the height of the row. "1fr" means give the rest of the space to
    // that row. Since this box is set to *at least* 100%, the footer will always appear at least on the bottom but
    // can be pushed further down if the first row grows beyond the page.
    val baseModifier = if(fillMaxSize) Modifier.fillMaxSize() else Modifier.fillMaxWidth()
    Box(
        baseModifier.then(colorModifier).fadeInTransition(),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = baseModifier.align(Alignment.TopCenter),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            when (type) {
                is CenteredPageLayoutType.Navigation -> {
                    Navigation(type.selectedNavItem, type.buttons, content = content)
                }

                CenteredPageLayoutType.Simple -> {
                    Simple(content)
                }

                is CenteredPageLayoutType.ToolbarOnly -> {
                    ToolbarOnly(buttons = type.buttons, content = content, withAnimation = type.withAnimation)
                }
            }
            if (!hideFooter) {
                Space()
                Footer(Modifier.gridRowStart(2).gridRowEnd(3))
            }
        }
    }
}

@Composable
private fun ColumnScope.Navigation(selectedNavItem: MenuItem?, buttons: (@Composable RowScope.() -> Unit)?, content: @Composable () -> Unit) = apply {
    val bp = rememberBreakpoint()

    Header(Modifier.fillMaxWidth()) {
        buttons?.invoke(this)
    }

    Space()

    if (bp < Breakpoint.MD) {
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
            selectedNavItem?.let {
                RowNavMenu(modifier = Modifier, selectedNavItem = it)
            }
            ContentColumn(Modifier.fillMaxWidth(), content)
        }
    } else {
        Row(Modifier.fillMaxWidth(75.percent).justifyContent(JustifyContent.FlexStart)) {
            selectedNavItem?.let {
                SideNavMenu(
                    modifier = Modifier.fillMaxWidth(25.percent).minWidth(120.px)
                        .position(Position.Sticky).top(96.px),
                    selectedNavItem = it
                )
            }
            Space()
            ContentColumn(Modifier.fillMaxHeight().fillMaxWidth(75.percent), content)
        }
    }
}

@Composable
private fun ContentColumn(modifier: Modifier, content: @Composable () -> Unit) {
    Column(modifier, horizontalAlignment = Alignment.CenterHorizontally) {
        content()
    }
}


@Composable
private fun ColumnScope.ToolbarOnly(withAnimation: Boolean, buttons: (@Composable RowScope.() -> Unit)?, content: @Composable () -> Unit) =
    apply {
        Header(Modifier.fillMaxWidth(), withAnimation = withAnimation) {
            buttons?.invoke(this)
        }
        Space()
        content()
    }

@Composable
private fun ColumnScope.Simple(content: @Composable () -> Unit) = apply {
    content()
}


//Button(modifier = ButtonStyle.toModifier(CircleIconButtonStyleVariant), onClick = {}) {
//    FaGear(Modifier.color(MainColors.iconOnPrimary))
//}
//VSpace()
//Button(modifier = ButtonStyle.toModifier(CircleIconButtonStyleVariant), onClick = {}) {
//    FaUserAstronaut(Modifier.color(MainColors.iconOnPrimary))
//}
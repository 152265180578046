package boostie.pages.post.create

import androidx.compose.runtime.Composable
import boostie.components.style.SecondaryInputStyle
import boostie.components.style.SemiBoldNormalTextStyle
import boostie.components.style.SubTitleStyle
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.cssRem

object CreatePostDefaults {

    val titleModifier
        @Composable get() = SemiBoldNormalTextStyle.toModifier().fillMaxWidth().fontSize(1.1.cssRem)
    val descriptionModifier
        @Composable get() = SubTitleStyle.toModifier().fillMaxWidth()


    val inputStyleModifier
        @Composable get() = SecondaryInputStyle.toModifier().fillMaxWidth()
}
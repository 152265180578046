package shared.model.error.domain

import shared.common.ErrorResult

/**
 * Error type used anywhere in the project. Contains subclasses for common exceptions that can happen anywhere
 * @param throwable optional [Throwable] parameter used for debugging or crash reporting
 */
sealed class PostError(throwable: Throwable? = null, message: String? = null) : ErrorResult(
    throwable = throwable,
    message = message,
) {
    class PostNotFound(id: String) : PostError(message = "No post has been found id: $id")
    object PostsNotFound : PostError(message = "No posts has been found")
    class CannotBeDeleted(id: String) : PostError(message = "Cannot be deleted id: $id")
    object CreateFailed : PostError(message = "Create failed")
    object PostMissingId : PostError(message = "Missing postId")
    object CannotDeletePostOfAnotherCreator : PostError(
        message = "Cannot delete post of another creator",
    )
    object CannotUpdatePostOfAnotherCreator : PostError(
        message = "Cannot update post of another creator",
    )
}

package shared.business.feature.post.data.repository

import shared.business.feature.post.data.source.PostRemoteSource
import shared.business.feature.post.domain.repository.PostRepository
import shared.common.Result
import shared.model.feature.post.data.payload.CreatePostPayload
import shared.model.feature.post.data.payload.UpdatePostPayload
import shared.model.feature.post.domain.Post

internal class PostRepositoryImpl(
    private val source: PostRemoteSource,
) : PostRepository {
    override suspend fun createPost(payload: CreatePostPayload): Result<Post> =
        source.createPost(payload)

    override suspend fun deletePost(postId: String): Result<Unit> =
        source.deletePost(postId)

    override suspend fun getPostById(postId: String): Result<Post> =
        source.getPostById(postId)

    override suspend fun getCurrentCreatorPosts(
        deleted: Boolean?,
        draft: Boolean?,
        tagId: String?,
    ): Result<List<Post>> =
        source.getCurrentCreatorPosts(deleted = deleted, draft = draft, tagId = tagId)

    override suspend fun updatePost(postId: String, payload: UpdatePostPayload): Result<Post> =
        source.updatePost(postId = postId, payload = payload)
}

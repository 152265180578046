package Boostie.components.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import org.jetbrains.compose.web.css.px

//box-shadow: inset 0 0 99999px rgba(0, 120, 255, 0.5);

fun Modifier.overlay(color: Color.Rgb = Color.rgba(0, 0, 0, 0.2f)) = boxShadow(inset = true, blurRadius = 99999.px, color = color)


package shared.business.feature.post.domain.usecase

import shared.business.feature.post.domain.repository.PostRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult

interface DeletePostUseCase : UseCaseResult<String, Unit>

internal class DeletePostUseCaseImpl(
    private val repo: PostRepository,
) : DeletePostUseCase {
    override suspend fun invoke(params: String): Result<Unit> =
        repo.deletePost(params)
}

package shared.model.feature.creator.domain

import kotlinx.datetime.LocalDateTime
import shared.model.common.domain.Language
import shared.model.feature.creator.data.payload.UpdateCreatorPayload

data class UpdateCreatorModel(
    val id: String,
    val name: String? = null,
    val shortBio: String? = null,
    val fullBio: String? = null,
    val email: String? = null,
    val language: Language? = null,
    val photoUrl: String? = null,
    val backgroundPhotoUrl: String? = null,
    val lastActivity: LocalDateTime? = null,
) {
    val asUpdatePayload
        get() = UpdateCreatorPayload(
            name = name,
            shortBio = shortBio,
            fullBio = fullBio,
            email = email,
            language = language,
            photoUrl = photoUrl,
            backgroundPhotoUrl = backgroundPhotoUrl,
            lastActivity = lastActivity,
        )
}

fun UpdateCreatorPayload.toUpdateModel(id: String) =
    UpdateCreatorModel(
        id = id,
        name = name,
        shortBio = shortBio,
        fullBio = fullBio,
        email = email,
        language = language,
        photoUrl = photoUrl,
        backgroundPhotoUrl = backgroundPhotoUrl,
        lastActivity = lastActivity,
    )

package boostie.util

import kotlinx.browser.window
import shared.model.common.domain.Language

enum class Localization(val values: Map<Language, String>) {
    site_name(
        mapOf(
            Language.CS to "Boostie",
            Language.EN to "Boostie",
            Language.SK to "Boostie",
        )
    ),
    upload_label(
        mapOf(
            Language.CS to "Nahrát",
            Language.EN to "Upload",
            Language.SK to "Nahráť",
        )
    ),

    choose_label(
        mapOf(
            Language.CS to "Vybrat...",
            Language.EN to "Pick...",
            Language.SK to "Vybrat...",
        )
    ),

    auth_title(
        mapOf(
            Language.CS to "Přihlášení",
            Language.EN to "Sign in",
            Language.SK to "Prihlasenie",
        )
    ),

    posts_label(
        mapOf(
            Language.CS to "Příspěvky",
            Language.EN to "Posts",
            Language.SK to "Prispevky",
        )
    ),
    create_new_post_label(
        mapOf(
            Language.CS to "Nový příspěvek",
            Language.EN to "New post",
            Language.SK to "Nový příspěvek",
        )
    ),
    upload_full_audio_label(
        mapOf(
            Language.CS to "Nahrát plnou verzi podcastu",
            Language.EN to "Nahrát plnou verzi podcastu",
            Language.SK to "Nahrát plnou verzi podcastu",
        )
    ),
    upload_public_audio_label(
        mapOf(
            Language.CS to "Nahrát zkrácenou verzi podcastu",
            Language.EN to "Nahrát zkrácenou verzi podcastu",
            Language.SK to "Nahrát zkrácenou verzi podcastu",
        )
    ),
    upload_full_video_label(
        mapOf(
            Language.CS to "Nahrát plnou verzi videa",
            Language.EN to "Nahrát plnou verzi videa",
            Language.SK to "Nahrát plnou verzi videa",
        )
    ),
    upload_public_video_label(
        mapOf(
            Language.CS to "Nahrát zkrácenou verzi videa",
            Language.EN to "Nahrát zkrácenou verzi videa",
            Language.SK to "Nahrát zkrácenou verzi videa",
        )
    ),

    onboarding_label(
        mapOf(
            Language.CS to "Stát se tvůrcem",
            Language.EN to "Become creator",
            Language.SK to "Stát se tvůrcem",
        )
    ),

    appearance_label(
        mapOf(
            Language.CS to "Vzhled",
            Language.EN to "Appearance",
            Language.SK to "Vzhled",
        )
    ),

    copyright_title(
        mapOf(
            Language.CS to "Copyright © 2023 Boostie. Všechna práva vyhrazena.",
            Language.EN to "Copyright © 2023 Boostie. All rights reserved.",
            Language.SK to "Copyright © 2023 Boostie. All rights reserved.",
        )
    ),

    email_label(
        mapOf(
            Language.CS to "Email",
            Language.EN to "Email",
            Language.SK to "Email",
        )
    ),

    email_placeholder_label(
        mapOf(
            Language.CS to "Váš email",
            Language.EN to "Your email",
            Language.SK to "Your email",
        )
    ),
    email_signin_label(
        mapOf(
            Language.CS to "Přihlásit se emailem",
            Language.EN to "Přihlásit se emailem",
            Language.SK to "Přihlásit se emailem",
        )
    ),

    google_signin_label(
        mapOf(
            Language.CS to "Přihlásit se Google účtem",
            Language.EN to "Přihlásit se Google účtem",
            Language.SK to "Přihlásit se Google účtem",
        )
    ),
    signin_label(
        mapOf(
            Language.CS to "Přihlásit se",
            Language.EN to "Přihlásit se",
            Language.SK to "Přihlásit se",
        )
    ),

    add_new_post_label(
        mapOf(
            Language.CS to "Nový příspěvek",
            Language.EN to "Add post",
            Language.SK to "Nový příspěvek",
        )
    ),

    add_label(
        mapOf(
            Language.CS to "Přidat",
            Language.EN to "Add",
            Language.SK to "Přidat",
        )
    ),
    full_version_label(
        mapOf(
            Language.CS to "Celá verze",
            Language.EN to "Full version",
            Language.SK to "Celá verze",
        )
    ),
    public_version_label(
        mapOf(
            Language.CS to "Veřejná verze",
            Language.EN to "Public version",
            Language.SK to "Veřejná verze",
        )
    ),
    attachments_label(
        mapOf(
            Language.CS to "Přílohy",
            Language.EN to "Attachments",
            Language.SK to "Přílohy",
        )
    ),

    name_placeholder_label(
        mapOf(
            Language.CS to "Název...",
            Language.EN to "Name",
            Language.SK to "Your email",
        )
    ),
    short_bio_placeholder_label(
        mapOf(
            Language.CS to "Pod titulek...",
            Language.EN to "Subtitle...",
            Language.SK to "Pod titulek...",
        )
    ),
    full_bio_placeholder_label(
        mapOf(
            Language.CS to "Dlouhý popis...",
            Language.EN to "Full bio...",
            Language.SK to "Dlouhý popis...",
        )
    ),

    password_label(
        mapOf(
            Language.CS to "Heslo",
            Language.EN to "Password",
            Language.SK to "Heslo",
        )
    ),
    password_placeholder_label(
        mapOf(
            Language.CS to "Zadejte heslo",
            Language.EN to "Enter password",
            Language.SK to "Enter password",
        )
    ),

    next_label(
        mapOf(
            Language.CS to "Další",
            Language.EN to "Next",
            Language.SK to "Další",
        )
    ),

    yes_label(
        mapOf(
            Language.CS to "Ano",
            Language.EN to "Yes",
            Language.SK to "Ano",
        )
    ),
    cancel_label(
        mapOf(
            Language.CS to "Zrušit",
            Language.EN to "Cancel",
            Language.SK to "Zrušit",
        )
    ),
    remove_label(
        mapOf(
            Language.CS to "Odebrat",
            Language.EN to "Remove",
            Language.SK to "Odebrat",
        )
    ),

    publish_label(
        mapOf(
            Language.CS to "Publikovat",
            Language.EN to "Publish",
            Language.SK to "Publikovat",
        )
    ),
    save_draft_label(
        mapOf(
            Language.CS to "Uložit koncept",
            Language.EN to "Uložit koncept",
            Language.SK to "Uložit koncept",
        )
    ),
    summary_label(
        mapOf(
            Language.CS to "Souhrn",
            Language.EN to "Summary",
            Language.SK to "Souhrn",
        )
    ),
    create_label(
        mapOf(
            Language.CS to "Vyvořit",
            Language.EN to "Create",
            Language.SK to "Vytvoriť",
        )
    ),
    continue_label(
        mapOf(
            Language.CS to "Pokračovat",
            Language.EN to "Continue",
            Language.SK to "Pokračovat",
        )
    ),
    ;

    val string: String
        get() = this.toString()

    override fun toString(): String {
        val lng = window.navigator.language.run {
            if (this.contains("en")) {
                Language.EN
            }
            if (this.contains("cs")) {
                Language.CS
            }
            if (this.contains("sk")) {
                Language.SK
            }
            Language.CS

        }
        return this.translate(lng)
    }

}

private fun Localization.translate(lng: Language): String {
    return this.values[lng] ?: "TODO"
}


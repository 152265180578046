package Boostie.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px

@Composable
fun SvgImage(width: CSSNumeric = 24.px, height: CSSNumeric = 24.px, icon: String, color: String, maskSize: Int = 24) {
    Box(
        modifier = Modifier.width(width).height(height).styleModifier {
            property("mask", "url(${icon}) no-repeat center")
            property("-webkit-mask", "url(${icon}) no-repeat center")
            property("mask-size", "${maskSize}px")
            property("background-color", color)
        },
    ) {

    }
}
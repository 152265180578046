package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.animation.fadeOutTransition
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleHeaderBackgroundVariant
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.RadiusXLarge
import boostie.components.style.SizeSimpleButton
import boostie.components.style.SpaceLarge
import boostie.components.style.SpaceMedium
import boostie.components.style.SubTitleStyle
import boostie.components.style.TitleStyle
import boostie.components.style.TitleStyleOnBackgroundVariant
import boostie.components.style.defaultShadow
import boostie.util.Localization
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSPercentageValue
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun ToolbarDialogOverlayView(
    title: String? = null,
    sizePercent: CSSPercentageValue = 90.percent,
    onDismiss: () -> Unit,
    content: @Composable ColumnScope.() -> Unit,
) {
    Overlay(modifier = Modifier.fadeInTransition(), contentAlignment = Alignment.Center) {
        Column(BoxStyle.toModifier(BoxStyleSurfaceVariant).padding(0.px).fillMaxSize(sizePercent).overflow(Overflow.Auto)) {
            Row(
                modifier = BoxStyle.toModifier(BoxStyleHeaderBackgroundVariant)
                    .borderRadius(topLeft = RadiusXLarge, topRight = RadiusXLarge, bottomLeft = 0.px, bottomRight = 0.px).fillMaxWidth()
                    .padding(SpaceMedium).defaultShadow, horizontalArrangement = Arrangement.End, verticalAlignment = Alignment.CenterVertically
            ) {
                if (title != null) {
                    SpanText(title, modifier = TitleStyle.toModifier(TitleStyleOnBackgroundVariant).fillMaxWidth().align(Alignment.CenterStart))
                    Space()
                }

                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant).size(SizeSimpleButton), onClick = {
                    onDismiss()
                }) {
                    FaXmark(Modifier.color(MainColors.iconOnPrimary))
                }
            }
            content()
        }
    }
}


@Composable
fun SimpleDialogOverlayView(sizePercent: CSSPercentageValue = 90.percent, onDismiss: () -> Unit, content: @Composable BoxScope.() -> Unit) {
    Overlay(modifier = Modifier.fadeInTransition().onClick { onDismiss() }, contentAlignment = Alignment.Center) {
        Box(
            modifier = Modifier.borderRadius(RadiusXLarge).fillMaxSize(sizePercent).overflow(Overflow.Auto)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(SpaceMedium).zIndex(10),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant).padding(0.px).size(SizeSimpleButton), onClick = {
                    onDismiss()
                }) {
                    FaXmark(Modifier.color(MainColors.iconOnPrimary))
                }
            }
            Box(
                Modifier.borderRadius(RadiusXLarge).fillMaxSize().overflowY(Overflow.Scroll).fadeOutTransition(), contentAlignment = Alignment.Center
            ) { content() }
        }
    }
}

@Composable
fun QuestionDialogView(
    title: String? = null,
    description: String,
    positiveText: String = Localization.yes_label.string,
    onPositive: () -> Unit,
    negativeText: String = Localization.cancel_label.string,
    onDismiss: () -> Unit,
) {
    Overlay(modifier = Modifier.fadeInTransition(), contentAlignment = Alignment.Center) {
        Box(
            modifier = BoxStyle.toModifier(BoxStyleSurfaceVariant).overflow(Overflow.Auto)
        ) {
            Column(Modifier.padding(SpaceMedium), horizontalAlignment = Alignment.CenterHorizontally) {
                title?.let {
                    SpanText(title, TitleStyle.toModifier())
                    Space()
                }

                SpanText(description, NormalTextStyle.toModifier().fontSize(1.2.cssRem).fontWeight(FontWeight.SemiBold))
                Space(SpaceMedium)

                Row(Modifier.align(Alignment.Center).padding(SpaceMedium)) {
                    TransparentSecondaryButton(negativeText, onColor = MainColors.primary) {
                        onDismiss()
                    }
                    Space(SpaceLarge)
                    PrimaryButton(text = positiveText) {
                        onPositive()
                    }
                }

            }
        }
    }
}


@Composable
fun SimpleColumnDialogView(
    title: String? = null,
    description: String,
    content: @Composable ColumnScope.() -> Unit,
) {
    Overlay(modifier = Modifier.fadeInTransition(), contentAlignment = Alignment.Center) {
        Box(
            modifier = BoxStyle.toModifier(BoxStyleSurfaceVariant).overflow(Overflow.Auto)
        ) {
            Column(Modifier.padding(SpaceMedium), horizontalAlignment = Alignment.CenterHorizontally) {
                title?.let {
                    SpanText(title, TitleStyle.toModifier())
                    Space()
                }

                SpanText(description, SubTitleStyle.toModifier().fontSize(1.cssRem).fontWeight(FontWeight.Normal))
                Space(SpaceMedium)
                content()
            }
        }
    }
}

package shared.common.provider

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel

interface ApplicationCoroutineScope {
    val scope: CoroutineScope
}

class ApplicationCoroutineScopeProvider : ApplicationCoroutineScope {
    override val scope = CoroutineScope(SupervisorJob() + Dispatchers.Default)

    fun onApplicationFinished() {
        scope.cancel(
            "Application finished",
            CancellationException("Application finished"),
        )
    }
}

package shared.business.feature.filecontent.domain.usecase

import shared.business.feature.filecontent.domain.repository.FileRepository
import shared.common.Result
import shared.common.usecase.UseCaseResultNoParams

interface ReserveFileIdUseCase : UseCaseResultNoParams<String>

internal class ReserveFileIdUseCaseImpl(
    private val repo: FileRepository,
) : ReserveFileIdUseCase {
    override suspend fun invoke(): Result<String> = repo.reserveFileId()
}

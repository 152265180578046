package boostie.pages.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Resources
import boostie.components.Space
import boostie.components.layout.CenteredPageLayout
import boostie.components.style.MainColors
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SecondaryInputStyle
import boostie.components.style.SizeXXLarge
import boostie.components.style.SpaceLarge
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SpaceXSmall
import boostie.components.style.SubTitleStyle
import boostie.components.widgets.LineTextButton
import boostie.components.widgets.ProgressButton
import boostie.components.widgets.SimpleBoxError
import boostie.components.widgets.SimpleDivider
import boostie.toHome
import boostie.toRegistration
import boostie.util.Localization
import boostie.util.Localization.auth_title
import boostie.util.rememberDependency
import boostie.vm.shared.AuthEvents
import boostie.vm.shared.AuthVMIntent
import boostie.vm.shared.AuthViewModel
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaEnvelope
import com.varabyte.kobweb.silk.components.icons.fa.FaGoogle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

private val inputsStyle
    @Composable get() = SecondaryInputStyle.toModifier()

@Page("/auth")
@Composable
fun AuthPage() {
    val scope = rememberCoroutineScope()
    val router = rememberPageContext().router
    val vm = rememberDependency<AuthViewModel>()


    LaunchedEffect(vm) {
        vm.onViewDidAppear()
        vm.events.collect {
            when (it) {
                AuthEvents.GoToHome -> router.toHome()
            }
        }
    }

    LaunchedEffect(vm.state.isSignedIn) {
        if (vm.state.isSignedIn) {
            router.toHome()
        }
    }

    CenteredPageLayout(title = auth_title.string,
        fillMaxSize = true,
        colorModifier = Modifier.styleModifier { background(MainColors.tertiaryGradient) }) {

        Box(Modifier.fillMaxSize().padding(SpaceSmall), contentAlignment = Alignment.Center) {
            Column(Modifier.minHeight(700.px), horizontalAlignment = Alignment.CenterHorizontally) {
                Image(src = Resources.Icons.app_icon, desc = Resources.Icons.app_icon, modifier = Modifier.clip(Circle()).size(SizeXXLarge))
                Space(SpaceLarge)

                SignInEmailView(isLoading = vm.state.isEmailLoading, onAction = { email, password ->
                    scope.launch { vm.onIntent(AuthVMIntent.OnEmailLogin(email = email, password = password)) }
                }, onSwitchView = {
                    router.toRegistration()
                })

                Space()
                SimpleDivider(color = MainColors.primaryLineSeparator)
                Space()
                SpanText(Localization.google_signin_label.string, SubTitleStyle.toModifier())
                Space(SpaceSmall)
                ProgressButton(Localization.signin_label.string, vm.state.isGoogleLoading, PrimaryButtonStyleVariant, icon = { FaGoogle() }) {
                    scope.launch { vm.onIntent(AuthVMIntent.GoogleLogin) }
                }

                vm.state.error?.let {
                    Space()
                    SimpleBoxError(error = it)
                }
            }
        }
    }
}


@Composable
private fun SignInEmailView(isLoading: Boolean, onSwitchView: () -> Unit, onAction: (String, String) -> Unit) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        var emailInput by remember { mutableStateOf("") }
        var passwordInput by remember { mutableStateOf("") }
        Input(InputType.Email, attrs = inputsStyle.fillMaxWidth().toAttrs {
            placeholder(Localization.email_placeholder_label.string)
            onInput {
                emailInput = it.value
            }
        })
        Space(SpaceSmall)
        Input(InputType.Password, attrs = inputsStyle.fillMaxWidth().toAttrs {
            placeholder(Localization.password_placeholder_label.string)
            onInput {
                passwordInput = it.value
            }
        })

        Space(SpaceMedium)
        ProgressButton(Localization.email_signin_label.string, isLoading, PrimaryButtonStyleVariant, icon = { FaEnvelope() }) {
            onAction(emailInput, passwordInput)
        }
        Space(SpaceXSmall)

        LineTextButton("Nemate ucet?", "Vytvorte si ho.", fontSize = 0.8.cssRem) {
            onSwitchView()
        }

    }
}

package boostie.components.sections

import androidx.compose.runtime.Composable
import boostie.components.Resources
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleHeaderBackgroundVariant
import boostie.components.style.RadiusXLarge
import boostie.components.style.defaultShadow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun Header(
    modifier: Modifier = Modifier,
    variant: ComponentVariant = BoxStyleHeaderBackgroundVariant,
    withShadow: Boolean = true,
    withAnimation : Boolean = true,
    icons: @Composable RowScope.() -> Unit,
) {
    Box(
        modifier = BoxStyle.toModifier(variant).then(modifier)
            .borderRadius(topLeft = 0.px, topRight = 0.px, bottomLeft = RadiusXLarge, bottomRight = RadiusXLarge)
            .thenIf(withShadow) {
                Modifier.defaultShadow
            }
            .position(Position.Sticky).top(0.percent).thenIf(withAnimation, Modifier.fadeInTransition())
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Anchor(
                href = "/",
            ) {
                Image(
                    src = Resources.Icons.app_logo_long, modifier = Modifier.width(150.px)
                )
            }
            Space(modifier = Modifier.fillMaxWidth())
            Row(Modifier, verticalAlignment = Alignment.CenterVertically) { icons() }
        }
    }
}
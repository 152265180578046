import boostie.pages.onboarding.OnboardingViewModel
import boostie.pages.tags.CreatorTagsViewModel
import boostie.pages.post.create.CreatePostViewModel
import boostie.pages.post.list.PostsViewModel
import firebasestorage.binding.FirebaseStorageApp
import boostie.vm.shared.AuthViewModel
import boostie.vm.shared.CreatorValidationViewModel
import boostie.vm.shared.UploadFileViewModel
import domain.file.usecase.UploadLargeFileUseCase
import domain.file.usecase.UploadLargeFileUseCaseImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.di.firebaseOptions
import shared.common.provider.AppInfoProvider
import shared.common.provider.BuildType


fun getWebModule(buildType: BuildType) = module {

    // TODO: whole next section we have to refactor somehow
    single<AppInfoProvider> {
        object : AppInfoProvider {
            override val appVersion: String = "1.0.0"
            override val deviceId: String = "web"
            override val system: String = "web"
            override val language: String = "unknown"
            override val buildType: BuildType = buildType
        }
    }

    single { FirebaseStorageApp.initialize(firebaseOptions) }
    single { get<FirebaseStorageApp>().getStorage() }

    factoryOf(::AuthViewModel)
    factoryOf(::CreatorValidationViewModel)
    factoryOf(::OnboardingViewModel)
    factoryOf(::UploadFileViewModel)
    factoryOf(::PostsViewModel)
    factoryOf(::CreatorTagsViewModel)
    factoryOf(::CreatePostViewModel)

    // Usecases
    factoryOf(::UploadLargeFileUseCaseImpl) bind UploadLargeFileUseCase::class
}
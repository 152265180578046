package boostie.pages.onboarding

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import boostie.components.Resources
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.sections.Header
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.HeaderTitleStyle
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.SpaceLarge
import boostie.components.style.SpaceMedium
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.CircleIconButton
import boostie.pages.onboarding.states.OnboardingCreateStateView
import boostie.pages.onboarding.states.UpdateBackgroundPhotoView
import boostie.pages.onboarding.states.UpdateProfilePhotoView
import boostie.toHome
import boostie.util.Authenticated
import boostie.util.Localization
import boostie.util.rememberDependency
import boostie.vm.shared.AuthVMIntent
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRightFromBracket
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.px

@Page("/onboarding")
@Composable
fun OnboardingPage() {
    Authenticated(Modifier.fillMaxSize()) { authViewModel ->
        val scope = rememberCoroutineScope()

        CenteredPageLayout(
            fillMaxSize = true,
            title = Localization.onboarding_label.string, type = CenteredPageLayoutType.Simple, hideFooter = true
        ) {
            OnboardingScreen(
                modifier = Modifier.fillMaxSize(),
                onLogout = {
                    scope.launch { authViewModel.onIntent(AuthVMIntent.SignOut) }
                }
            )
        }

    }
}

@Composable
private fun OnboardingScreen(
    modifier: Modifier = Modifier,
    onLogout: () -> Unit,
) {
    val vm = rememberDependency<OnboardingViewModel>()
    val router = rememberPageContext().router

    LaunchedEffect(vm) {
        vm.onViewDidAppear()
        vm.events.collect {
            when (it) {
                OnboardingVMEvents.FinishOnboarding -> router.toHome()
            }
        }
    }

    Row(modifier.fillMaxSize()) {
        when (vm.state.currentOnboardingStep) {
            is OnboardingStep.CreateCreatorStep -> SideStateTexts(
                "Stan se tvurcem",
                "vypln profil zani",
                image = "7883544_vlog_record_video_icon.svg"
            )

            is OnboardingStep.AddPhotoStep -> SideStateTexts(
                "Dopln svuj profilovy obrazek",
                "Muze to byt logo ci cokoliv chces pouzivat na svem kanalu",
                image = "7883550_video_conference_call_online_icon.svg"
            )

            is OnboardingStep.AddBackgroundPhotoStep -> SideStateTexts(
                "Obrazek na tve profilove zobrazovce",
                "Nastav velky obrazek na pozadi tveho kanalu",
                image = "7883546_selfie_picture_couple_photo_icon.svg"
            )
        }
        Column(Modifier.fillMaxWidth().padding(right = SpaceMedium), horizontalAlignment = Alignment.End) {
            Space()
            Header(Modifier.fillMaxWidth().padding(left = SpaceMedium), variant = BoxStyleSurfaceVariant, withShadow = false) {
                CircleIconButton(icon = { FaArrowRightFromBracket(onPrimaryColor) }) {
                    onLogout()
                }
            }

            Space()
            Column(Modifier.fillMaxWidth().padding(SpaceLarge).fadeInTransition(), horizontalAlignment = Alignment.Start) {
                when (vm.state.currentOnboardingStep) {
                    is OnboardingStep.CreateCreatorStep -> OnboardingCreateStateView(vm)
                    is OnboardingStep.AddPhotoStep -> UpdateProfilePhotoView(vm)
                    is OnboardingStep.AddBackgroundPhotoStep -> UpdateBackgroundPhotoView(vm)
                }
            }
        }
    }
}


@Composable
private fun SideStateTexts(title: String, description: String, image: String) {
    Column(
        Modifier
            .styleModifier {
                background(MainColors.primaryGradient)
            }
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(SpaceLarge)
            .fadeInTransition(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Space(SpaceMedium)
        SpanText(
            text = title,
            modifier = Modifier.then(HeaderTitleStyle.toModifier())
                .color(MainColors.onPrimary),
        )
        Space(SpaceMedium)
        SpanText(
            text = description,
            modifier = Modifier.then(NormalTextStyle.toModifier())
                .color(MainColors.onPrimary),
        )

        Space(SpaceLarge)
        Image(
            "${Resources.illustrationsRoot}/$image",
            modifier = Modifier.fillMaxWidth().objectFit(ObjectFit.Fill).maxHeight(600.px).padding(SpaceLarge)
        )

    }
}


//@Composable
//private fun OnboardingScreen(
//    modifier: Modifier = Modifier,
//) {
//    val scope = rememberCoroutineScope()
//    val pageContext = rememberPageContext()
//    val vm = rememberDependency<OnboardingViewModel>()
//    val uploadFileVm = rememberDependency<UploadFileViewModel>()
//
//
//    Column(modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
//        SpanText(
//            text = "Onboarding",
//            modifier = Modifier.then(HeaderTitleStyle.toModifier()).alignContent(AlignContent.Start),
//        )
//
//        Space(SpaceMedium)
//        val photoModifier = Modifier.clip(Circle()).size(200.px).objectFit(ObjectFit.Cover)
//        if (!vm.state.profilePhotoUrl.isNullOrEmpty()) {
//            vm.state.profilePhotoUrl?.let {
//                Image(it, modifier = photoModifier)
//            }
//        } else {
//            Box(photoModifier.styleModifier { background(MainColors.secondaryGradient) }) {
//                FaImage(Modifier.color(MainColors.onPrimary))
//            }
//        }
//
//        if (!uploadFileVm.state.publicFileUrl.isNullOrEmpty()) {
//            uploadFileVm.state.publicFileUrl?.let { url ->
//                Video(attrs = Modifier.width(400.px).height(400.px).toAttrs {
//                    attr("controls", "")
//                }) {
//                    Source(attrs = {
//                        attr("src", url)
//                        attr("type", "video/mp4")
//                    })
//                }
//            }
//        } else {
//            Box(modifier = BoxStyle.toModifier(BoxStyleBackground).size(400.px)) {
//                Title("Some video")
//            }
//        }
//
//        Space(SpaceMedium)
//
//        UploadButton(text = "Upload file", icon = { FaUpload() }, isUploading = uploadFileVm.state.isUploading) { file ->
//            uploadFileVm.onIntent(UploadFileVMIntent.OnUpload(file))
//        }
//
//        Space(SpaceMedium)
//        if (uploadFileVm.state.isUploading) {
//            Box(Modifier.width(200.px).height(30.px), contentAlignment = Alignment.Center) {
//                Progress(attrs = {
//                    attr("value", "${uploadFileVm.state.uploadProgress}")
//                    attr("max", "100")
//                })
//                Text("progress: ${uploadFileVm.state.uploadProgress} %")
//            }
//        }
//    }
//}

package shared.business.feature.post.data.repository

import shared.business.feature.post.data.source.PostCreatorTagRemoteSource
import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.model.feature.post.data.payload.CreatePostCreatorTagPayload
import shared.model.feature.post.data.payload.UpdatePostCreatorTagPayload

internal class PostCreatorTagRepositoryImpl(
    private val source: PostCreatorTagRemoteSource,
) : PostCreatorTagRepository {

    override suspend fun create(
        payload: CreatePostCreatorTagPayload,
    ) = source.create(payload = payload)

    override suspend fun delete(tagId: String) = source.delete(tagId = tagId)

    override suspend fun getCurrentCreatorTags() = source.getCurrentCreatorTags()

    override suspend fun update(
        tagId: String,
        payload: UpdatePostCreatorTagPayload,
    ) = source.update(tagId = tagId, payload = payload)
}

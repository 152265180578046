package shared.model.error.domain

import shared.common.ErrorResult

sealed class FileContentError(
    message: String? = null,
    throwable: Throwable? = null,
) : ErrorResult(message, throwable = throwable) {
    class UploadFileFailed(message: String? = null, throwable: Throwable? = null) :
        FileContentError(
            message = message,
            throwable = throwable,
        )

    object SaveFileFailed : FileContentError()
    class FileNotFound(id: String) : FileContentError("File has not been found id: $id")
}

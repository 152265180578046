package shared.business.feature.post.infrastructure

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import shared.business.feature.post.data.source.PostCreatorTagRemoteSource
import shared.common.Result
import shared.common.network.catchingBackendExceptions
import shared.model.error.domain.PostCreatorTagError
import shared.model.feature.post.data.api.PostCreatorTagApiDto
import shared.model.feature.post.data.api.toDomain
import shared.model.feature.post.data.payload.CreatePostCreatorTagPayload
import shared.model.feature.post.data.payload.UpdatePostCreatorTagPayload
import shared.model.feature.post.domain.PostCreatorTag

class ApiPostCreatorTagRemoteSourceImpl(
    private val httpClient: HttpClient,
) : PostCreatorTagRemoteSource {

    override suspend fun create(payload: CreatePostCreatorTagPayload): Result<PostCreatorTag> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.post("creator/post/tags/create") {
                    setBody(payload)
                }.body<PostCreatorTagApiDto>()
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(PostCreatorTagError.CreateFailed)
        }
    }

    override suspend fun delete(tagId: String): Result<Unit> {
        return try {
            catchingBackendExceptions {
                httpClient.delete("creator/post/tags/delete/$tagId")
                Result.Success(Unit)
            }
        } catch (e: Exception) {
            Result.Error(PostCreatorTagError.CannotBeDeleted(tagId))
        }
    }

    override suspend fun getCurrentCreatorTags(): Result<List<PostCreatorTag>> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.get("creator/post/tags/my")
                    .body<List<PostCreatorTagApiDto>>()
                Result.Success(body.map { it.toDomain() })
            }
        } catch (e: Exception) {
            Result.Error(PostCreatorTagError.TagsNotFound)
        }
    }

    override suspend fun update(
        tagId: String,
        payload: UpdatePostCreatorTagPayload,
    ): Result<PostCreatorTag> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.post("creator/post/tags/update/$tagId") {
                    setBody(payload)
                }.body<PostCreatorTagApiDto>()
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(PostCreatorTagError.CannotDeleteTagOfAnotherCreator)
        }
    }
}

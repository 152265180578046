package boostie.base

import androidx.compose.runtime.Immutable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue

abstract class BaseIntentViewModel<S : VmState, I : VmIntent>(
    initialState: S,
) {
    var state: S by mutableStateOf(initialState)
        protected set

    suspend fun onIntent(intent: I) {
        applyIntent(intent)
    }

    protected abstract suspend fun applyIntent(intent: I)

    fun S.batch(block: S.() -> S): S {
        block()
        return this
    }

    abstract suspend fun onViewDidAppear()
}

/**
 * Base state of every view that is held in view model.
 */
@Immutable
interface VmState

/**
 * Base intent interface
 */
interface VmIntent
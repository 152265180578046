package boostie.components.style

import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

/**
 * Radius of 4 px
 */
val RadiusSmall = 4.px

/**
 * Radius of 8 px
 */
val RadiusMedium = 8.px

/**
 * Radius of 16 px
 */
val RadiusLarge = 16.px

/**
 * Radius of 18 px
 */
val RadiusXLarge = 18.px


/**
 * Radius of 24 px
 */
val RadiusXXLarge = 24.px

/**
 * Radius of 24 px
 */
val RadiusXXXLarge = 32.px


val SpaceZero = 0.px
/**
 * Spacing of 2 px
 */
val SpaceXXSmall = 2.px

/**
 * Spacing of 4 px
 */
val SpaceXSmall = 4.px

/**
 * Spacing of 8 px
 */
val SpaceSmall = 8.px

/**
 * Spacing of 12 px
 */
val SpaceMediumSmall = 12.px

/**
 * Spacing of 16 px
 */
val SpaceMedium = 16.px

/**
 * Spacing of 20 px
 */
val SpaceMediumLarge = 20.px

/**
 * Spacing of 24 px
 */
val SpaceLarge = 24.px

/**
 * Spacing of 32 px
 */
val SpaceXLarge = 32.px

/**
 * Spacing of 44 px
 */
val SpaceXXLarge = 44.px

/**
 * Spacing of 64 px
 */
val SpaceXXXLarge = 64.px

// === BORDER ===
val BorderSmall = 1.px
val BorderMedium = 2.px
val BorderLarge = 3.px

// === Asset size ===
// 1.rem = 16
val SizeSimpleButton = 3.cssRem
val SizeSmall = 2.cssRem // 32px
val SizeMedium = 4.cssRem // 64px
val SizeMediumLarge = 6.cssRem // 96px
val SizeLarge = 8.cssRem // 128px
val SizeXXLarge = 12.cssRem // 192px
val SizeXXXLarge = 16.cssRem // 256px


package boostie.components.sections

import androidx.compose.runtime.Composable
import boostie.components.Resources
import boostie.components.style.SpaceLarge
import boostie.components.style.SubTitleStyle
import co.touchlab.kermit.Logger
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.prependIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
private fun Logo() {
    Anchor(
        href = "/",
    ) {
        Box(Modifier.margin(4.px)) {
            Image(Resources.Icons.app_logo_long, modifier = Modifier.height(SpaceLarge))
        }
    }
}

@Composable
fun Footer(modifier: Modifier = Modifier) {
    Box(
        Modifier.fillMaxWidth().minHeight(200.px).then(modifier), contentAlignment = Alignment.Center
    ) {
        Column(
            Modifier.fillMaxWidth(70.percent).margin(1.em), horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Logo()
            SpanText(
                "Copyright © 2023 Boostie. All rights reserved.", SubTitleStyle.toModifier().textAlign(TextAlign.Center)
            )
        }
    }
}
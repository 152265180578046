//ktlint-disable
package shared.model.feature.post.data.payload

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.serializers.LocalDateTimeIso8601Serializer
import kotlinx.serialization.Serializable
import shared.model.feature.post.domain.Post

@Serializable
data class CreatePostPayload(
    val title: String,
    val description: String,
    val draft: Boolean,
    @Serializable(LocalDateTimeIso8601Serializer::class) val publishedAt: LocalDateTime? = null,
    val tagIds: List<String>?,
    val fileItems: PostFileItemsPayload? = null,
)

@Serializable
data class PostFileItemsPayload(
    val audio: AudioPayload? = null,
    val video: VideoPayload? = null,
    val images: ImagesPayload? = null,
) {
    @Serializable
    data class AudioPayload(
        val full: String? = null,
        val public: String? = null,
    )

    @Serializable
    data class VideoPayload(
        val full: String?,
        val public: String?,
    )

    @Serializable
    data class ImagesPayload(
        val files: List<String>? = null,
    )
}

@Serializable
data class UpdatePostPayload(
    val title: String? = null,
    val description: String? = null,
    val tagIds: List<String>? = null,
)

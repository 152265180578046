package shared.business.feature.filecontent.data.repository

import shared.business.feature.filecontent.data.source.FileDataSource
import shared.business.feature.filecontent.domain.repository.FileRepository
import shared.common.Result
import shared.common.extensions.map
import shared.model.feature.content.data.api.toApiDto
import shared.model.feature.content.data.api.toDomain
import shared.model.feature.content.domain.FileContent

internal class FileRepositoryImpl(
    private val source: FileDataSource,
) : FileRepository {
    override suspend fun uploadPhoto(fileName: String, fileData: ByteArray): Result<String> =
        source.uploadPhoto(fileName, fileData.size.toLong(), fileData)

    override suspend fun uploadPhotoStream(fileName: String, fileData: ByteArray): Result<String> =
        source.uploadPhotoStream(fileName, fileData.size.toLong(), fileData)

    /**
     * Returns public url
     */
    override suspend fun saveFile(fileContent: FileContent): Result<FileContent> {
        return source.saveFile(fileContent.toApiDto()).map { it.toDomain() }
    }

    override suspend fun reserveFileId(): Result<String> = source.reserveFileId()
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.HeaderTitleStyle
import boostie.components.style.NormalTextStyle
import boostie.components.style.SpaceSmall
import boostie.components.style.SubTitleStyle
import boostie.components.style.TitleStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText

@Composable
fun BigTitle(value: String, modifier: Modifier = Modifier) {
    SpanText(value, modifier = HeaderTitleStyle.toModifier().then(modifier))
}

@Composable
fun Title(value: String, modifier: Modifier = Modifier) {
    SpanText(value, modifier = TitleStyle.toModifier().then(modifier))
}

@Composable
fun Text(value: String, icon: (@Composable () -> Unit)? = null, modifier: Modifier = Modifier) {
    icon?.let {
        Row(verticalAlignment = Alignment.CenterVertically) {
            icon()
            Space(SpaceSmall)
            SpanText(value, modifier = NormalTextStyle.toModifier().then(modifier))
        }
    } ?: SpanText(value, modifier = NormalTextStyle.toModifier().then(modifier))
}

@Composable
fun Subtitle(value: String, modifier: Modifier = Modifier) {
    SpanText(value, modifier = SubTitleStyle.toModifier().then(modifier))
}

@Composable
fun LabelText(label: String, icon: (@Composable () -> Unit)? = null) {
    Row(Modifier.fillMaxWidth()) {
        icon?.let {
            icon()
            Space(SpaceSmall)
        }
        SpanText(
            label,
            NormalTextStyle.toModifier().fontWeight(FontWeight.SemiBold).textAlign(TextAlign.Start)
        )
    }
}
package boostie.components

import androidx.compose.runtime.Composable
import boostie.components.style.SpaceMedium
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px

@Composable
fun RowScope.Space(width: CSSNumeric = SpaceMedium, modifier: Modifier = Modifier) {
    Box(modifier = Modifier.height(10.px).width(size = width).then(modifier))
}

@Composable
fun ColumnScope.Space(height: CSSNumeric = SpaceMedium, modifier: Modifier = Modifier) {
    Box(modifier = Modifier.fillMaxWidth().height(size = height).then(modifier))
}
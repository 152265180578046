package shared.business.feature.filecontent

import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.di.boostieBackendKtorClient
import shared.business.feature.filecontent.data.repository.FileRepositoryImpl
import shared.business.feature.filecontent.data.source.FileDataSource
import shared.business.feature.filecontent.domain.repository.FileRepository
import shared.business.feature.filecontent.domain.usecase.CreateFileUseCase
import shared.business.feature.filecontent.domain.usecase.CreateFileUseCaseImpl
import shared.business.feature.filecontent.domain.usecase.ReserveFileIdUseCase
import shared.business.feature.filecontent.domain.usecase.ReserveFileIdUseCaseImpl
import shared.business.feature.filecontent.domain.usecase.UploadPhotoUseCase
import shared.business.feature.filecontent.domain.usecase.UploadPhotoUseCaseImpl
import shared.business.feature.filecontent.infrastructure.ApiFileDataSourceImpl

internal val fileContentModule = module {
    single<FileDataSource> { ApiFileDataSourceImpl(get(boostieBackendKtorClient)) }

    singleOf(::FileRepositoryImpl) bind FileRepository::class

    factoryOf(::UploadPhotoUseCaseImpl) bind UploadPhotoUseCase::class
    factoryOf(::ReserveFileIdUseCaseImpl) bind ReserveFileIdUseCase::class
    factoryOf(::CreateFileUseCaseImpl) bind CreateFileUseCase::class
}

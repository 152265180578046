package shared.business.feature.creator.domain.usecase

import shared.business.feature.creator.domain.repository.CreatorRepository
import shared.business.feature.user.domain.usecase.GetUserByIdUseCase
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.common.domain.Language
import shared.model.error.domain.CreatorError
import shared.model.feature.creator.domain.Creator

interface CreateCreatorUseCase : UseCaseResult<CreateCreatorUseCase.Params, Creator> {
    data class Params(
        val id: String,
        val name: String,
        val email: String,
        val shortBio: String,
        val fullBio: String,
        val language: Language,
    )
}

internal class CreateCreatorUseCaseImpl(
    private val repository: CreatorRepository,
    private val getUserByIdUseCase: GetUserByIdUseCase,
) : CreateCreatorUseCase {
    override suspend fun invoke(params: CreateCreatorUseCase.Params): Result<Creator> {
        val user = getUserByIdUseCase(
            GetUserByIdUseCase.Params(
                params.id,
            ),
        ).getOrNull()
            ?: return Result.Error(CreatorError.CreateFailedNoUser)
        if (user.isCreator) {
            return Result.Error(CreatorError.CreateFailedAlreadyExist)
        }

        return when (
            val result = repository.createCreator(
                id = params.id,
                name = params.name,
                shortBio = params.shortBio,
                fullBio = params.fullBio,
                email = params.email,
                language = params.language,
            )
        ) {
            is Result.Error -> Result.Error(result.error)
            is Result.Success -> {
                getUserByIdUseCase(
                    GetUserByIdUseCase.Params(
                        params.id,
                        forceRefresh = true,
                    ),
                ).getOrNull()
                result
            }
        }
    }
}

package boostie.vm.shared

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import shared.business.feature.auth.domain.usecase.SignOutUseCase
import shared.business.feature.creator.domain.usecase.GetCreatorByIdUseCase
import shared.business.feature.user.domain.usecase.GetCurrentUserUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.model.feature.creator.domain.Creator
import shared.model.feature.creator.domain.isFulfilled
import shared.model.feature.user.domain.User
import shared.model.feature.user.domain.isFulfilled

class CreatorValidationViewModel(
    private val currentUser: GetCurrentUserUseCase,
    private val signOut: SignOutUseCase,
    private val getCreatorById: GetCreatorByIdUseCase,
) : BaseIntentViewModel<CreatorValidationVMState, CreatorValidationVMIntent>(CreatorValidationVMState()) {
    private val _events = MutableSharedFlow<CreatorValidationEvents>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: CreatorValidationVMIntent) {
    }

    override suspend fun onViewDidAppear() {
        state = state.copy(isLoading = true)
        when (val result = currentUser(GetCurrentUserUseCase.Params.Get)) {
            is Result.Error -> {
                signOut()
                _events.emit(CreatorValidationEvents.GoToLogin)
            }

            is Result.Success -> {
                val user = result.data
                val creatorId = user.creatorId
                if (creatorId == null) {
                    _events.emit(CreatorValidationEvents.GoToOnboarding)
                } else {
                    val creator = getCreatorById(creatorId).getOrNull()
                    state = state.copy(user = user, creator = creator)
                    if (!state.isFulfilled) {
                        _events.emit(CreatorValidationEvents.GoToOnboarding)
                    }
                }
            }
        }
        state = state.copy(isLoading = false)
    }


}

data class CreatorValidationVMState(
    val isLoading: Boolean = false,
    val error: ErrorResult? = null,
    val user: User? = null,
    val creator: Creator? = null,
) : VmState {
    val isFulfilled
        get() = user?.isCreator == true && creator?.isFulfilled == true
}


sealed interface CreatorValidationVMIntent : VmIntent {

}

sealed interface CreatorValidationEvents {
    object GoToOnboarding : CreatorValidationEvents
    object GoToLogin : CreatorValidationEvents
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.ButtonUnselectedVariant
import boostie.components.style.CircleIconButtonStyleVariant
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.PrimaryButtonSelectedVariant
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.PrimaryTextButtonStyleVariant
import boostie.components.style.SecondaryButtonStyleVariant
import boostie.components.style.SpaceXSmall
import boostie.components.style.SubTitleStyle
import boostie.components.style.TransparentButtonStyleVariant
import boostie.components.style.TransparentSecondaryButtonStyleVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.ariaDisabled
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
private fun BaseButton(
    modifier: Modifier = Modifier,
    text: String? = null,
    enabled: Boolean = true,
    onColor: CSSColorValue = MainColors.onPrimary,
    iconLeft: @Composable (() -> Unit)? = null,
    iconRight: @Composable (() -> Unit)? = null,
    variant: ComponentVariant,
    onClick: () -> Unit,
) {
    val iconTextSpace = 6.px
    Button(modifier = ButtonStyle.toModifier(variant).ariaDisabled(!enabled).then(modifier), onClick = {
        if (enabled) {
            onClick()
        }
    }) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            iconLeft?.let {
                iconLeft()
            }
            text?.let {
                iconLeft?.let {
                    Space(iconTextSpace)
                }
                SpanText(
                    text,
                    modifier = NormalTextStyle.toModifier().fontWeight(FontWeight.SemiBold).color(onColor)
                )
                iconRight?.let {
                    Space(iconTextSpace)
                }
            }
            iconRight?.let {
                iconRight()
            }

        }
    }
}

@Composable
fun PrimaryButton(
    modifier: Modifier = Modifier,
    text: String? = null,
    enabled: Boolean = true,
    iconLeft: @Composable (() -> Unit)? = null,
    iconRight: @Composable (() -> Unit)? = null,
    onClick: () -> Unit,
) = BaseButton(
    modifier = modifier,
    text = text,
    enabled = enabled,
    iconLeft = iconLeft,
    iconRight = iconRight,
    variant = PrimaryButtonStyleVariant,
    onClick = onClick
)

@Composable
fun TextButton(
    modifier: Modifier = Modifier,
    text: String,
    color: CSSColorValue = MainColors.primary,
    fontSize: CSSSizeValue<CSSUnit.rem> = 1.cssRem,
    enabled: Boolean = true,
    onClick: () -> Unit,
) = Button(modifier = PrimaryTextButtonStyleVariant.toModifier().ariaDisabled(!enabled).then(modifier), onClick = {
    if (enabled) {
        onClick()
    }
}) {
    SpanText(
        text,
        modifier = NormalTextStyle.toModifier().fontWeight(FontWeight.Normal).color(color).fontSize(fontSize).whiteSpace(WhiteSpace.NoWrap)
    )
}

@Composable
fun LineTextButton(text: String, clickableText: String, fontSize: CSSSizeValue<CSSUnit.rem> = 1.cssRem, onClick: () -> Unit) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        SpanText(text, SubTitleStyle.toModifier().fontSize(fontSize).whiteSpace(WhiteSpace.NoWrap))
        Space(SpaceXSmall)
        TextButton(text = clickableText, fontSize = fontSize) {
            onClick()
        }
    }
}


@Composable
fun SecondaryButton(
    text: String? = null,
    enabled: Boolean = true,
    iconLeft: @Composable (() -> Unit)? = null,
    iconRight: @Composable (() -> Unit)? = null,
    onClick: () -> Unit,
) = BaseButton(text = text, enabled = enabled, iconLeft = iconLeft, iconRight = iconRight, variant = SecondaryButtonStyleVariant, onClick = onClick)


@Composable
fun TransparentSecondaryButton(
    text: String? = null,
    enabled: Boolean = true,
    onColor: CSSColorValue = MainColors.onPrimary,
    iconLeft: @Composable (() -> Unit)? = null,
    iconRight: @Composable (() -> Unit)? = null,
    onClick: () -> Unit,
) = BaseButton(
    text = text,
    enabled = enabled,
    iconLeft = iconLeft,
    iconRight = iconRight,
    onColor = onColor,
    variant = TransparentSecondaryButtonStyleVariant,
    onClick = onClick
)


@Composable
fun TransparentButton(
    text: String? = null,
    onColor: CSSColorValue = MainColors.onPrimary,
    enabled: Boolean = true,
    iconLeft: @Composable (() -> Unit)? = null,
    iconRight: @Composable (() -> Unit)? = null,
    onClick: () -> Unit,
) = BaseButton(
    text = text,
    enabled = enabled,
    iconLeft = iconLeft,
    iconRight = iconRight,
    onColor = onColor,
    variant = TransparentButtonStyleVariant,
    onClick = onClick
)


@Composable
fun CircleIconButton(
    enabled: Boolean = true,
    icon: @Composable (() -> Unit),
    onClick: () -> Unit,
) = BaseButton(text = null, enabled = enabled, iconLeft = icon, iconRight = null, variant = CircleIconButtonStyleVariant, onClick = onClick)


@Composable
fun SelectableButton(title: String, isSelected: Boolean = false, onClick: () -> Unit) {
    val variant = if (isSelected) PrimaryButtonSelectedVariant else ButtonUnselectedVariant
    Button(onClick = { onClick() }, modifier = ButtonStyle.toModifier(variant).fillMaxWidth()) {
        Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
            val color = if (isSelected) MainColors.onPrimary else MainColors.onBackground
            SpanText(
                title, modifier = NormalTextStyle.toModifier().color(color).fontWeight(FontWeight.Medium)
            )
        }
    }
}
package boostie

import com.varabyte.kobweb.navigation.Router

object Navigation {
    const val POSTS = "/"
    const val APPEARANCE = "/appearance"
    const val MEMBERS = "/members"
    const val MESSAGES = "/messages"
    const val STATS = "/stats"
    const val PAYMENTS = "/payments"
    const val HOME = "/"
    const val AUTH = "/auth"
    const val ONBOARDING = "/onboarding"
    const val REGISTRATION = "/registration"
    const val POST_CREATE_CONTENT = "/create/content"
    const val POST_CREATE_PROPS = "/create/props"
    const val POST_CREATE_SUMMARY = "/create/summary"
    const val PROFILE = "/profile"
    const val FEED = "/feed"
    const val SETTINGS = "/settings"
    const val SAMPLE = "/sample"
}


fun Router.toRegistration() = navigateTo(Navigation.REGISTRATION)
fun Router.toLogin() = navigateTo(Navigation.AUTH)

fun Router.toOnBoarding() = navigateTo(Navigation.ONBOARDING)

fun Router.toHome() = navigateTo(Navigation.HOME)

fun Router.toCreateContent() = navigateTo(Navigation.POST_CREATE_CONTENT)
fun Router.toCreateProps() = navigateTo(Navigation.POST_CREATE_PROPS)
fun Router.toCreateSummary() = navigateTo(Navigation.POST_CREATE_SUMMARY)
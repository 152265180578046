package shared.business.feature.user.domain.usecase

import shared.business.feature.auth.domain.usecase.GetCurrentUserIdUseCase
import shared.business.feature.user.domain.repository.UserRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.user.domain.User

/**
 * Input forceRefresh true/false
 */
interface GetCurrentUserUseCase : UseCaseResult<GetCurrentUserUseCase.Params, User> {
    sealed interface Params {
        object Get : Params
        object ForceRefresh : Params
    }
}

internal class GetCurrentUserUseCaseImpl(
    private val getCurrentUserId: GetCurrentUserIdUseCase,
    private val repository: UserRepository,
) : GetCurrentUserUseCase {
    override suspend fun invoke(params: GetCurrentUserUseCase.Params): Result<User> {
        return when (val result = getCurrentUserId()) {
            is Result.Error -> Result.Error(result.error)
            is Result.Success -> repository.getUser(
                result.data,
                forceRefresh = params is GetCurrentUserUseCase.Params.ForceRefresh,
            )
        }
    }
}

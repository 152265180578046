package boostie.components.layout

import androidx.compose.runtime.Composable
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.widgets.ProgressIndicator
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier


@Composable
fun SimpleLoadingLayout(variant: ComponentVariant = BoxStyleBackgroundVariant) {
    Box(BoxStyle.toModifier(variant).fillMaxWidth(), contentAlignment = Alignment.Center) {
        ProgressIndicator()
    }
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.MainColors
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.SpaceXSmall
import boostie.components.style.onPrimaryColor
import boostie.components.style.primaryDashedBorder
import boostie.pages.post.create.CreatePostDefaults
import boostie.util.Localization
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.css.resize
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaBold
import com.varabyte.kobweb.silk.components.icons.fa.FaItalic
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Element

/**
 * onInput -> "HTML", "PLAIN_TEXT"
 */
@Composable
fun RichTextEditor(
    modifier: Modifier = Modifier,
    editorStyleModifier: Modifier,
    content: String?,
    onInput: (String?, String?) -> Unit,
) {
    val id = "rich-text-editor"
    val commandBold = "bold"
    val commandItalic = "italic"
    val commandCreateLink = "createLink"

    var isAddLinkDialogVisible by remember { mutableStateOf(false) }

    LaunchedEffect(true) {
        document.execCommand("defaultParagraphSeparator", false, "p")
        document.getElementById(id)?.innerHTML = content ?: "<p>Text...</p>"
    }

    Column(
        modifier.then(
            BoxStyle.toModifier(BoxStyleBackgroundVariant).backgroundColor(MainColors.surface).primaryDashedBorder
        )
    ) {
        fun command(command: String, value: String = "") {
            document.execCommand(command, false, value)
        }

        Row(modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            SecondaryButton(iconLeft = { FaBold(onPrimaryColor) }) {
                command(commandBold)
            }
            Space(SpaceXSmall)
            SecondaryButton(iconLeft = { FaItalic(onPrimaryColor) }) {
                command(commandItalic)
            }
//            Space(SpaceXSmall)
//            SecondaryButton(iconLeft = { FaLink(onPrimaryColor) }) {
//                isAddLinkDialogVisible = true
//            }
        }
        Space(SpaceSmall)
        Div(attrs = editorStyleModifier.toAttrs {
            id(id)
            contentEditable(true)
            addEventListener("input") {
                val htmlValue = document.getElementById(id)?.innerHTML
                val stringValue = document.getElementById(id)?.textContent
                onInput(htmlValue, stringValue)
            }
        }) {
            Div {
                P {
                    Text(content ?: "<br>")
                }
            }
        }

        if (isAddLinkDialogVisible) {
            AddLinkDialog {
                isAddLinkDialogVisible = false
                if (it.isNotBlank()) {
                    document.getElementById(id)?.focus()
                    command(commandCreateLink, it)
                }
            }
        }
    }
}

private fun Element.focus() {
    val dynElement: dynamic = this
    dynElement.focus()
}

@Composable
private fun AddLinkDialog(onDismiss: (String) -> Unit) {
    var linkValue: String by remember { mutableStateOf("") }
    ToolbarDialogOverlayView(sizePercent = 50.percent, onDismiss = { onDismiss("") }) {
        Column(Modifier.fillMaxWidth().padding(SpaceMedium), horizontalAlignment = Alignment.End) {
            Input(type = InputType.Text, attrs = CreatePostDefaults.inputStyleModifier.toAttrs {
                style { resize(Resize.None) }
                placeholder("Link...")
                onInput {
                    linkValue = it.value
                }
            })
            Space()
            SecondaryButton(Localization.add_label.string) {
                onDismiss(linkValue)
            }
        }

    }
}
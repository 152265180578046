package shared.business.feature.auth.domain.usecase

import shared.business.feature.auth.domain.repository.AuthRepository
import shared.common.Result
import shared.common.usecase.UseCaseResultNoParams

interface GetCurrentUserIdUseCase : UseCaseResultNoParams<String>

internal class GetCurrentUserIdUseCaseImpl(
    private val repository: AuthRepository,
) : GetCurrentUserIdUseCase {
    override suspend fun invoke(): Result<String> {
        return repository.getCurrentUid()
    }
}

package shared.model.feature.user.domain

import kotlinx.datetime.LocalDateTime
import shared.model.common.domain.Language

data class User(
    val id: String,
    val creatorId: String?,
    val nickname: String?,
    val email: String?,
    val photoUrl: String?,
    val bio: String?,
    val createdAt: LocalDateTime,
    val preferredLanguages: List<Language>,
) {
    val isCreator: Boolean
        get() = creatorId != null
}

val User.isFulfilled
    get() = nickname != null && email != null && preferredLanguages.isNotEmpty()

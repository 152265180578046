package shared.business.feature.user.domain.usecase

import shared.business.feature.user.domain.repository.UserRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.user.domain.User

interface GetUserByIdUseCase : UseCaseResult<GetUserByIdUseCase.Params, User> {
    data class Params(
        val id: String,
        val forceRefresh: Boolean = false,
    )
}

internal class GetUserByIdUseCaseImpl(
    private val repository: UserRepository,
) : GetUserByIdUseCase {
    override suspend fun invoke(params: GetUserByIdUseCase.Params): Result<User> {
        return repository.getUser(id = params.id, forceRefresh = params.forceRefresh)
    }
}

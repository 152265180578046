package boostie.components.style

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.*
import org.jetbrains.compose.web.css.*


private val baseModifier = Modifier
    .width(100.percent)
    .backgroundColor(MainColors.inputBackground)
    .border(BorderMedium, LineStyle.Solid, MainColors.primary)
    .borderRadius(RadiusLarge)
    .color(MainColors.onBackground)
    .margin(0.px, 0.px, SpaceSmall, 0.px)
    .padding(SpaceMediumLarge, SpaceLarge)
    .fontWeight(FontWeight.Normal)
    .fontSize(1.cssRem)
//    .letterSpacing(0.8.px)
    .transition(CSSTransition("ease-in-out", 0.3.s))
    .styleModifier {
        property("outline", "none !important")
    }

val InputStyle by ComponentStyle {
    base {
        baseModifier
    }

    focus {
        Modifier
            .backgroundColor(MainColors.inputBackground)
            .color(MainColors.onSurface)
    }

    cssRule("::placeholder") {
        Modifier
            .color(MainColors.onSurfaceSecondary)
    }

    (placeholderShown) {
        Modifier
            .backgroundColor(MainColors.surface)
            .fontWeight(FontWeight.Light)
            .fontSize(1.cssRem)
    }
}

val SecondaryInputStyle by ComponentStyle {
    base {
        Modifier
            .width(100.percent)
            .backgroundColor(MainColors.inputBackgroundSecondary)
            .border(0.px)
            .borderRadius(RadiusLarge)
            .color(MainColors.onBackgroundSecondary)
            .margin(0.px, 0.px, SpaceSmall, 0.px)
            .padding(SpaceMediumSmall, SpaceMedium)
            .fontWeight(FontWeight.Normal)
            .fontSize(1.cssRem)
            .transition(CSSTransition("ease-in-out", 0.3.s))
            .styleModifier {
                property("outline", "none !important")
            }
    }

    focus {
        Modifier
            .backgroundColor(MainColors.inputBackgroundSecondary)
            .border(BorderSmall, LineStyle.Solid, MainColors.primary)
            .color(MainColors.onBackground)
            .styleModifier {
                property("outline", "none !important")
            }
    }

    cssRule("::placeholder") {
        Modifier
            .color(MainColors.onSurfaceSecondary)
    }

    (placeholderShown) {
        Modifier
            .backgroundColor(MainColors.inputBackgroundSecondary)
            .fontWeight(FontWeight.Light)
            .fontSize(1.cssRem)
    }
}
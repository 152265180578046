package boostie.pages.sample

import Boostie.components.style.overlay
import Boostie.components.widgets.CreatorProfileMobilePreviewView
import Boostie.components.widgets.SvgImage
import Boostie.util.ColorUtils
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import boostie.components.Resources
import boostie.components.Space
import boostie.components.layout.CenteredPageLayout
import boostie.components.style.*
import boostie.components.widgets.*
import boostie.pages.tags.views.CreatorPostTagView
import boostie.pages.tags.views.CreatorPostTagViewProps
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBatteryHalf
import com.varabyte.kobweb.silk.components.icons.fa.FaSignal
import com.varabyte.kobweb.silk.components.icons.fa.FaWifi
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.unaryMinus
import org.jetbrains.compose.web.dom.Input
import shared.model.feature.post.domain.PostCreatorTag
import kotlin.random.Random

@Page("/mobile")
@Composable
fun MobileSamplePage() {
    CenteredPageLayout("Sample") {
        MobileSampleScreen(
            modifier = Modifier.fillMaxSize(),
        )
    }
}


@Composable
private fun MobileSampleScreen(
    modifier: Modifier = Modifier,
) {
    val initState = MobileTemplateSampleState()
    var state by remember { mutableStateOf(MobileTemplateSampleState()) }

    Row(modifier, horizontalArrangement = Arrangement.Center) {
        Column(Modifier.fillMaxWidth(40.percent), horizontalAlignment = Alignment.End) {
            Row(Modifier, verticalAlignment = Alignment.CenterVertically) {
                SpanText("Gradient", NormalTextStyle.toModifier().fillMaxWidth().fontWeight(FontWeight.SemiBold))
                Space(SpaceSmall)
                Column {
                    Input(InputType.Color, Modifier.width(56.px).height(32.px).toAttrs {
                        defaultValue(initState.backgroundPrimaryGradientTop)
                        onInput {
                            state = state.copy(backgroundPrimaryGradientTop = it.value)
                        }
                    })
                    Input(InputType.Color, Modifier.width(56.px).height(32.px).toAttrs {
                        defaultValue(initState.backgroundPrimaryGradientBottom)
                        onInput {
                            state = state.copy(backgroundPrimaryGradientBottom = it.value)
                        }
                    })
                }

            }

            Row(Modifier, verticalAlignment = Alignment.CenterVertically) {
                SpanText("Primary text color", NormalTextStyle.toModifier().fillMaxWidth().fontWeight(FontWeight.SemiBold))
                Space(SpaceSmall)
                Input(InputType.Color, Modifier.width(56.px).height(32.px).toAttrs {
                    defaultValue(initState.textPrimaryHexColor)
                    onInput {
                        state = state.copy(textPrimaryHexColor = it.value)
                    }
                })
            }

            Row(Modifier, verticalAlignment = Alignment.CenterVertically) {
                SpanText("Primary icon color", NormalTextStyle.toModifier().fillMaxWidth().fontWeight(FontWeight.SemiBold))
                Space(SpaceSmall)
                Input(InputType.Color, Modifier.width(56.px).height(32.px).toAttrs {
                    defaultValue(initState.iconPrimaryHexColor)
                    onInput {
                        state = state.copy(iconPrimaryHexColor = it.value)
                    }
                })
            }
        }

        Space()
        CreatorProfileMobilePreviewView(modifier = Modifier.height((window.screen.height*0.75f).px), state = state)
    }
}


data class MobileTemplateSampleState(
    val name: String = "Creator name",
    val bio: String = "\uD83D\uDCD6 Místo, kde je EASYCAST bez cenzury a plně nekorektní.",
    val description: String = "\uD83D\uDCD6 Místo, kde je EASYCAST bez cenzury a plně nekorektní. \n" + "\uD83D\uDE4F \n" + "- Bonusový obsah a verze bez střihu a reklam \n" + "- Epizody o 3 dny dříve než na YouTube. \n" + "- Necenzurovaný humor a témata \n" + "- Obsah, který jinde nenajdeš! \n" + "\n" + "Děkujeme, že nám pomáháte tvořit \n" + "EASYCAST! \n" + "\n" + "\uD83E\uDD18\uD83D\uDC9B",
    val profilePhotoUrl: String = "https://images.unsplash.com/photo-1567022296806-d2d37b715647?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80",
    val backgroundPhotoUrl: String = "https://images.unsplash.com/photo-1605388289664-4e9d145d6a70?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    val textPrimaryHexColor: String = "#959595",
    val iconPrimaryHexColor: String = "#46FFFF",
    val backgroundPrimaryGradientTop: String = "#F9F9F9",
    val backgroundPrimaryGradientBottom: String = "#F9F9F9",
    val reactionEmojiUnicode: String = "\uD83D\uDE01",
    val creatorTags: List<PostCreatorTag> = listOf(
        PostCreatorTag("", "", "⚡️Some tag", "#46FFFF"),
        PostCreatorTag("", "", "⚡️Some tag", "#46FFFF"),
        PostCreatorTag("", "", "⚡️Some tag", "#46FFFF"),
        PostCreatorTag("", "", "⚡️Some tag", "#46FFFF"),
        PostCreatorTag("", "", "⚡️Some tag", "#46FFFF"),
    ),
) {
    val iconSecondaryRgbColor: Color.Rgb
        get() = ColorUtils.parseColor(iconPrimaryHexColor).copyf(alpha = 0.75f)
    val iconSecondaryHexColor: String
        get() = ColorUtils.toHex(iconSecondaryRgbColor)

    val textSecondaryRgbColor: Color.Rgb
        get() = ColorUtils.parseColor(textPrimaryHexColor).copyf(alpha = 0.75f)
    val textSecondaryHexColor: String
        get() = ColorUtils.toHex(textSecondaryRgbColor)

    val separatorRgbColor: Color.Rgb
        get() = ColorUtils.parseColor(textPrimaryHexColor).copyf(alpha = 0.35f)
    val separatorHexColor: String
        get() = ColorUtils.toHex(separatorRgbColor)

    val backgroundGradientColor: String
        get() = "linear-gradient(180deg, $backgroundPrimaryGradientTop 0.0%, $backgroundPrimaryGradientBottom 100.0%)"

}



package boostie.components

object Resources {
    private const val images = "/images"
    const val illustrationsRoot = "$images/illustrations"

    object Icons {
        private const val root = "/icons"
        const val app_icon_round = "${root}/app_icon_round.svg"
        const val app_icon = "${root}/app_icon.svg"
        const val app_logo_long = "${root}/app_logo_long.svg"
        const val app_logo_pictogram = "${root}/app_logo_pictogram.svg"

        const val ic_boostie_logo = "${root}/ic_boostie_logo.svg"
        const val ic_comment_active = "${root}/ic_comment_active.svg"
        const val ic_comment_inactive = "${root}/ic_comment_inactive.svg"
        const val ic_subscriber_filled = "${root}/ic_subscriber_filled.svg"
        const val ic_dots = "${root}/ic_dots.svg"
        const val ic_play_simple = "${root}/ic_play_simple.svg"


    }
}
package shared.business.feature.creator.infrastructure

import com.russhwolf.settings.Settings
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.LocalDateTime
import shared.business.feature.common.infrastruture.LocalStorageCache
import shared.business.feature.creator.data.source.CreatorRemoteSource
import shared.common.Result
import shared.common.extensions.map
import shared.common.network.catchingBackendExceptions
import shared.model.common.domain.Language
import shared.model.error.domain.CreatorError
import shared.model.feature.creator.data.api.CreatorApiDto
import shared.model.feature.creator.data.api.toDomain
import shared.model.feature.creator.data.payload.CreateCreatorPayload
import shared.model.feature.creator.domain.Creator
import shared.model.feature.creator.domain.UpdateCreatorModel

class ApiCreatorRemoteSourceImpl(
    private val httpClient: HttpClient,
    settings: Settings,
) : CreatorRemoteSource {
    private val cache = LocalStorageCache<CreatorApiDto>("cache_creator", settings)
    override suspend fun getCreator(id: String): Result<Creator> {
        val cachedValue: CreatorApiDto? = cache.value()
        if (cachedValue != null) return Result.Success(cachedValue.toDomain())

        return try {
            catchingBackendExceptions {
                Result.Success(
                    httpClient.get("creator/$id").body<CreatorApiDto>(),
                ).map {
                    cache.store(it)
                    it.toDomain()
                }
            }
        } catch (e: Exception) {
            Result.Error(CreatorError.CreatorNotFound())
        }
    }

    override suspend fun createCreator(
        id: String,
        name: String,
        shortBio: String,
        fullBio: String,
        email: String,
        language: Language,
        createdAt: LocalDateTime,
    ): Result<Creator> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.post("creator/create") {
                    setBody(
                        CreateCreatorPayload(
                            name = name,
                            email = email,
                            shortBio = shortBio,
                            fullBio = fullBio,
                            language = language,
                        ),
                    )
                }.body<CreatorApiDto>()
                cache.store(body)
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(CreatorError.CannotCreateCreator())
        }
    }

    override suspend fun updateCreator(updateCreatorModel: UpdateCreatorModel): Result<Creator> {
        return try {
            catchingBackendExceptions {
                val body = httpClient.put("creator/update") {
                    setBody(updateCreatorModel.asUpdatePayload)
                }.body<CreatorApiDto>()
                cache.store(body)
                Result.Success(body.toDomain())
            }
        } catch (e: Exception) {
            Result.Error(CreatorError.CannotCreateCreator())
        }
    }
}

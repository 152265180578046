package shared.model.feature.post.data.payload

import kotlinx.serialization.Serializable

@Serializable
data class CreatePostCreatorTagPayload(
    val name: String,
    val hexColor: String = "#ffffff",
)

@Serializable
data class UpdatePostCreatorTagPayload(
    val name: String? = null,
    val hexColor: String? = null,
)

package boostie.vm.shared

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import co.touchlab.kermit.Logger
import domain.file.usecase.UploadLargeFileResult
import domain.file.usecase.UploadLargeFileUseCase
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.awaitCancellation
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import kotlinx.coroutines.suspendCancellableCoroutine
import org.w3c.dom.Audio
import org.w3c.files.File
import org.w3c.files.FileReader
import shared.common.ErrorResult
import shared.model.feature.content.domain.FileContent
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.resume

class UploadFileViewModel(
    private val uploadLargeFileUseCase: UploadLargeFileUseCase,
) : BaseIntentViewModel<UploadFileVMState, UploadFileVMIntent>(UploadFileVMState()), CoroutineScope {
    override val coroutineContext: CoroutineContext
        get() = SupervisorJob()
    private var currentJob: Job? = null

    override suspend fun applyIntent(intent: UploadFileVMIntent) {

        when (intent) {
            UploadFileVMIntent.OnCancel -> currentJob?.cancel()
            is UploadFileVMIntent.OnUpload -> uploadFile(
                file = intent.file, onSuccess = intent.onSuccess,
                onProgress = intent.onProgress,
                onError = intent.onError
            )
        }
    }

    private fun uploadFile(
        file: File,
        onSuccess: (FileContent?) -> Unit,
        onProgress: (Long) -> Unit,
        onError: (ErrorResult) -> Unit,
    ) {
        currentJob = launch(Dispatchers.Default) {
            uploadLargeFileUseCase(UploadLargeFileUseCase.Params(file, file.duration())).collect {
                state = state.copy(uploadLargeFileResult = it)
                when (it) {
                    is UploadLargeFileResult.Success -> onSuccess(it.fileContent)
                    is UploadLargeFileResult.Progress -> onProgress(it.progress)
                    is UploadLargeFileResult.Error -> onError(it.error)
                }
            }
        }
    }

    override suspend fun onViewDidAppear() {
        state = state.copy(uploadLargeFileResult = null)
    }

    private suspend fun File.duration(): Float? {
        if (!this.type.contains("audio") || !this.type.contains("video")) return null
        return suspendCancellableCoroutine { cont ->
            try {
                val reader = FileReader()
                reader.onload = {
                    val media = Audio(reader.result)
                    media.onloadedmetadata = {
                        cont.resume(media.duration.toFloat())
                        Logger.d { "Video duration: ${media.duration}" }
                    }
                    Unit
                }
                reader.readAsDataURL(this)
            } catch (ex: Exception) {
                cont.resume(null)
            }

            cont.invokeOnCancellation {
                cont.resume(null)
            }
        }
    }
}

data class UploadFileVMState(
    val uploadLargeFileResult: UploadLargeFileResult? = null,
) : VmState {
    val isUploading: Boolean
        get() = uploadLargeFileResult?.let {
            it is UploadLargeFileResult.Progress
        } ?: false

    val uploadProgress: Long?
        get() = uploadLargeFileResult?.let {
            (it as? UploadLargeFileResult.Progress)?.progress
        }

    val publicFileUrl: String?
        get() = uploadLargeFileResult?.let {
            (it as? UploadLargeFileResult.Success)?.fileContent?.publicUrl
        }

}

sealed interface UploadFileVMIntent : VmIntent {
    class OnUpload(
        val file: File,
        val onSuccess: (FileContent?) -> Unit,
        val onProgress: (Long) -> Unit,
        val onError: (ErrorResult) -> Unit,
    ) : UploadFileVMIntent

    object OnCancel : UploadFileVMIntent
}
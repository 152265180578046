package boostie.components.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.base
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.backgroundColor

val BoxStyle by ComponentStyle.base {
    Modifier.padding(SpaceMedium).borderRadius(RadiusXLarge)
}

val BoxStylePrimaryVariant by BoxStyle.addVariant {
    base { Modifier.styleModifier { background(MainColors.primaryGradient) } }
}

val BoxStyleBackgroundVariant by BoxStyle.addVariant {
    base { Modifier.backgroundColor(MainColors.background) }
}

val BoxStylePrimaryDashedVariant by BoxStyle.addVariant {
    base {
        Modifier
            .backgroundColor(MainColors.surface)
            .primaryDashedBorder
    }
}


val BoxStyleSurfaceVariant by BoxStyle.addVariant {
    base { Modifier.styleModifier { backgroundColor(MainColors.surface) } }
}


val BoxStyleHeaderBackgroundVariant by BoxStyle.addVariant {
    base { Modifier.styleModifier { backgroundColor(MainColors.headerBackground) } }
}

val SimpleErrorBoxBackgroundVariant by BoxStyle.addVariant {
    base { Modifier.styleModifier { backgroundColor(MainColors.error) } }
}
package boostie.pages.tags.views

import androidx.compose.runtime.Composable
import boostie.components.style.RadiusMedium
import boostie.components.style.SpaceSmall
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.PrimaryButton
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import org.jetbrains.compose.web.css.background
import shared.model.feature.post.domain.PostCreatorTag


sealed class CreatorPostTagViewProps(val tag: PostCreatorTag, val onClick: (PostCreatorTag) -> Unit) {
    class Simple(tag: PostCreatorTag, onClick: (PostCreatorTag) -> Unit) : CreatorPostTagViewProps(tag, onClick)
    class Removable(tag: PostCreatorTag, onClick: (PostCreatorTag) -> Unit) : CreatorPostTagViewProps(tag, onClick)
    class Editable(tag: PostCreatorTag, onClick: (PostCreatorTag) -> Unit) : CreatorPostTagViewProps(tag, onClick)
}


@Composable
fun CreatorPostTagView(
    props: CreatorPostTagViewProps,
    modifier: Modifier = Modifier,
) {
    val mod = modifier.then(Modifier.padding(SpaceSmall).borderRadius(RadiusMedium).styleModifier { background(props.tag.hexColor) })

    when (props) {
        is CreatorPostTagViewProps.Editable -> {
            PrimaryButton(mod, text = props.tag.name, iconRight = { FaPen(onPrimaryColor) }) {
                props.onClick(props.tag)
            }
        }

        is CreatorPostTagViewProps.Removable -> {
            PrimaryButton(mod, text = props.tag.name, iconRight = { FaXmark(onPrimaryColor) }) {
                props.onClick(props.tag)
            }
        }

        is CreatorPostTagViewProps.Simple -> {
            PrimaryButton(mod, text = props.tag.name) {
                props.onClick(props.tag)
            }
        }
    }
}
package shared.business.feature.user.data.repository

import shared.business.feature.user.data.source.UserRemoteSource
import shared.business.feature.user.domain.repository.UserRepository
import shared.common.Result
import shared.common.extensions.map
import shared.model.feature.user.domain.User

internal class UserRepositoryImpl(
    private val remoteSource: UserRemoteSource,
) : UserRepository {

    override suspend fun getUser(id: String, forceRefresh: Boolean): Result<User> =
        remoteSource.getUser(id, forceRefresh = forceRefresh)

    override suspend fun createUser(user: User): Result<User> =
        remoteSource.createUser(user).map { user }
}

package boostie.pages.post.list.views

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.MainColors
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SpaceSmall
import boostie.pages.tags.views.CreatorTagsFilterView
import boostie.util.Localization
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaRotateRight
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun PostsToolbarView(onNewPostClick: () -> Unit, onReload: (String?) -> Unit) {
    Box(modifier = BoxStyle.toModifier(BoxStyleBackgroundVariant).padding(SpaceSmall).fillMaxWidth()) {
        Column(Modifier.fillMaxWidth()) {
            Row(
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
            ) {
                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    onReload(null)
                }) {
                    Row {
                        FaRotateRight(Modifier.color(MainColors.iconOnPrimary))
                    }
                }

                Button(modifier = ButtonStyle.toModifier(PrimaryButtonStyleVariant), onClick = {
                    onNewPostClick()
                }) {
                    Row {
                        FaPlus(Modifier.color(MainColors.iconOnPrimary))
                        Space()
                        SpanText(Localization.add_new_post_label.string)
                    }
                }
            }
            Space()
            CreatorTagsFilterView(Modifier.fillMaxWidth(), onTagClick = {
                onReload(it.id)
            })

        }

    }
}
package shared.model.feature.creator.data.payload

import kotlinx.datetime.LocalDateTime
import shared.model.common.domain.Language

@kotlinx.serialization.Serializable
data class CreateCreatorPayload(
    val name: String,
    val email: String,
    val shortBio: String,
    val fullBio: String,
    val language: Language,
)

@kotlinx.serialization.Serializable
data class UpdateCreatorPayload(
    val name: String? = null,
    val shortBio: String? = null,
    val fullBio: String? = null,
    val email: String? = null,
    val language: Language? = null,
    val photoUrl: String? = null,
    val backgroundPhotoUrl: String? = null,
    val lastActivity: LocalDateTime? = null,
)

@kotlinx.serialization.Serializable
data class SetCreatorPhotoPayload(
    val photoUrl: String,
)

@kotlinx.serialization.Serializable
data class SetCreatorBackgroundPhotoPayload(
    val backgroundPhotoUrl: String,
)

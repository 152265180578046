package boostie.pages.tags.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.SpaceLarge
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceSmall
import boostie.components.widgets.PrimaryButton
import boostie.components.widgets.SimpleColumnDialogView
import boostie.components.widgets.TransparentSecondaryButton
import boostie.pages.post.create.CreatePostDefaults
import boostie.util.Localization
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.css.resize
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@Composable
fun CreateTagDialogView(showDialogCreateTag: MutableState<Boolean>, onCreate: (String, String) -> Unit) {
    var nameValue by remember { mutableStateOf("") }
    var colorValue by remember { mutableStateOf("#C100FF") }
    if (showDialogCreateTag.value) {
        SimpleColumnDialogView(title = "Kategorie", description = "Vytvorte si svoje kategorii") {
            Space(SpaceSmall)
            Input(InputType.Text, attrs = CreatePostDefaults.inputStyleModifier.toAttrs {
                style { resize(Resize.None) }
                placeholder("Nazev....")
                onInput {
                    nameValue = it.value
                }
            })
            Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
                SpanText("Barva kategorie", NormalTextStyle.toModifier().fillMaxWidth().fontWeight(FontWeight.SemiBold))
                Space()
                Input(InputType.Color, Modifier.width(56.px).height(32.px).toAttrs {
                    defaultValue(colorValue)
                    onInput {
                        colorValue = it.value
                    }
                })
            }


            Row(Modifier.padding(SpaceMedium)) {
                TransparentSecondaryButton(Localization.cancel_label.string, onColor = MainColors.primary) {
                    showDialogCreateTag.value = false
                }
                Space(SpaceLarge)
                PrimaryButton(text = Localization.create_label.string, enabled = nameValue.isNotBlank()) {
                    showDialogCreateTag.value = false
                    onCreate(nameValue, colorValue)
                }
            }
        }
    }
}
package firebasestorage.binding

import firebasestorage.external.FirebaseApp
import firebasestorage.external.initializeApp
import dev.bitspittle.firebase.app.FirebaseOptions
import kotlin.js.json

class FirebaseStorageApp internal constructor(private val wrapped: FirebaseApp) {
    companion object {
        fun initialize(options: FirebaseOptions, name: String? = null) = FirebaseStorageApp(
            initializeApp(
                json(
                    "apiKey" to options.apiKey,
                    "authDomain" to options.authDomain,
                    "databaseURL" to options.databaseURL,
                    "projectId" to options.projectId,
                    "storageBucket" to options.storageBucket,
                    "messagingSenderId" to options.messagingSenderId,
                    "appId" to options.appId,
                    "measurementId" to options.measurementId,
                ), name
            )
        )
    }

    val name get() = wrapped.name
    val options
        get() = FirebaseOptions(
            apiKey = wrapped.options.apiKey,
            authDomain = wrapped.options.authDomain,
            databaseURL = wrapped.options.databaseURL,
            projectId = wrapped.options.projectId,
            storageBucket = wrapped.options.storageBucket,
            messagingSenderId = wrapped.options.messagingSenderId,
            appId = wrapped.options.appId,
            measurementId = wrapped.options.measurementId,
        )

    fun getStorage() = FirebaseStorage(firebasestorage.external.getStorage(wrapped, options.storageBucket))

}

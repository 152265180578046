package boostie.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import org.koin.mp.KoinPlatformTools

@Composable
inline fun <reified T : Any> rememberDependency(): T = remember {
    KoinPlatformTools.defaultContext().get().get()
}

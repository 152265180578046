package boostie.pages.tags

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import shared.business.feature.post.domain.usecase.tags.CreatePostCreatorTagUseCase
import shared.business.feature.post.domain.usecase.tags.DeletePostCreatorTagUseCase
import shared.business.feature.post.domain.usecase.tags.GetCurrentCreatorTagsUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.model.feature.post.data.payload.CreatePostCreatorTagPayload
import shared.model.feature.post.domain.PostCreatorTag

class CreatorTagsViewModel(
    private val getCurrentCreatorTagsUseCase: GetCurrentCreatorTagsUseCase,
    private val createPostCreatorTagUseCase: CreatePostCreatorTagUseCase,
    private val deletePostCreatorTagUseCase: DeletePostCreatorTagUseCase,
) : BaseIntentViewModel<CreatorTagsViewModel.State, CreatorTagsViewModel.Intent>(State()) {
    private val _events = MutableSharedFlow<Events>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: Intent) {
        when (intent) {
            Intent.OnRefresh -> reloadTags()
            is Intent.OnNewTagInputChange -> state = state.copy(tagName = intent.input)
            Intent.OnSaveTag -> addTag()
            is Intent.CreateTag -> addTag(intent.name, intent.hexColor)
            is Intent.RemoveTag -> deleteTag(intent.tagId)
        }
    }

    override suspend fun onViewDidAppear() {
        reloadTags()
    }

    private suspend fun deleteTag(tagId: String) {
        state = state.copy(isLoading = true)
        state = when (val result = deletePostCreatorTagUseCase(tagId)) {
            is Result.Error -> state.copy(error = result.error)
            is Result.Success -> {
                _events.emit(Events.OnTagDeleted)
                reloadTags()
                state.copy(tagName = null)
            }
        }
        state = state.copy(isLoading = false)
    }


    private suspend fun addTag(name: String, hexColor: String?) {
        state = state.copy(isLoading = true)
        state = when (val result = createPostCreatorTagUseCase(CreatePostCreatorTagPayload(name, hexColor ?: "#FFFFFF"))) {
            is Result.Error -> state.copy(error = result.error)
            is Result.Success -> {
                _events.emit(Events.OnTagAdded)
                reloadTags()
                state.copy(tagName = null)
            }
        }
        state = state.copy(isLoading = false)
    }

    private suspend fun addTag() {
        val tagName = state.tagName
        if (tagName.isNullOrEmpty()) {
            return
        }
        addTag(tagName, null)
    }

    private suspend fun reloadTags() {
        state = state.copy(isLoading = true)
        state = when (val result = getCurrentCreatorTagsUseCase()) {
            is Result.Error -> {
                state.copy(error = result.error)
            }

            is Result.Success -> state.copy(tags = result.data)
        }
        state = state.copy(isLoading = false)
    }

    data class State(
        val isLoading: Boolean = false,
        val error: ErrorResult? = null,
        val tags: List<PostCreatorTag>? = null,
        val tagName: String? = null,
    ) : VmState {
        fun getAvailableTags(selectedTagIds: List<String>?) = tags?.filter { tagFromAll ->
            selectedTagIds?.firstOrNull { it == tagFromAll.id } == null
        }
    }

    sealed interface Intent : VmIntent {
        object OnRefresh : Intent
        object OnSaveTag : Intent
        class CreateTag(val name: String, val hexColor: String) : Intent
        class OnNewTagInputChange(val input: String) : Intent
        class RemoveTag(val tagId: String) : Intent
    }

    sealed interface Events {
        object OnTagAdded : Events
        object OnTagDeleted : Events
    }


}


package shared.common.extensions

import kotlinx.datetime.*
import kotlin.math.abs

val Long.asInstant: Instant
    get() = Instant.fromEpochMilliseconds(this)

val Instant.asCurrentLocalDateTime: LocalDateTime
    get() = toLocalDateTime(TimeZone.currentSystemDefault())

val LocalDateTime.asEpochMilliseconds: Long
    get() = toInstant(TimeZone.currentSystemDefault()).toEpochMilliseconds()

val LocalDateTime.Companion.now: LocalDateTime
    get() = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())

fun LocalDateTime.between(other: LocalDateTime): Long {
    val thisInstant = toInstant(TimeZone.UTC)
    val otherInstant = other.toInstant(TimeZone.UTC)

    return abs(otherInstant.epochSeconds - thisInstant.epochSeconds)
}

val LocalDate.asLocalDateTime
    get() = atStartOfDayIn(TimeZone.currentSystemDefault()).toLocalDateTime(
        TimeZone.currentSystemDefault(),
    )

val currentTimeInMillis
    get() = Clock.System.now().toEpochMilliseconds()

val currentLocalDateTime
    get() = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())

val currentLocalDate: LocalDate
    get() = currentLocalDateTime.date
val currentLocalTime: LocalTime
    get() = currentLocalDateTime.time

fun millisToMinutes(millis: Long) = millis.div(Time.minuteInMillis.toFloat())
fun secondsToHours(seconds: Long) = seconds.div(Time.hourInSeconds.toFloat())
fun millisToSeconds(millis: Long) = millis.div(Time.secondInMillis)

object Time {
    const val secondInMillis = 1_000L
    const val minuteInMillis = 60 * secondInMillis
    const val minuteInSeconds = 60
    const val hourInMillis = 60 * minuteInMillis
    const val hourInSeconds = 60 * minuteInSeconds
    const val dayInMillis = 24 * hourInMillis
}

/**
 * Parse times like: 2023-04-30T21:36:41
 */
fun Instant.Companion.parseWithBasicOffset(string: String): Instant {
    var lastDigit = string.length
    while (lastDigit > 0 && string[lastDigit - 1].isDigit()) {
        --lastDigit
    }
    val digits = string.length - lastDigit // how many digits are there at the end of the string
    if (digits <= 2) {
        return parse(string) // no issue in any case
    }
    var newString = string.substring(0, lastDigit + 2)
    lastDigit += 2
    while (lastDigit < string.length) {
        newString += ":" + string.substring(lastDigit, lastDigit + 2)
        lastDigit += 2
    }
    return parse(newString)
}

package shared.business.feature.auth.domain.usecase

import shared.business.feature.auth.domain.repository.AuthRepository
import shared.common.Result
import shared.common.usecase.UseCaseResultNoParams

interface SignOutUseCase : UseCaseResultNoParams<Unit>

internal class SignOutUseCaseImpl(
    private val repository: AuthRepository,
) : SignOutUseCase {
    override suspend fun invoke(): Result<Unit> {
        return repository.signOut()
    }
}

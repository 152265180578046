package shared.business.feature.post

import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.post.data.repository.PostCreatorTagRepositoryImpl
import shared.business.feature.post.data.repository.PostRepositoryImpl
import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.business.feature.post.domain.repository.PostRepository
import shared.business.feature.post.domain.usecase.CreatePostUseCase
import shared.business.feature.post.domain.usecase.CreatePostUseCaseImpl
import shared.business.feature.post.domain.usecase.DeletePostUseCase
import shared.business.feature.post.domain.usecase.DeletePostUseCaseImpl
import shared.business.feature.post.domain.usecase.GetPostsByCreatorUseCase
import shared.business.feature.post.domain.usecase.GetPostsByCreatorUseCaseImpl
import shared.business.feature.post.domain.usecase.UpdatePostUseCase
import shared.business.feature.post.domain.usecase.UpdatePostUseCaseImpl
import shared.business.feature.post.domain.usecase.tags.CreatePostCreatorTagUseCase
import shared.business.feature.post.domain.usecase.tags.CreatePostCreatorTagUseCaseImpl
import shared.business.feature.post.domain.usecase.tags.DeletePostCreatorTagUseCase
import shared.business.feature.post.domain.usecase.tags.DeletePostCreatorTagUseCaseImpl
import shared.business.feature.post.domain.usecase.tags.GetCurrentCreatorTagsUseCase
import shared.business.feature.post.domain.usecase.tags.GetCurrentCreatorTagsUseCaseImpl
import shared.business.feature.post.domain.usecase.tags.UpdatePostCreatorTagUseCase
import shared.business.feature.post.domain.usecase.tags.UpdatePostCreatorTagUseCaseImpl

internal val postModule = module {
    singleOf(::PostRepositoryImpl) bind PostRepository::class
    singleOf(::PostCreatorTagRepositoryImpl) bind PostCreatorTagRepository::class

    // Usecases
    factoryOf(::CreatePostUseCaseImpl) bind CreatePostUseCase::class
    factoryOf(::DeletePostUseCaseImpl) bind DeletePostUseCase::class
    factoryOf(::GetPostsByCreatorUseCaseImpl) bind GetPostsByCreatorUseCase::class
    factoryOf(::UpdatePostUseCaseImpl) bind UpdatePostUseCase::class

    // Tags
    factoryOf(::CreatePostCreatorTagUseCaseImpl) bind CreatePostCreatorTagUseCase::class
    factoryOf(::DeletePostCreatorTagUseCaseImpl) bind DeletePostCreatorTagUseCase::class
    factoryOf(::GetCurrentCreatorTagsUseCaseImpl) bind GetCurrentCreatorTagsUseCase::class
    factoryOf(::UpdatePostCreatorTagUseCaseImpl) bind UpdatePostCreatorTagUseCase::class
}

package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.style.BorderSmall
import boostie.components.style.MainColors
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderColor
import com.varabyte.kobweb.compose.ui.modifiers.borderStyle
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.borderWidth
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.silk.components.layout.Divider
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px


@Composable
fun SimpleDivider(modifier: Modifier = Modifier.fillMaxWidth(), color: CSSColorValue = MainColors.lineSeparator) {
    Divider(modifier.height(BorderSmall).borderColor(color = color).borderTop(BorderSmall))
}
package shared.model.feature.post.data.api

import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import shared.model.feature.content.domain.FileContentType
import shared.model.feature.post.domain.ContentFileItem
import shared.model.feature.post.domain.Post

@Serializable
data class PostApiDto(
    val id: String,
    val creatorId: String,
    val title: String,
    val description: String,
    val draft: Boolean,
    val createdAt: String,
    val publishedAt: String?,
    val deletedAt: String?,
    val editedAt: String?,
    val publicLink: String?,
    val tagIds: List<String>?,
    val fileItems: FileItemsApiDto?,
) {
    @Serializable
    data class FileItemsApiDto(
        val audio: AudioApiDto?,
        val video: VideoApiDto?,
        val images: ImagesApiDto?,
    ) {
        @Serializable
        data class AudioApiDto(
            val full: ContentFileItemApiDto?,
            val public: ContentFileItemApiDto?,
        )

        @Serializable
        data class VideoApiDto(
            val full: ContentFileItemApiDto?,
            val public: ContentFileItemApiDto?,
        )

        @Serializable
        data class ImagesApiDto(
            val files: List<ContentFileItemApiDto>?,
        )
    }
}

fun PostApiDto.toDomain() = Post(
    id = id,
    creatorId = creatorId,
    title = title,
    description = description,
    draft = draft,
    createdAt = createdAt.toLocalDateTime(),
    publishedAt = publishedAt?.toLocalDateTime(),
    deletedAt = deletedAt?.toLocalDateTime(),
    editedAt = editedAt?.toLocalDateTime(),
    publicLink = publicLink,
    tagIds = tagIds,
    fileItems = fileItems?.toDomain(),
)

fun Post.toApiDto() = PostApiDto(
    id = id,
    creatorId = creatorId,
    title = title,
    description = description,
    draft = draft,
    createdAt = createdAt.toString(),
    publishedAt = publishedAt.toString(),
    deletedAt = deletedAt?.toString(),
    editedAt = editedAt?.toString(),
    publicLink = publicLink,
    tagIds = tagIds,
    fileItems = fileItems?.toApiDto(),
)

fun PostApiDto.FileItemsApiDto.toDomain() = Post.FileItems(
    audio = audio?.toDomain(),
    video = video?.toDomain(),
    images = images?.toDomain(),
)

fun Post.FileItems.toApiDto() = PostApiDto.FileItemsApiDto(
    audio = audio?.toApiDto(),
    video = video?.toApiDto(),
    images = images?.toApiDto(),
)

fun PostApiDto.FileItemsApiDto.AudioApiDto.toDomain() = Post.FileItems.Audio(
    full = full?.toDomain(),
    public = public?.toDomain(),
)

fun Post.FileItems.Audio.toApiDto() = PostApiDto.FileItemsApiDto.AudioApiDto(
    full = full?.toApiDto(),
    public = public?.toApiDto(),
)

fun PostApiDto.FileItemsApiDto.VideoApiDto.toDomain() = Post.FileItems.Video(
    full = full?.toDomain(),
    public = public?.toDomain(),
)

fun Post.FileItems.Video.toApiDto() = PostApiDto.FileItemsApiDto.VideoApiDto(
    full = full?.toApiDto(),
    public = public?.toApiDto(),
)

fun PostApiDto.FileItemsApiDto.ImagesApiDto.toDomain() =
    Post.FileItems.Images(files = files?.map { it.toDomain() })

fun Post.FileItems.Images.toApiDto() =
    PostApiDto.FileItemsApiDto.ImagesApiDto(files = files?.map { it.toApiDto() })

fun ContentFileItemApiDto.toDomain() = ContentFileItem(
    id = id,
    size = size,
    publicUrl = publicUrl,
    duration = duration,
    type = FileContentType.valueOf(type),
)

fun ContentFileItem.toApiDto() = ContentFileItemApiDto(
    id = id,
    size = size,
    publicUrl = publicUrl,
    duration = duration,
    type = type.name,
)

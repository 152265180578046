@file:OptIn(ExperimentalSerializationApi::class)

package shared.business.di

import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.ObservableSettings
import com.russhwolf.settings.Settings
import com.russhwolf.settings.coroutines.toFlowSettings
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.request.header
import io.ktor.http.HttpHeaders
import kotlinx.serialization.ExperimentalSerializationApi
import org.koin.core.module.Module
import org.koin.dsl.module
import shared.business.feature.auth.authModule
import shared.business.feature.auth.data.source.FirebaseAuthSource
import shared.business.feature.comment.commentModule
import shared.business.feature.common.commonModule
import shared.business.feature.creator.creatorModule
import shared.business.feature.filecontent.fileContentModule
import shared.business.feature.post.postModule
import shared.business.feature.user.userModule
import shared.common.network.ClientHttpConfig
import shared.common.network.KtorClient
import shared.common.network.installFirebaseInterceptor
import shared.common.provider.AppInfoProvider
import shared.common.provider.BuildType

internal expect val platformModule: Module

private fun getPlatformModule() = platformModule

@ExperimentalSerializationApi
@OptIn(ExperimentalSettingsApi::class)
private val coreModule = module {
    single(boostieBackendKtorClient) {
        val firebaseAuthSource: FirebaseAuthSource = get()
        KtorClient.init(
            appInfoProvider = get(),
            clientHttpConfig = getClientConfig(get()),
        ).config {
            installFirebaseInterceptor {
                firebaseAuthSource.getToken().getOrNull()
            }

            defaultRequest {
//                header(HttpHeaders.AccessControlAllowOrigin, "*")
            }
        }
    }

    single { Settings() }
    single { (get<Settings>() as ObservableSettings).toFlowSettings() }
}

val businessModules = listOf(
    getPlatformModule(),
    coreModule,
    commonModule,
    authModule,
    creatorModule,
    userModule,
    postModule,
    commentModule,
    fileContentModule,
)

private fun getClientConfig(
    appInfoProvider: AppInfoProvider,
): ClientHttpConfig {
    return when (appInfoProvider.buildType) {
        BuildType.Debug -> ClientHttpConfig.Debug(
            baseUrl = "http://localhost:8090/v1/",
        )
//        BuildType.Debug,
        BuildType.Alpha,
        BuildType.Beta,
        -> ClientHttpConfig.Debug(
            baseUrl = "https://boostie-ultimate-test.up.railway.app/v1/",
        )

        BuildType.Release -> ClientHttpConfig.Release(
            baseUrl = "http://localhostsss:8080/v1/",
        )
    }
}

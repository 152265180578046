package boostie.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import boostie.components.Resources
import boostie.components.animation.fadeInTransition
import boostie.components.style.SizeLarge
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.rotate
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaSpinner
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.plus
import org.jetbrains.compose.web.css.px

@Composable
fun ProgressIndicator(modifier: Modifier = Modifier) {
    val rotation by produceState(0.deg) {
        while (true) {
            value += 10.deg
            delay(30)
        }
    }
    FaSpinner(modifier.rotate(rotation).fadeInTransition())
}


@Composable
fun BigBoostieProgressIndicator(modifier: Modifier = Modifier) {
    val rotation by produceState(0.deg) {
        while (true) {
            value += 5.deg
            delay(15)
        }
    }
    Image(
        src = Resources.Icons.app_logo_pictogram, modifier = modifier.size(SizeLarge).rotate(rotation).fadeInTransition()
    )
}
package shared.common.error

import shared.common.ErrorResult

sealed class AuthError(
    message: String? = null,
    throwable: Throwable? = null,
) : ErrorResult(message, throwable = throwable) {
    class Unauthorized(throwable: Throwable? = null) : AuthError(throwable = throwable)
    class InvalidCredentials(message: String? = null) : AuthError(message = message)
    class InvalidEmail(message: String? = null) : AuthError(message = message)
    object NoUserAuthenticated : AuthError("No user authenticated")
    object NoUserLoggedIn : AuthError("No user logged in", null)
    class UserDisabled(
        message: String?,
        innerException: Exception?,
    ) : AuthError(message, innerException) {
        constructor(message: String?) : this(message, null)
    }

    class GoogleSignInUnsuccessful(
        message: String?,
        innerException: Exception?,
    ) : AuthError(message, innerException) {
        constructor(message: String?) : this(message, null)
    }
}

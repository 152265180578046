package boostie.util

import shared.common.ErrorResult

sealed class ValidationError(throwable: Throwable? = null, message: String? = null) : ErrorResult(
    throwable = throwable,
    message = message,
) {
    object NoName : ValidationError()
    object NoShortBio : ValidationError()
    object NoFullBio : ValidationError()
    object NoImageSelected : ValidationError()

}
package shared.business.feature.common

import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.common.usecase.HasConnectivityFlowUseCase
import shared.business.feature.common.usecase.HasConnectivityFlowUseCaseImpl

internal val commonModule = module {
    factoryOf(::HasConnectivityFlowUseCaseImpl) bind HasConnectivityFlowUseCase::class
}

package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.SubTitleStyle
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.MediaFileContent
import boostie.components.widgets.SecondaryButton
import boostie.components.widgets.SimpleUploadButton
import boostie.util.Localization
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.SupportedFormats

@Composable
fun VideoFileContentManagementView(videoContent: FileContent?, uploadLabel: String, onAdd: (FileContent) -> Unit, onRemove: () -> Unit) {
    if (videoContent == null) {
        Column(BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth()) {
            Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                var progress: Long? by remember { mutableStateOf(null) }
                SimpleUploadButton(uploadLabel, format = SupportedFormats.video, icon = { FaUpload(onPrimaryColor) }, onUploaded = {
                    progress = null
                    it?.let(onAdd)
                }, onFailed = {
                    progress = null
                }, onUploadProgress = { progress = it })
                if (progress != null) {
                    Space()
                    SpanText("$progress %", SubTitleStyle.toModifier().fontWeight(FontWeight.SemiBold))
                }
            }
        }
    } else {
        MediaFileContent(
            modifier = Modifier.fillMaxWidth(),
            fileContent = videoContent,
            onAction1 = {
                SecondaryButton(text = Localization.remove_label.string, iconLeft = { FaTrash(Modifier.color(MainColors.iconOnPrimary)) }) {
                    onRemove()
                }
            })
    }
}

@Composable
fun AudioFileContentManagementView(audioContent: FileContent?, uploadLabel: String, onAdd: (FileContent) -> Unit, onRemove: () -> Unit) {
    if (audioContent == null) {
        Column(BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth()) {
            Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                var progress: Long? by remember { mutableStateOf(null) }
                SimpleUploadButton(uploadLabel, format = SupportedFormats.audio, icon = { FaUpload(onPrimaryColor) }, onUploaded = {
                    progress = null
                    it?.let(onAdd)
                }, onFailed = {
                    progress = null
                }, onUploadProgress = { progress = it })
                if (progress != null) {
                    Space()
                    SpanText("$progress %", SubTitleStyle.toModifier().fontWeight(FontWeight.SemiBold))
                }
            }
        }
    } else {
        MediaFileContent(
            modifier = Modifier.fillMaxWidth(),
            fileContent = audioContent,
            onAction1 = {
                SecondaryButton(text = Localization.remove_label.string, iconLeft = { FaTrash(Modifier.color(MainColors.iconOnPrimary)) }) {
                    onRemove()
                }
            })
    }
}
package boostie.model.error

import shared.common.ErrorResult


// TODO: localize this file
sealed class PostModificationError(message: String) : ErrorResult(
    throwable = null,
    message = message,
) {
    object NoTitle: PostModificationError(message = "Fill title")
    object NoDescription: PostModificationError(message = "Description is empty")
    object TypeVideoNoFullVideo: PostModificationError(message = "For this type of post you have to add full video variant at least")
    object TypeAudioNoFullAudio: PostModificationError(message = "For this type of post you have to add full audio variant at least")

}
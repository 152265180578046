package shared.common.koin

import org.koin.core.KoinApplication
import org.koin.core.context.startKoin
import org.koin.dsl.KoinAppDeclaration
import org.koin.dsl.module
import shared.common.provider.ApplicationCoroutineScope
import shared.common.provider.ApplicationCoroutineScopeProvider

fun initKoin(
    coroutineScopeProvider: ApplicationCoroutineScopeProvider,
    appDeclaration: KoinAppDeclaration = {},
): KoinApplication {
    val koinApplication = startKoin {
        appDeclaration()
        modules(
            module {
                single<ApplicationCoroutineScope> { coroutineScopeProvider }
            },
        )
    }

    // Dummy initialization logic, making use of appModule declarations for demonstration purposes.
    val koin = koinApplication.koin

    // doOnStartup is a lambda which is implemented in Swift on iOS side
    val doOnStartup = koin.getOrNull<() -> Unit>()
    doOnStartup?.invoke()

    return koinApplication
}

package boostie.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import boostie.components.widgets.BigBoostieProgressIndicator
import boostie.toLogin
import boostie.toOnBoarding
import boostie.vm.shared.CreatorValidationEvents
import boostie.vm.shared.CreatorValidationViewModel
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.rememberPageContext


@Composable
fun CheckOnboarding(
    content: @Composable () -> Unit,
) {
    val router = rememberPageContext().router
    val vm = rememberDependency<CreatorValidationViewModel>()


    LaunchedEffect(vm) {
        vm.events.collect {
            when (it) {
                CreatorValidationEvents.GoToLogin -> router.toLogin()
                CreatorValidationEvents.GoToOnboarding -> router.toOnBoarding()
            }
        }
    }

    LaunchedEffect(Unit) {
        vm.onViewDidAppear()
    }


    Box(Modifier.fillMaxWidth()) {
        if (vm.state.isLoading) {
            BigBoostieProgressIndicator(Modifier.align(Alignment.Center))
        } else {
            if (vm.state.isFulfilled) {
                content()
            }
        }

    }
}

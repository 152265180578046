package shared.business.feature.auth.domain.usecase

import shared.business.feature.auth.domain.repository.AuthRepository
import shared.business.feature.user.domain.usecase.CreateUserUseCase
import shared.business.feature.user.domain.usecase.GetCurrentUserUseCase
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.auth.domain.AuthState
import shared.model.feature.user.domain.User

interface SignInUseCase : UseCaseResult<SignInUseCase.Params, User> {
    sealed class Params {
        class Email(val email: String, val password: String) : Params()
        class CreateEmailAccount(val email: String, val password: String) : Params()
        object Facebook : Params()
        object Apple : Params()
        class Google(val tokenId: String) : Params()
    }
}

internal class SignInUseCaseImpl(
    private val repository: AuthRepository,
    private val getCurrentUser: GetCurrentUserUseCase,
    private val createUser: CreateUserUseCase,
) : SignInUseCase {
    override suspend fun invoke(params: SignInUseCase.Params): Result<User> {
        val signInResult = when (params) {
            SignInUseCase.Params.Apple -> repository.signWithApple()
            is SignInUseCase.Params.Email -> repository.signWithEmail(params.email, params.password)
            is SignInUseCase.Params.CreateEmailAccount -> repository.createEmailAccount(
                params.email,
                params.password,
            )

            SignInUseCase.Params.Facebook -> repository.signWithFacebook()
            is SignInUseCase.Params.Google -> repository.signWithGoogle(params.tokenId)
        }

        return when (signInResult) {
            is Result.Error -> Result.Error(signInResult.error)
            is Result.Success -> {
                val user = getCurrentUser(GetCurrentUserUseCase.Params.Get).getOrNull()
                if (user != null) {
                    Result.Success(user)
                } else {
                    val authState = (signInResult.data as AuthState.Authenticated)
                    when (
                        val result = createUser(
                            CreateUserUseCase.Params(
                                id = authState.id,
                                nickname = authState.name,
                                email = authState.email,
                            ),
                        )
                    ) {
                        is Result.Error -> Result.Error(result.error)
                        is Result.Success -> result
                    }
                }
            }
        }
    }
}

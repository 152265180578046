package boostie.components.style

import com.varabyte.kobweb.compose.css.BackgroundColor
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderWidth
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.active
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.common.ariaDisabled
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.not
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

private val disabledOpacity = 50.percent

val PrimaryButtonStyleVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onPrimary).styleModifier {
            background(MainColors.primaryGradient)

        }.lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }.cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }
    }

    (active + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientPress)
        }
    }
}

val PrimaryButtonStyleUnselectedVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onPrimary).styleModifier {
            background(MainColors.primaryGradientHover)

        }.lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradient)
        }.cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradient)
        }
    }

    (active + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientPress)
        }
    }
}

val SecondaryButtonStyleVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onPrimary).styleModifier {
            background(MainColors.secondaryGradient)
        }.lineHeight(1.2).fontSize(1.cssRem).padding(leftRight = SpaceMedium, topBottom = 14.px).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }
    (hover + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }.cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }
    }

    (active + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientPress)
        }
    }
}

val TransparentSecondaryButtonStyleVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onBackground).backgroundColor(MainColors.secondary.copy(alpha = 30))
            .lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }

    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.secondary.copy(alpha = 40))
    }

    (focus + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.secondary.copy(alpha = 40))
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.secondary.copy(alpha = 40))
    }

}


val TransparentButtonStyleVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onPrimary).backgroundColor(MainColors.primary.copy(alpha = 0))
            .lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }

    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.primary.copy(alpha = 10))
    }

    (focus + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.primary.copy(alpha = 10))
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(MainColors.primary.copy(alpha = 10))
    }

}


val CircleIconButtonStyleVariant by ButtonStyle.addVariant {
    base {
        Modifier.size(48.px).clip(Circle()).color(MainColors.iconOnPrimary).styleModifier {
            background(MainColors.primaryGradient)
        }.padding(SpaceMediumSmall).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }.cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientHover)
        }
    }

    (active + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradientPress)
        }
    }
}


val PrimaryButtonSelectedVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onPrimary).styleModifier {
            background(MainColors.primaryGradient)

        }.lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradient)
        }
    }

    (focus + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradient)
        }
    }

    (active + not(ariaDisabled)) {
        Modifier.styleModifier {
            background(MainColors.primaryGradient)
        }
    }

}

val ButtonUnselectedVariant by ButtonStyle.addVariant {
    base {
        Modifier.color(MainColors.onBackground)
            .backgroundColor(MainColors.background)
            .lineHeight(1.2).fontSize(1.cssRem).padding(SpaceMedium).borderRadius(RadiusXLarge).borderWidth(0.px)
            // By default, don't use outlines to indicate focused buttons - we'll use background color instead to
            // indicate focus
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier.opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .styleModifier {
                background(MainColors.secondaryGradient)
            }
            .color(MainColors.onPrimary)
            .cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier
            .styleModifier {
                backgroundColor(MainColors.onBackground)
            }
    }

    (active + not(ariaDisabled)) {
        Modifier
            .styleModifier {
                backgroundColor(MainColors.background)
            }
    }

}


val PrimaryTextButtonStyleVariant by ComponentStyle {
    base {
        Modifier.color(MainColors.primary).backgroundColor(BackgroundColor.Transparent)
            .lineHeight(1.2).fontSize(1.cssRem).padding(SpaceXSmall)
            .borderRadius(RadiusXLarge).borderWidth(0.px)
            .outline(0.px).userSelect(UserSelect.None) // No selecting text within buttons
            .transition(CSSTransition("ease-in-out", 0.3.s))
    }
    ariaDisabled {
        Modifier
            .backgroundColor(BackgroundColor.Transparent)
            .opacity(disabledOpacity)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(BackgroundColor.Transparent)
            .styleModifier {
                color(MainColors.primaryGradientHover)
            }.cursor(Cursor.Pointer)
    }

    (focus + not(ariaDisabled)) {
        Modifier
            .backgroundColor(BackgroundColor.Transparent)
            .styleModifier {
                color(MainColors.primaryGradientHover)
            }
    }

    (active + not(ariaDisabled)) {
        Modifier
            .backgroundColor(BackgroundColor.Transparent)
            .styleModifier {
                color(MainColors.primaryGradientPress)
            }
    }
}
package shared.model.feature.creator.data.api

import kotlinx.datetime.toLocalDateTime
import shared.model.common.domain.Language
import shared.model.feature.creator.domain.Creator

@kotlinx.serialization.Serializable
data class CreatorApiDto(
    val id: String,
    val userId: String,
    val name: String?,
    val email: String?,
    val photoUrl: String?,
    val backgroundPhotoUrl: String?,
    val shortBio: String?,
    val fullBio: String?,
    val language: String?,
    val isVerified: Boolean,
    val created: String,
    val lastActivity: String,
)

fun CreatorApiDto.toDomain() = Creator(
    id = id,
    userId = userId,
    name = name,
    email = email,
    isVerified = isVerified,
    photoUrl = photoUrl,
    backgroundPhotoUrl = backgroundPhotoUrl,
    shortBio = shortBio,
    fullBio = fullBio,
    language = language?.let { Language.valueOf(language) },
    createdAt = created.toLocalDateTime(),
    lastActivity = lastActivity.toLocalDateTime(),
)

fun Creator.toApiDto() = CreatorApiDto(
    id = id,
    userId = userId,
    name = name,
    email = email,
    isVerified = isVerified,
    photoUrl = photoUrl,
    backgroundPhotoUrl = backgroundPhotoUrl,
    shortBio = shortBio,
    fullBio = fullBio,
    language = language?.name,
    created = createdAt.toString(),
    lastActivity = lastActivity.toString(),
)

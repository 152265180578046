package boostie.pages.post.list

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import shared.business.feature.post.domain.usecase.CreatePostUseCase
import shared.business.feature.post.domain.usecase.GetPostsByCreatorUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.model.feature.post.data.payload.CreatePostPayload
import shared.model.feature.post.domain.Post

class PostsViewModel(
    private val getPostsByCreatorUseCase: GetPostsByCreatorUseCase,
) : BaseIntentViewModel<PostsVMState, PostsVMIntent>(PostsVMState()) {
    private val _events = MutableSharedFlow<PostsVMIntent>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: PostsVMIntent) {
        when (intent) {
            is PostsVMIntent.OnRefresh -> reloadPosts(intent.tagId)
        }
    }

    override suspend fun onViewDidAppear() {
        reloadPosts(null)
    }

    private suspend fun reloadPosts(tagId: String?) {
        state = state.copy(isLoading = true)
        state = when (val result = getPostsByCreatorUseCase(GetPostsByCreatorUseCase.Params(null, null, tagId))) {
            is Result.Error -> {
                state.copy(error = result.error)
            }

            is Result.Success -> state.copy(posts = result.data)
        }
        state = state.copy(isLoading = false)
    }

}

data class PostsVMState(
    val isLoading: Boolean = false,
    val error: ErrorResult? = null,
    val posts: List<Post>? = null,
) : VmState

sealed interface PostsVMIntent : VmIntent {
    class OnRefresh(val tagId: String?) : PostsVMIntent
}

sealed interface PostsVMEvents {

}

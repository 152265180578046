package boostie.components.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

val Modifier.primaryDashedBorder
    get() = border(1.px, LineStyle.Dashed, MainColors.primary)


val onPrimaryColor
    get() = Modifier.color(MainColors.iconOnPrimary)

val onError
    get() = Modifier.color(MainColors.onError)

val primaryColor
    get() = Modifier.color(MainColors.primary)

val onBackgroundColor
    get() = Modifier.color(MainColors.onBackground)
val onBackgroundSecondaryColor
    get() = Modifier.color(MainColors.onBackgroundSecondary)

object MainColors {
    val primary = Color.rgb(0xC100FF)
    val secondary = Color.rgb(0xFF3899)
    val primaryLink = Color.rgb(0xC100FF)
    val primaryGradient = "linear-gradient(180deg, #FF3899 0%, #C100FF 100%)"
    val primaryGradientHover = "linear-gradient(180deg, #FF78BA 0%, #D145FF 100%)"
    val primaryGradientPress = "linear-gradient(180deg, #BD266F 0%, #8201AC 100%)"
    val onPrimary = Color.rgb(0xffffff)
    val secondaryGradient = "linear-gradient(180deg, rgba(255, 0, 122, 0.4) 0%, rgba(138, 0, 187, 0.4) 100%)"
    val onSecondaryGradient = "linear-gradient(180deg, #FF007A 0%, #8A00BB 100%)"
    val tertiaryGradient = "linear-gradient(180deg, rgba(255, 0, 122, 0.1) 0%, rgba(138, 0, 187, 0.1) 100%)"
    val onTertiaryGradient = "linear-gradient(180deg, #FF007A 0%, #8A00BB 100%)"
    val error = Color.rgb(0xEF5658)
    val onError = Color.rgb(0xffffff)
    val errorSecondary = Color.rgba(239, 86, 88, 0.4f)
    val onErrorSecondary = Color.rgb(0xEF5658)
    val background = Color.rgb(0xECECEC)
    val onBackground = Color.rgb(0x4D4D4D)
    val onBackgroundSecondary = Color.rgb(0x777777)
    val transparentBackground = Color.argb(0x4D4D4D).copy(alpha = 75)
    val onTransparentBackground = Color.rgb(0xEEEEEE)
    val surface = Color.rgb(0xF9F9F9)
    val onSurface = Color.rgb(0x4D4D4D)
    val onSurfaceSecondary = Color.rgb(0x959595)
    val lineSeparator = Color.rgb(0xE9E9E9)
    val primaryLineSeparator = Color.rgb(0xC100FF).copy(alpha = 50)
    val onOverlay = Color.rgb(0xF0F0F0)
    val onOverlaySecondary = Color.rgb(0xD0D0D0)

    val inputBackground = org.jetbrains.compose.web.css.Color.white
    val inputBackgroundSecondary = Color.rgb(0xF2F2F2)
    val headerBackground = org.jetbrains.compose.web.css.Color.white
    val overlayContentBackground = org.jetbrains.compose.web.css.Color.white

    val iconOnPrimary = org.jetbrains.compose.web.css.Color.white

    val white = Color.rgb(0xFFFFFF)
    val black = Color.rgb(0x000000)
}


package shared.model.error.domain

import shared.common.ErrorResult

/**
 * Error type used anywhere in the project. Contains subclasses for common exceptions that can happen anywhere
 * @param throwable optional [Throwable] parameter used for debugging or crash reporting
 */
sealed class PostCreatorTagError(throwable: Throwable? = null, message: String? = null) :
    ErrorResult(
        throwable = throwable,
        message = message,
    ) {
    class CreatorTagNotFound(id: String) : PostCreatorTagError(
        message = "No item has been found id: $id",
    )
    object TagsNotFound : PostCreatorTagError(message = "No items has been found")
    object CannotDeleteTagOfAnotherCreator : PostCreatorTagError(
        message = "Cannot delete tag of another creator",
    )
    class CannotBeDeleted(id: String) : PostCreatorTagError(message = "Cannot be deleted id: $id")
    object CreateFailed : PostCreatorTagError(message = "Create failed")
    object PostCreateCreatorTagMissingId : PostCreatorTagError(message = "Missing postId")
}

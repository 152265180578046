package shared.common.provider

import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.callbackFlow

abstract class ConnectivityProvider {
    abstract fun addListener(listener: (state: NetworkState) -> Unit)
    abstract fun getNetworkState(): NetworkState

    fun getConnectivityFlow() = callbackFlow {
        addListener { state ->
            when (state) {
                is NetworkState.Connected -> state.hasInternet
                else -> false
            }.let {
                trySend(it)
            }
        }
        awaitClose()
    }

    sealed class NetworkState {
        object NotConnected : NetworkState()
        class Connected(val hasInternet: Boolean) : NetworkState()
    }
}

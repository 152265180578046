package shared.common.network

sealed class ClientHttpConfig(
    val baseUrl: String,
) {
    class Debug(baseUrl: String) : ClientHttpConfig(baseUrl = baseUrl)
    class Alpha(baseUrl: String) : ClientHttpConfig(baseUrl)
    class Beta(baseUrl: String) : ClientHttpConfig(baseUrl)
    class Release(baseUrl: String) : ClientHttpConfig(baseUrl)
}

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import boostie.components.style.MainColors
import boostie.util.rememberDependency
import co.touchlab.kermit.Logger
import com.varabyte.kobweb.compose.css.BoxSizing
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.bottom
import com.varabyte.kobweb.compose.ui.modifiers.boxSizing
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.right
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerBaseStyle
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.MutableSilkPalette
import com.varabyte.kobweb.silk.theme.colors.MutableSilkPalettes
import com.varabyte.kobweb.silk.theme.colors.getColorMode
import dev.bitspittle.firebase.app.FirebaseApp
import kotlinx.browser.document
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.number
import org.jetbrains.compose.web.css.px
import org.koin.dsl.module
import org.w3c.dom.get
import shared.business.di.businessModules
import shared.common.koin.initKoin
import shared.common.provider.ApplicationCoroutineScopeProvider
import shared.common.provider.BuildType


class WebConfig(appGlobals: Map<String, String>) {
    val deployment: String

    init {
        deployment = appGlobals["deployment"] ?: "PROD"
        Logger.d {
            "Deployments: $deployment"
        }
    }
}

private fun getBuildTypeByUrl(url: String) = when {
    url.contains(Regex("localhost")) -> BuildType.Debug
    url.contains(Regex("127.0.0.1")) -> BuildType.Debug
    url.contains(Regex("test-app.boostie")) -> BuildType.Beta
    url.contains(Regex("app-test.boostie")) -> BuildType.Beta
    url.contains(Regex("app.boostie")) -> BuildType.Beta
    else -> BuildType.Release
}

object PStyleSheet : StyleSheet() {
    init {
        "p" style {
            // Allow our app to stretch the full screen
            margin(0.px)
        }
    }
    // asdasd
    val asdasd = "asdasd"

}


@App
@Composable
fun MyApp(content: @Composable () -> Unit) {
    val appGlobals = AppGlobals
    Logger.d {
        "Current build variant ${getBuildTypeByUrl(document.URL)}"
    }
    

    initKoin(ApplicationCoroutineScopeProvider()) {
        modules(getWebModule(getBuildTypeByUrl(document.URL)) + businessModules + module { single { WebConfig(appGlobals) } })
    }
    rememberDependency<FirebaseApp>()


    SilkApp {
        Style(PStyleSheet)
        val colorMode = getColorMode()
        LaunchedEffect(colorMode) { // Relaunched every time the color mode changes
            localStorage.setItem(ColorModeKey, colorMode.name)
        }

        Surface(
            content = content,
            modifier = Modifier.fillMaxSize(),
        )
    }
}

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.config.initialColorMode = ColorMode.valueOf(
        localStorage[ColorModeKey] ?: ColorMode.LIGHT.name,
    )

    with(ctx.stylesheet) {
        registerBaseStyle("html, body") { Modifier.margin(0.number).padding(0.number) }
        registerBaseStyle("*") { Modifier.boxSizing(BoxSizing.BorderBox) }
        registerBaseStyle("#root") {
            Modifier.position(Position.Absolute).top(0.px).bottom(0.px).left(0.px).right(0.px)
        }

        registerBaseStyle("body") {
            Modifier.fontFamily(
                "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
                "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif",
            )
        }
    }

    ctx.theme.palettes = MutableSilkPalettes(
        light = LightPalette,
        dark = DarkPalette,
    )
}

private val LightPalette = MutableSilkPalette(
    background = MainColors.surface,
    color = MainColors.onSurface,
    link = MutableSilkPalette.Link(
        default = MainColors.primaryLink,
        visited = MainColors.primary.darkened(0.2f),
    ),
    button = MutableSilkPalette.Button(
        default = MainColors.primary,
        hover = MainColors.primary.darkened(0.2f),
        pressed = MainColors.primary.darkened(0.35f),
        focus = MainColors.primary.lightened(0.3f),
    ),
)
private val DarkPalette = MutableSilkPalette(
    background = Color.rgb(0x19, 0x1c, 0x1d),
    color = Color.rgb(0xe0, 0xe3, 0xe3),
    link = MutableSilkPalette.Link(
        default = Color.rgb(0xbf, 0xc1, 0xff),
        visited = Color.rgb(0xbf, 0xc1, 0xff).lightened(0.4f),
    ),
    button = MutableSilkPalette.Button(
        default = Color.rgb(0x5a, 0x44, 0x00),
        hover = Color.rgb(0x5a, 0x44, 0x00).darkened(0.2f),
        pressed = Color.rgb(0x5a, 0x44, 0x00).darkened(0.35f),
        focus = Color.rgb(0x5a, 0x44, 0x00).darkened(0.35f),
    ),
)

private const val ColorModeKey = "ColorModeKey"

package boostie.pages.post.create.views

import androidx.compose.runtime.Composable
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStyleBackgroundVariant
import boostie.components.style.SpaceSmall
import boostie.components.style.TitleStyle
import boostie.components.style.TitleStyleOnBackgroundVariant
import boostie.components.style.onBackgroundColor
import boostie.components.style.onPrimaryColor
import boostie.components.widgets.PrimaryButton
import boostie.components.widgets.TransparentButton
import boostie.util.Localization
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.cssRem

@Composable
fun PostStepToolbarView(
    title: String,
    onBack: () -> Unit,
    onNext: () -> Unit,
    nextEnabled: Boolean,
    nextButtonTitle: String = Localization.next_label.string,
    nextButtonIcon: @Composable () -> Unit = { FaArrowRight(onPrimaryColor) },
) {
    Row(
        BoxStyle.toModifier(BoxStyleBackgroundVariant).padding(SpaceSmall).fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        TransparentButton(iconLeft = { FaArrowLeft(onBackgroundColor) }, onClick = onBack)
        SpanText(title, modifier = TitleStyle.toModifier(TitleStyleOnBackgroundVariant).fontSize(1.25.cssRem))
        PrimaryButton(text = nextButtonTitle, iconRight = nextButtonIcon, enabled = nextEnabled, onClick = onNext)
    }
}
package boostie.vm.shared

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import shared.business.feature.auth.domain.usecase.IsSignedInUseCase
import shared.business.feature.auth.domain.usecase.SignInUseCase
import shared.business.feature.auth.domain.usecase.SignOutUseCase
import shared.common.ErrorResult
import shared.common.Result

class AuthViewModel(
    private val signIn: SignInUseCase,
    private val isSignedIn: IsSignedInUseCase,
    private val signOut: SignOutUseCase,
) : BaseIntentViewModel<AuthVMState, AuthVMIntent>(AuthVMState()) {
    private val _events = MutableSharedFlow<AuthEvents>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: AuthVMIntent) {
        when (intent) {
            AuthVMIntent.GoogleLogin -> googleLogin()
            AuthVMIntent.SignOut -> signOut()
            is AuthVMIntent.OnEmailLogin -> emailLogin(email = intent.email, password = intent.password)
            is AuthVMIntent.OnCreateEmailAccount -> createEmailAccount(email = intent.email, password = intent.password)
        }
    }

    private suspend fun createEmailAccount(email: String, password: String) {
        state = state.copy(isEmailLoading = true, error = null)
        state = when (val result = signIn(SignInUseCase.Params.CreateEmailAccount(email = email, password = password))) {
            is Result.Error -> {
                state.copy(isEmailLoading = false, error = result.error)
            }

            is Result.Success -> {
                _events.emit(AuthEvents.GoToHome)
                state.copy(isEmailLoading = false)
            }
        }
    }

    private suspend fun emailLogin(email: String, password: String) {
        state = state.copy(isEmailLoading = true, error = null)
        state = when (val result = signIn(SignInUseCase.Params.Email(email = email, password = password))) {
            is Result.Error -> {
                state.copy(isEmailLoading = false, error = result.error)
            }

            is Result.Success -> {
                _events.emit(AuthEvents.GoToHome)
                state.copy(isEmailLoading = false)
            }
        }
    }

    private suspend fun googleLogin() {
        state = state.copy(isGoogleLoading = true, error = null)
        state = when (val result = signIn(SignInUseCase.Params.Google(""))) {
            is Result.Error -> {
                state.copy(isGoogleLoading = false, error = result.error)
            }

            is Result.Success -> {
                _events.emit(AuthEvents.GoToHome)
                state.copy(isGoogleLoading = false)
            }
        }
    }

    override suspend fun onViewDidAppear() {
        state = state.copy(isGlobalLoading = true)
        when (val result = isSignedIn()) {
            is Result.Error -> {
                state = state.copy(isGlobalLoading = false)
            }

            is Result.Success -> {
                if (result.data) {
                    state = state.copy(isSignedIn = true)
                    _events.emit(AuthEvents.GoToHome)
                } else {
                    state = state.copy(isGlobalLoading = false)
                }
            }
        }
    }
}

data class AuthVMState(
    val isGlobalLoading: Boolean = false,
    val isEmailLoading: Boolean = false,
    val isGoogleLoading: Boolean = false,
    val error: ErrorResult? = null,
    val isSignedIn: Boolean = false,
) : VmState

sealed interface AuthVMIntent : VmIntent {
    object GoogleLogin : AuthVMIntent
    class OnEmailLogin(val email: String, val password: String) : AuthVMIntent
    class OnCreateEmailAccount(val email: String, val password: String) : AuthVMIntent
    object SignOut : AuthVMIntent
}

sealed interface AuthEvents {
    object GoToHome : AuthEvents
}

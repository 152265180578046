package shared.business.feature.user.domain.usecase

import kotlinx.datetime.Clock
import shared.business.feature.user.domain.repository.UserRepository
import shared.common.Result
import shared.common.extensions.asCurrentLocalDateTime
import shared.common.usecase.UseCaseResult
import shared.model.common.domain.Language
import shared.model.feature.user.domain.User

interface CreateUserUseCase : UseCaseResult<CreateUserUseCase.Params, User> {
    data class Params(
        val id: String,
        val nickname: String?,
        val email: String?,
        val photoUrl: String? = null,
        val bio: String? = null,
        val preferredLanguages: List<Language> = emptyList(),
    )
}

internal class CreateUserUseCaseImpl(
    private val repository: UserRepository,
) : CreateUserUseCase {
    override suspend fun invoke(params: CreateUserUseCase.Params): Result<User> {
        val user = User(
            id = params.id,
            creatorId = null,
            nickname = params.nickname,
            email = params.email,
            photoUrl = params.photoUrl,
            bio = params.bio,
            createdAt = Clock.System.now().asCurrentLocalDateTime,
            preferredLanguages = params.preferredLanguages,
        )
        return repository.createUser(user)
    }
}

@file:OptIn(ExperimentalObjCName::class)

package shared.common

import kotlin.experimental.ExperimentalObjCName
import kotlin.native.ObjCName

sealed class Result<out T : Any> {

    data class Success<out T : Any>(@ObjCName(swiftName = "_") val data: T) : Result<T>()

    data class Error<out T : Any>(val error: ErrorResult, val data: T? = null) : Result<T>()

    fun getOrNull(): T? = when (this) {
        is Success -> data
        is Error -> data
    }
}

open class ErrorResult(
    open var message: String? = null,
    open var throwable: Throwable? = null,
)

class UnhandledExceptionError(throwable: Throwable) : ErrorResult(
    throwable = throwable,
)

package shared.business.feature.post.domain.usecase

import shared.business.feature.post.domain.repository.PostRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.post.data.payload.CreatePostPayload
import shared.model.feature.post.domain.Post

interface CreatePostUseCase : UseCaseResult<CreatePostUseCase.Params, Post> {
    data class Params(
        val postPayload: CreatePostPayload,
    )
}

internal class CreatePostUseCaseImpl(
    private val repo: PostRepository,
) : CreatePostUseCase {
    override suspend fun invoke(params: CreatePostUseCase.Params): Result<Post> {
        return repo.createPost(params.postPayload)
    }
}

package boostie.components.sections

import androidx.compose.runtime.Composable
import boostie.Navigation
import boostie.components.Space
import boostie.components.style.BoxStyle
import boostie.components.style.MainColors
import boostie.components.style.NormalTextStyle
import boostie.components.style.PrimaryButtonStyleUnselectedVariant
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceMediumSmall
import boostie.components.style.SpaceSmall
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflowWrap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.icons.fa.FaChartSimple
import com.varabyte.kobweb.silk.components.icons.fa.FaMessage
import com.varabyte.kobweb.silk.components.icons.fa.FaMoneyBills
import com.varabyte.kobweb.silk.components.icons.fa.FaPaintRoller
import com.varabyte.kobweb.silk.components.icons.fa.FaPeopleGroup
import com.varabyte.kobweb.silk.components.icons.fa.FaQuoteLeft
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.px

sealed class MenuItem(val title: String, val route: String) {
    object Posts : MenuItem(title = "Přispěvky", Navigation.POSTS)
    object Appearance : MenuItem(title = "Vzhled", Navigation.APPEARANCE)
    object Members : MenuItem(title = "Členové", Navigation.MEMBERS)
    object Messages : MenuItem(title = "Zprávy", Navigation.MESSAGES)
    object Stats : MenuItem(title = "Statistiky", Navigation.STATS)
    object Payments : MenuItem(title = "Platební profil", Navigation.PAYMENTS)
}

@Composable
fun SideNavMenu(modifier: Modifier = Modifier, selectedNavItem: MenuItem) {
    val context = rememberPageContext()

    fun navigate(menuItem: MenuItem) {
        context.router.navigateTo(menuItem.route)
    }

    Column(BoxStyle
        .toModifier().styleModifier {
            background("#ECECEC")
        }
        .padding(SpaceSmall)
        .minWidth(100.px)
        .maxWidth(200.px)
        .then(modifier)
    ) {
        NavButton(menuItem = MenuItem.Posts, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceSmall)
        NavButton(menuItem = MenuItem.Appearance, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceSmall)
        NavButton(menuItem = MenuItem.Members, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceSmall)
        NavButton(menuItem = MenuItem.Messages, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceSmall)
        NavButton(menuItem = MenuItem.Stats, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceSmall)
        NavButton(menuItem = MenuItem.Payments, onClick = { navigate(it) }, selected = selectedNavItem)

    }
}

@Composable
fun RowNavMenu(modifier: Modifier = Modifier, selectedNavItem: MenuItem) {
    val context = rememberPageContext()

    fun navigate(menuItem: MenuItem) {
        context.router.navigateTo(menuItem.route)
    }
    Row(modifier.padding(leftRight = SpaceMedium).overflowWrap(OverflowWrap.Anywhere)) {
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Posts, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceMediumSmall)
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Appearance, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceMediumSmall)
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Members, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceMediumSmall)
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Messages, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceMediumSmall)
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Stats, onClick = { navigate(it) }, selected = selectedNavItem)
        Space(SpaceMediumSmall)
        NavButton(type = NavButtonType.CircleIcon, menuItem = MenuItem.Payments, onClick = { navigate(it) }, selected = selectedNavItem)
    }
}

private enum class NavButtonType {
    IconText,
    CircleIcon
}

@Composable
private fun NavButton(type: NavButtonType = NavButtonType.IconText, menuItem: MenuItem, onClick: (MenuItem) -> Unit, selected: MenuItem) {
    val variant = if (menuItem == selected) PrimaryButtonStyleVariant else PrimaryButtonStyleUnselectedVariant
    Button(onClick = { onClick(menuItem) }, modifier = ButtonStyle.toModifier(variant).fillMaxWidth()) {
        when (type) {
            NavButtonType.IconText -> {
                Row(Modifier.fillMaxWidth().flexWrap(FlexWrap.Nowrap)) {
                    NavIcon(menuItem)
                    Space()
                    SpanText(
                        menuItem.title, modifier = NormalTextStyle.toModifier()
                            .styleModifier {
                                property("text-overflow", "ellipsis")
                            }
                            .color(MainColors.onPrimary)
                            .fontWeight(FontWeight.Medium)
                            .flexWrap(FlexWrap.Nowrap)
                    )
                }
            }

            NavButtonType.CircleIcon -> {
                NavIcon(menuItem)
            }
        }

    }
}

@Composable
private fun NavIcon(menuItem: MenuItem) {
    when (menuItem) {
        MenuItem.Appearance -> FaPaintRoller()
        MenuItem.Members -> FaPeopleGroup()
        MenuItem.Messages -> FaMessage()
        MenuItem.Payments -> FaMoneyBills()
        MenuItem.Posts -> FaQuoteLeft()
        MenuItem.Stats -> FaChartSimple()
    }
}
package shared.common.utils

object StringUtils {
    private const val STRING_LENGTH = 10
    fun getRandomString(length: Int = STRING_LENGTH): String {
        val random = kotlin.random.Random
        val characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        return (1..length)
            .map { characters.random(random) }
            .joinToString("")
    }
}

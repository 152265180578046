package boostie.pages.post.create

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.BoxStyle
import boostie.components.style.ErrorTextStyle
import boostie.components.style.SimpleErrorBoxBackgroundVariant
import boostie.components.style.SpaceSmall
import boostie.components.style.onPrimaryColor
import boostie.toCreateContent
import boostie.toCreateProps
import boostie.toCreateSummary
import boostie.toHome
import co.touchlab.kermit.Logger
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleExclamation
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText

@Composable
fun CreatePostViewModel.onViewComposed(currentStep: PostModificationStep) {
    val vm = this
    val router = rememberPageContext().router

    LaunchedEffect(vm) {
        vm.onViewDidAppear()
        vm.onIntent(CreatePostViewModel.Intent.SetStep(currentStep))
    }
    LaunchedEffect(vm) {
        vm.events.collect { event ->
            when (event) {
                is CreatePostViewModel.Events.GoToNext -> {
                    when (event.nextStep) {
                        PostModificationStep.CreateContent -> router.toCreateContent()
                        PostModificationStep.CreateProps -> router.toCreateProps()
                        PostModificationStep.CreateSummary -> router.toCreateSummary()
                        null -> router.toHome()
                    }
                }
            }
        }
    }
}


@Composable
fun CreatePostViewModel.ErrorView() {
    state.validationError?.let { error ->
        Column(Modifier.fillMaxWidth()) {
            Box(
                BoxStyle.toModifier(SimpleErrorBoxBackgroundVariant).fillMaxWidth().fadeInTransition(),
                contentAlignment = Alignment.Center
            ) {
                Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
                    FaCircleExclamation(onPrimaryColor)
                    Space(SpaceSmall)
                    SpanText(error.message ?: "", ErrorTextStyle.toModifier())
                }
            }
            Space()
        }

    }
}
package shared.business.feature.post.domain.usecase

import shared.business.feature.post.domain.repository.PostRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult
import shared.model.feature.post.domain.Post

interface GetPostsByCreatorUseCase : UseCaseResult<GetPostsByCreatorUseCase.Params, List<Post>> {
    data class Params(
        val deleted: Boolean?,
        val draft: Boolean?,
        val tagId: String?,
    )
}

internal class GetPostsByCreatorUseCaseImpl(
    private val repo: PostRepository,
) : GetPostsByCreatorUseCase {
    override suspend fun invoke(params: GetPostsByCreatorUseCase.Params): Result<List<Post>> =
        repo.getCurrentCreatorPosts(
            deleted = params.deleted,
            draft = params.draft,
            tagId = params.tagId,
        )
}

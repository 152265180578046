package shared.business.feature.filecontent.domain.usecase

import shared.business.feature.filecontent.domain.repository.FileRepository
import shared.common.Result
import shared.common.extensions.currentLocalDateTime
import shared.common.usecase.UseCaseResult
import shared.model.feature.content.domain.FileContent
import shared.model.feature.content.domain.FileContentType

interface CreateFileUseCase : UseCaseResult<CreateFileUseCase.Params, FileContent> {
    data class Params(
        val id: String,
        val name: String,
        val extension: String,
        val size: Long,
        val duration: Float?,
        val publicUrl: String?,
    )
}

internal class CreateFileUseCaseImpl(
    private val repo: FileRepository,
) : CreateFileUseCase {
    override suspend fun invoke(params: CreateFileUseCase.Params): Result<FileContent> {
        val fileContent = FileContent(
            id = params.id,
            name = params.name,
            extension = params.extension.lowercase(),
            type = FileContentType.parseByExtension(params.extension),
            size = params.size,
            publicUrl = params.publicUrl,
            duration = params.duration,
            createdAt = currentLocalDateTime,
            createdBy = "",
        )
        return repo.saveFile(fileContent)
    }
}

package shared.model.feature.post.domain

import shared.model.feature.content.domain.FileContentType

data class ContentFileItem(
    val id: String,
    val size: Long,
    val publicUrl: String?,
    val duration: Float?,
    val type: FileContentType,
)

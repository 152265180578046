package boostie.pages.sample

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.layout.CenteredPageLayout
import boostie.components.sections.Header
import boostie.components.style.*
import boostie.components.widgets.*
import boostie.util.Localization
import co.touchlab.kermit.Logger
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaGear
import com.varabyte.kobweb.silk.components.icons.fa.FaHeart
import com.varabyte.kobweb.silk.components.icons.fa.FaUserAstronaut
import com.varabyte.kobweb.silk.components.layout.Divider
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.TextArea

@Page("/sample")
@Composable
fun SamplePage() {
//    Authenticated {
    CenteredPageLayout("Sample") {
        SampleScreen(
            modifier = Modifier.fillMaxSize(),
        )
    }
//    }
}


@Composable
fun SampleScreen(
    modifier: Modifier = Modifier,
) {
    Column(modifier.fillMaxWidth()) {
        Header(Modifier.fillMaxWidth()) {
            CircleIconButton(icon = { FaGear(onPrimaryColor) }, onClick = {})
            Space()
            CircleIconButton(icon = { FaUserAstronaut(onPrimaryColor) }, onClick = {})
        }
        Space(SpaceXXLarge)

        Column(
            Modifier.fillMaxWidth().padding(topBottom = SpaceMedium, leftRight = SpaceLarge)
        ) {

            Row(
                modifier = modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center
            ) {
                InputExamples()
                TextExamples()
            }
        }

        Row(modifier = Modifier.fillMaxWidth().padding(SpaceMedium).overflow(Overflow.Auto)) {
            Button(variant = PrimaryButtonStyleVariant, onClick = {}) {
                SpanText("Primary button")
            }
            Space(SpaceMedium)
            Button(variant = PrimaryButtonStyleVariant, onClick = {}) {
                Row {
                    FaHeart()
                    Space()
                    SpanText("Primary button with icon")
                }
            }
            Space(SpaceMedium)
            Button(variant = PrimaryButtonStyleUnselectedVariant, onClick = {}) {
                Row {
                    FaHeart()
                    Space()
                    SpanText("Primary button unselected with icon")
                }
            }

        }
    }


//    Box(modifier = modifier.fillMaxSize().backgroundColor(MainColors.surface), contentAlignment = Alignment.TopCenter) {
//
//
//        Column(modifier = Modifier.fillMaxWidth(50.percent)) {
//            HSpace()
//            Input(InputType.Email, attrs = InputStyle.toModifier().toAttrs {
//                placeholder("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB")
//                onInput {
//                    Logger.d {
//                        "Email: ${it.value}"
//                    }
//                }
//            })
//
//            HSpace(SpaceXLarge)
//
//            Box(
//                modifier = BoxStyle.toModifier(BoxStyleBackground)
//                    .fillMaxWidth()
//                    .position(Position.Sticky).top(0.percent)
//            ) {
//                Row(modifier = Modifier.fillMaxWidth()) {
//                    Image(
//                        src = "/images/app_logo_big.svg", desc = "apple-touch-icon.png",
//                        Modifier
//                            .width(150.px)
//                            .borderRadius(RadiusXXLarge)
//                            .padding(SpaceSmall)
//                    )
//                    VSpace()
//                    SpanText("boostie", modifier = TitleStyle.toModifier())
//                }
//            }
//
//            HSpace()
//            SpanText("HeaderTitle", modifier = HeaderTitleStyle.toModifier())
//            HSpace()
//            SpanText("HeaderTitle", modifier = TitleStyle.toModifier())
//            HSpace()
//            SpanText("Subtitle", modifier = SubTitleStyle.toModifier())
//            HSpace()
//            Box(
//                modifier = BoxStyle.toModifier(BoxStylePrimary)
//            ) {
//                SpanText("Primary box", modifier = TitleStyle.toModifier(TitleStyleOnPrimary))
//            }
//            HSpace()
//            Box(
//                modifier = BoxStyle.toModifier(BoxStyleBackground)
//            ) {
//                SpanText("Primary box", modifier = TitleStyle.toModifier(TitleStyleOnBackground))
//            }
//            HSpace()
//            Button(variant = primaryButtonStyle, onClick = {}) {
//                SpanText("AAAA")
//            }
//            HSpace()
//            Image(src = "/apple-touch-icon.png", desc = "apple-touch-icon.png")
//            HSpace()
//
//
//        }
//    }
}

@Composable
private fun TextExamples(modifier: Modifier = Modifier) {
    Column(modifier = modifier.fillMaxWidth(50.percent).padding(SpaceXLarge)) {
        BigTitle("Some texts")
        Space()
        Divider(Modifier.fillMaxWidth().height(BorderSmall).color(MainColors.onSurfaceSecondary))
        Space()
        BigTitle("HeaderTitle")
        Space()
        Title("Title")
        Space()
        Text("Normal Text")
        Space()
        Text("Normal Text with icon", icon = { FaGear() })
        Space()
        Subtitle("Subtitle")
        Space()

        Subtitle("Subtitle with primary color", modifier = Modifier.color(MainColors.primary))
        Space()
    }
}

@Composable
private fun InputExamples(modifier: Modifier = Modifier) {
    Column(modifier = modifier.fillMaxWidth(50.percent).padding(SpaceXLarge)) {
        BigTitle("Inputs")
        Space()
        Divider(Modifier.fillMaxWidth().height(BorderSmall).color(MainColors.onSurfaceSecondary))
        Space()
        Input(InputType.Email, attrs = InputStyle.toModifier().toAttrs {
            placeholder(Localization.email_placeholder_label.string)
            onInput {
                Logger.d {
                    "Email: ${it.value}"
                }
            }
        })

        Input(InputType.Password, attrs = InputStyle.toModifier().toAttrs {
            placeholder(Localization.password_label.string)
            onInput {
                Logger.d {
                    "Password: ${it.value}"
                }
            }
        })

        TextArea(attrs = InputStyle.toModifier().height(200.px).toAttrs {
            placeholder("Some message")
            onInput {
                "Some message: ${it.value}"
            }
        })
    }
}


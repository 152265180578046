package shared.business.feature.creator

import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module
import shared.business.feature.creator.data.repository.CreatorRepositoryImpl
import shared.business.feature.creator.domain.repository.CreatorRepository
import shared.business.feature.creator.domain.usecase.CreateCreatorUseCase
import shared.business.feature.creator.domain.usecase.CreateCreatorUseCaseImpl
import shared.business.feature.creator.domain.usecase.GetCreatorByIdUseCase
import shared.business.feature.creator.domain.usecase.GetCreatorByIdUseCaseImpl
import shared.business.feature.creator.domain.usecase.UpdateCreatorUseCase
import shared.business.feature.creator.domain.usecase.UpdateCreatorUseCaseImpl

internal val creatorModule = module {
    singleOf(::CreatorRepositoryImpl) bind CreatorRepository::class

    factoryOf(::CreateCreatorUseCaseImpl) bind CreateCreatorUseCase::class
    factoryOf(::GetCreatorByIdUseCaseImpl) bind GetCreatorByIdUseCase::class
    factoryOf(::UpdateCreatorUseCaseImpl) bind UpdateCreatorUseCase::class
}

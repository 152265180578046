package boostie.components.widgets

import androidx.compose.runtime.Composable
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.style.PrimaryButtonStyleVariant
import boostie.components.style.SpaceSmall
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.ariaDisabled
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText

enum class ButtonIconOrientation {
    Left, Right
}


@Composable
fun ProgressButton(
    title: String,
    isLoading: Boolean,
    variant: ComponentVariant = PrimaryButtonStyleVariant,
    enabled: Boolean = true,
    icon: (@Composable () -> Unit)? = null,
    iconOrientation: ButtonIconOrientation = ButtonIconOrientation.Left,
    onClick: () -> Unit,
) {
    Button(onClick = {
        if (enabled) {
            onClick()
        }
    }, ButtonStyle.toModifier(variant).ariaDisabled(!enabled)) {
        if (!isLoading) {
            if (icon != null) {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    when (iconOrientation) {
                        ButtonIconOrientation.Left -> {
                            icon()
                            Space(SpaceSmall)
                            SpanText(title)
                        }

                        ButtonIconOrientation.Right -> {
                            SpanText(title)
                            Space(SpaceSmall)
                            icon()
                        }
                    }

                }
            } else {
                Row(Modifier.fadeInTransition(), verticalAlignment = Alignment.CenterVertically) {
                    SpanText(title)
                }
            }

        } else {
            ProgressIndicator(Modifier.align(Alignment.Center).fadeInTransition())
        }
    }
}

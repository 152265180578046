package shared.model.feature.user.data.api

import shared.common.extensions.asCurrentLocalDateTime
import shared.common.extensions.asEpochMilliseconds
import shared.common.extensions.asInstant
import shared.model.common.domain.Language
import shared.model.feature.user.domain.User

@kotlinx.serialization.Serializable
data class UserApiDto(
    val id: String,
    val creatorId: String? = null,
    val nickname: String?,
    val email: String? = null,
    val photoUrl: String? = null,
    val bio: String? = null,
    val created: Long,
    val preferredLanguages: List<String> = emptyList(),
)

fun UserApiDto.toDomain() = User(
    id = id,
    creatorId = creatorId,
    nickname = nickname,
    email = email,
    photoUrl = photoUrl,
    bio = bio,
    createdAt = created.asInstant.asCurrentLocalDateTime,
    preferredLanguages = preferredLanguages.map { Language.valueOf(it) },
)

fun User.toApiDto() = UserApiDto(
    id = id,
    creatorId = creatorId,
    nickname = nickname,
    email = email,
    photoUrl = photoUrl,
    bio = bio,
    created = createdAt.asEpochMilliseconds,
    preferredLanguages = preferredLanguages.map { it.name },
)

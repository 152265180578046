package shared.model.feature.post.data.api

import kotlinx.serialization.Serializable
import shared.model.feature.post.domain.PostCreatorTag

@Serializable
data class PostCreatorTagApiDto(
    val id: String,
    val creatorId: String,
    val name: String,
    val hexColor: String,
)

fun PostCreatorTagApiDto.toDomain() = PostCreatorTag(
    id = id,
    creatorId = creatorId,
    name = name,
    hexColor = hexColor,
)

fun PostCreatorTag.toApiDto() = PostCreatorTagApiDto(
    id = id,
    creatorId = creatorId,
    name = name,
    hexColor = hexColor,
)

package shared.business.feature.post.domain.usecase.tags

import shared.business.feature.post.domain.repository.PostCreatorTagRepository
import shared.common.Result
import shared.common.usecase.UseCaseResult

interface DeletePostCreatorTagUseCase : UseCaseResult<String, Unit>
internal class DeletePostCreatorTagUseCaseImpl internal constructor(
    private val repo: PostCreatorTagRepository,
) : DeletePostCreatorTagUseCase {
    override suspend fun invoke(params: String): Result<Unit> {
        return repo.delete(params)
    }
}

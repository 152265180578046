package boostie.pages.post.create.props

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import boostie.components.Space
import boostie.components.animation.fadeInTransition
import boostie.components.layout.CenteredPageLayout
import boostie.components.layout.CenteredPageLayoutType
import boostie.components.layout.DefaultToolbarButtons
import boostie.components.style.BoxStyle
import boostie.components.style.BoxStylePrimaryDashedVariant
import boostie.components.style.BoxStyleSurfaceVariant
import boostie.components.style.MainColors
import boostie.components.style.RadiusMedium
import boostie.components.style.SpaceMedium
import boostie.components.style.SpaceMediumSmall
import boostie.components.style.SpaceSmall
import boostie.components.style.SubTitleStyle
import boostie.components.style.onPrimaryColor
import boostie.components.style.primaryColor
import boostie.components.style.primaryDashedBorder
import boostie.components.widgets.LabelText
import boostie.components.widgets.PrimaryButton
import boostie.components.widgets.ToggleWithLabel
import boostie.model.toVO
import boostie.pages.post.create.CreatePostViewModel
import boostie.pages.post.create.ErrorView
import boostie.pages.post.create.PostModificationStep
import boostie.pages.post.create.onViewComposed
import boostie.pages.post.create.views.CreatePostLayout
import boostie.pages.post.create.views.PostStepToolbarView
import boostie.pages.post.create.views.TitleWithDescriptionView
import boostie.pages.tags.CreatorTagsViewModel
import boostie.pages.tags.views.CreatorPostTagView
import boostie.pages.tags.views.CreatorPostTagViewProps
import boostie.pages.tags.views.CreatorTagsManagementView
import boostie.toCreateContent
import boostie.util.Authenticated
import boostie.util.CheckOnboarding
import boostie.util.Localization
import boostie.util.rememberDependency
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rowGap
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaHashtag
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toJSDate
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.DateTimeLocalInput
import shared.common.extensions.currentLocalDate

@Page("/create/props")
@Composable
fun PostCreatePropsPage() {

    Authenticated { authViewModel ->
        CheckOnboarding {
            val vm = rememberDependency<CreatePostViewModel>()
            val tagVM = rememberDependency<CreatorTagsViewModel>()
            val scope = rememberCoroutineScope()

            vm.onViewComposed(PostModificationStep.CreateProps)

            LaunchedEffect(tagVM) {
                tagVM.onViewDidAppear()
            }

            CenteredPageLayout(
                title = Localization.create_new_post_label.string,
                type = CenteredPageLayoutType.ToolbarOnly(buttons = { DefaultToolbarButtons(authViewModel) }),
            ) {
                CreatePostLayout(toolbar = { router ->
                    PostStepToolbarView("Nastavení", onBack = { router.toCreateContent() }, onNext = {
                        scope.launch {
                            vm.onIntent(CreatePostViewModel.Intent.ValidateCurrentStep)
                        }
                    }, nextEnabled = true)
                }, content = {
                    vm.apply {
                        ErrorView()
                    }
                    RootScreen(vm, tagVM)
                })

            }
        }
    }
}

@Composable
private fun ColumnScope.RootScreen(vm: CreatePostViewModel, tagVM: CreatorTagsViewModel) {
    val scope = rememberCoroutineScope()

    Column(Modifier.fillMaxWidth()) {
        TitleWithDescriptionView(
            title = "Typ publikovani", description = "Nastav jak a kdy chces publikovat Tvuj prispevek"
        )
    }

    Column(BoxStyle.toModifier(BoxStylePrimaryDashedVariant).fillMaxWidth()) {
        Column(Modifier.fillMaxWidth()) {
            ToggleWithLabel("Ulozit jako koncept", vm.state.createEditPostVO.draft, onChecked = {
                scope.launch {
                    vm.onIntent(CreatePostViewModel.Intent.OnDraftChange(it))
                }
            })
            Space()
            val draftText = if (vm.state.createEditPostVO.draft) {
                "Tvuj prispevek bude zatim ulozeny jako koncept. Tvoji fanousci ho zatim neuvidi, nicmene muzes se k prispevku kdykoliv vratit a publikovat ho."
            } else {
                "Tvuj prispevek bude publikovany ihned a nebo dle jineho nastaveni."
            }
            SpanText(
                draftText,
                SubTitleStyle.toModifier().textAlign(TextAlign.Start).fontSize(0.8.cssRem)
            )
        }
        Space()
        Column(Modifier.fillMaxWidth()) {
            val publishAtDateTime = vm.state.createEditPostVO.publishedAt
            var publishLater by remember { mutableStateOf(false) }
            val showPublishLater = publishLater || publishAtDateTime != null

            ToggleWithLabel("Publikovat pozdeji", showPublishLater, onChecked = {
                publishLater = it
            })
            Space()
            if (showPublishLater) {
                Column(Modifier.padding(leftRight = SpaceMedium, topBottom = SpaceSmall).fadeInTransition().fillMaxWidth()) {

                    Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
                        val inputModifier = Modifier.borderRadius(RadiusMedium).padding(SpaceSmall)
                            .backgroundColor(MainColors.surface).primaryDashedBorder.fontSize(1.cssRem).fillMaxWidth()
                        FaClock(primaryColor)
                        Space(SpaceSmall)
                        DateTimeLocalInput(
                            value = publishAtDateTime.toString(),
                            attrs = inputModifier.toAttrs {
                                defaultValue(publishAtDateTime.toString() ?: currentLocalDate.toString())
                                onInput {
                                    scope.launch {
                                        vm.onIntent(CreatePostViewModel.Intent.OnPublishedAtChanged(it.value.toLocalDateTime()))
                                    }
                                }
                            })
                        if (publishAtDateTime != null) {
                            Space()
                            PrimaryButton(
                                modifier = Modifier.padding(SpaceMediumSmall),
                                text = Localization.remove_label.string,
                                iconLeft = { FaXmark(onPrimaryColor) }) {
                                scope.launch {
                                    vm.onIntent(CreatePostViewModel.Intent.OnClearPublishedAt)
                                    publishLater = false
                                }
                            }
                        }
                    }

                    if (publishAtDateTime != null) {
                        Space(SpaceSmall)
                        SpanText(
                            "Tvuj prispevek bude publikovany v ${
                                publishAtDateTime.toInstant(TimeZone.currentSystemDefault()).toJSDate().toLocaleString(locales = "cs")
                            }. Zaroven vsichni fanousci obdrzi notifikaci do svych mobilnich telefonu a nebo emailem, dle jejich preferenci.",
                            SubTitleStyle.toModifier().textAlign(TextAlign.Start).fontSize(0.8.cssRem)
                        )
                    }

                }
            }

        }
    }

    Space()

    // Tags
    Column(Modifier.fillMaxWidth()) {
        TitleWithDescriptionView(
            title = "Kategorie prispevku", description = "Pridejte kategorie prispevku. Na Tvem kanalu se budou moci tvoji fanousci lepe zorientovat."
        )
        val selectedTags = vm.state.createEditPostVO.tags

        val availableCreatorTags = tagVM.state.getAvailableTags(selectedTags?.map { it.id })
        val tagsLoading = tagVM.state.isLoading
        CreatorTagsManagementView(availableCreatorTags, tagsLoading, onCreateTag = { name, hexColor ->
            scope.launch {
                tagVM.onIntent(CreatorTagsViewModel.Intent.CreateTag(name = name, hexColor = hexColor))
            }
        }, onTagClick = { tag ->
            scope.launch {
                vm.onIntent(CreatePostViewModel.Intent.OnAddTag(tag.toVO()))
            }
        })

        Space()
        Column(BoxStyle.toModifier(BoxStylePrimaryDashedVariant).fillMaxWidth()) {

            LabelText("Vybrane kategorie", icon = { FaHashtag(primaryColor) })
            Space(SpaceSmall)

            Row(
                BoxStyle.toModifier(BoxStyleSurfaceVariant).fillMaxWidth().flexWrap(FlexWrap.Wrap).rowGap(SpaceSmall)
                    .padding(topBottom = SpaceMedium), verticalAlignment = Alignment.CenterVertically
            ) {
                selectedTags?.forEach { vo ->
                    val props = CreatorPostTagViewProps.Removable(vo.toDomain(), onClick = {
                        scope.launch {
                            vm.onIntent(CreatePostViewModel.Intent.OnRemoveTag(it.id))
                        }
                    })
                    CreatorPostTagView(props)
                    Space(SpaceSmall)
                }
            }
        }
    }

}


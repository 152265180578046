package boostie.pages.post.create

import boostie.base.BaseIntentViewModel
import boostie.base.VmIntent
import boostie.base.VmState
import boostie.model.CreateEditPostVO
import boostie.model.FileContentVO
import boostie.model.PostCreatorTagVO
import boostie.model.error.PostModificationError
import boostie.model.toPayload
import com.russhwolf.settings.Settings
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.datetime.LocalDateTime
import shared.business.feature.common.infrastruture.LocalStorageCache
import shared.business.feature.post.domain.usecase.CreatePostUseCase
import shared.common.ErrorResult
import shared.common.Result
import shared.model.feature.post.domain.Post


sealed interface PostModificationStep {

    val nextStep: PostModificationStep?

    object CreateContent : PostModificationStep {
        override val nextStep: PostModificationStep
            get() = CreateProps

    }

    object CreateProps : PostModificationStep {
        override val nextStep: PostModificationStep
            get() = CreateSummary
    }

    object CreateSummary : PostModificationStep {
        override val nextStep: PostModificationStep?
            get() = null
    }
}

class CreatePostViewModel(
    settings: Settings,
    private val createPostUseCase: CreatePostUseCase,
) : BaseIntentViewModel<CreatePostViewModel.State, CreatePostViewModel.Intent>(State()) {
    private val localStorageCache = LocalStorageCache<CreateEditPostVO>(CACHE_CREATE_POST_KEY, settings)
    private val _events = MutableSharedFlow<Events>()
    val events = _events.asSharedFlow()

    override suspend fun applyIntent(intent: Intent) {
        when (intent) {
            Intent.CreatePost -> createPost()
            Intent.ValidateCurrentStep -> {
                state = state.copy(validationError = null, isValidating = true)
                val validationError = validateCurrentStep()
                if (validationError != null) {
                    state = state.copy(validationError = validationError, isValidating = false)
                } else {
                    _events.emit(Events.GoToNext(state.currentStep.nextStep))
                }
            }

            is Intent.SetStep -> {
                state = state.copy(currentStep = intent.step)
            }

            is Intent.OnDescriptionInput -> {
                _createPostVo = _createPostVo.copy(description = intent.input)
            }

            is Intent.OnTitleInput -> _createPostVo = _createPostVo.copy(title = intent.input)
            is Intent.OnPostTypeChange -> _createPostVo = _createPostVo.copy(type = intent.type)
            is Intent.OnDraftChange -> _createPostVo = _createPostVo.copy(draft = intent.isDraft)
            is Intent.OnPublishedAtChanged -> _createPostVo = _createPostVo.copy(publishedAt = intent.time)
            Intent.OnClearPublishedAt -> _createPostVo = _createPostVo.copy(publishedAt = null)

            // Tags
            is Intent.OnAddTag -> {
                val newTag = intent.creatorTag
                val tagsTmp = (_createPostVo.tags ?: emptyList()).toMutableList()
                if (tagsTmp.firstOrNull { it.id == newTag.id } == null) {
                    tagsTmp.add(newTag)
                    _createPostVo = _createPostVo.copy(tags = tagsTmp)
                }
            }

            is Intent.OnRemoveTag -> {
                val tagId = intent.tagId
                val tagsTmp = _createPostVo.tags?.toMutableList()
                val tagToRemove = tagsTmp?.firstOrNull { it.id == tagId }
                tagToRemove?.let {
                    tagsTmp.remove(tagToRemove)
                    _createPostVo = _createPostVo.copy(tags = tagsTmp)
                }
            }

            // Images
            is Intent.OnAddImageFile -> {
                val newFile = intent.fileContentVO
                val filesTmp = (_createPostVo.imageFiles ?: emptyList()).toMutableList()
                if (filesTmp.firstOrNull { it.id == newFile.id } == null) {
                    filesTmp.add(newFile)
                    _createPostVo = _createPostVo.copy(imageFiles = filesTmp)
                }

            }

            is Intent.OnRemoveImageFile -> {
                val fileId = intent.fileId
                val filesTmp = _createPostVo.imageFiles?.toMutableList()
                val fileToRemove = filesTmp?.firstOrNull { it.id == fileId }
                fileToRemove?.let {
                    filesTmp.remove(fileToRemove)
                    _createPostVo = _createPostVo.copy(imageFiles = filesTmp)
                }
            }

            // Audio
            is Intent.OnAddFullAudioFile -> _createPostVo = _createPostVo.copy(fullAudioFile = intent.fileContentVO)
            is Intent.OnAddPublicAudioFile -> _createPostVo = _createPostVo.copy(publicAudioFile = intent.fileContentVO)
            Intent.OnRemoveFullAudioFile -> _createPostVo = _createPostVo.copy(fullAudioFile = null)
            Intent.OnRemovePublicAudioFile -> _createPostVo = _createPostVo.copy(publicAudioFile = null)

            // Video
            is Intent.OnAddFullVideoFile -> _createPostVo = _createPostVo.copy(fullVideoFile = intent.fileContentVO)
            is Intent.OnAddPublicVideoFile -> _createPostVo = _createPostVo.copy(publicVideoFile = intent.fileContentVO)
            Intent.OnRemoveFullVideoFile -> _createPostVo = _createPostVo.copy(fullVideoFile = null)
            Intent.OnRemovePublicVideoFile -> _createPostVo = _createPostVo.copy(publicVideoFile = null)
        }
    }

    override suspend fun onViewDidAppear() {
        state = state.copy(createEditPostVO = localStorageCache.value() ?: CreateEditPostVO())
    }

    private suspend fun createPost() {
        val params = CreatePostUseCase.Params(_createPostVo.toPayload())
        when (val result = createPostUseCase(params)) {
            is Result.Error -> state = state.copy(error = result.error)
            is Result.Success -> {
                _events.emit(Events.GoToNext(state.currentStep.nextStep))
            }
        }

    }

    private fun validateCurrentStep(): PostModificationError? {
        return when (state.currentStep) {
            PostModificationStep.CreateContent -> {
                when {
                    _createPostVo.title.isNullOrBlank() -> PostModificationError.NoTitle
                    _createPostVo.description.isNullOrBlank() -> PostModificationError.NoDescription
                    _createPostVo.type == Post.Type.VIDEO && _createPostVo.fullVideoFile == null -> PostModificationError.TypeVideoNoFullVideo
                    _createPostVo.type == Post.Type.AUDIO && _createPostVo.fullAudioFile == null -> PostModificationError.TypeAudioNoFullAudio
                    else -> null
                }
            }

            PostModificationStep.CreateProps -> null
            PostModificationStep.CreateSummary -> null
        }
    }


    data class State(
        val isLoading: Boolean = false,
        val isValidating: Boolean = false,
        val error: ErrorResult? = null,
        val validationError: PostModificationError? = null,
        val currentStep: PostModificationStep = PostModificationStep.CreateContent,
        val createEditPostVO: CreateEditPostVO = CreateEditPostVO(),
    ) : VmState

    private var _createPostVo
        set(value) {
            state = state.copy(createEditPostVO = value)
            localStorageCache.store(value)
        }
        get() = state.createEditPostVO

    sealed interface Intent : VmIntent {
        class SetStep(val step: PostModificationStep) : Intent
        object ValidateCurrentStep : Intent
        class OnTitleInput(val input: String) : Intent
        class OnDescriptionInput(val input: String) : Intent
        class OnPostTypeChange(val type: Post.Type) : Intent

        class OnPublishedAtChanged(val time: LocalDateTime?) : Intent
        object OnClearPublishedAt : Intent

        // Draft
        class OnDraftChange(val isDraft: Boolean) : Intent

        // Tags
        class OnAddTag(val creatorTag: PostCreatorTagVO) : Intent
        class OnRemoveTag(val tagId: String) : Intent

        // Images
        class OnAddImageFile(val fileContentVO: FileContentVO) : Intent
        class OnRemoveImageFile(val fileId: String) : Intent

        // Audio
        class OnAddFullAudioFile(val fileContentVO: FileContentVO) : Intent
        object OnRemoveFullAudioFile : Intent
        class OnAddPublicAudioFile(val fileContentVO: FileContentVO) : Intent
        object OnRemovePublicAudioFile : Intent

        // Video
        class OnAddFullVideoFile(val fileContentVO: FileContentVO) : Intent
        object OnRemoveFullVideoFile : Intent
        class OnAddPublicVideoFile(val fileContentVO: FileContentVO) : Intent
        object OnRemovePublicVideoFile : Intent

        object CreatePost : Intent

    }

    sealed interface Events {
        class GoToNext(val nextStep: PostModificationStep?) : Events
    }

    companion object {
        private const val CACHE_CREATE_POST_KEY = "cache_creator_post_key"
    }
}



